import * as React from 'react';

import {
  Button,
  CircularProgress,
  DialogContentText,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { Clear, Group } from '@mui/icons-material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { AccessLevel, AccessLevelString } from 'libs/accessLevel';
import { Autocomplete } from '@mui/material';
import { ConfirmationServiceContext } from 'components/dialogs/ConfirmationService';
import { deleteBuilderMember } from 'libs/api/builders';
import {
  addMembers,
  deleteConnectionMember,
  deleteDatasourceMember,
  deleteNotificationDstMember,
  deleteNotificationMember,
  deleteProjectMember,
  deleteReportMember,
  getOrganizationUsers,
  getUserGroups,
  updateMembers
} from 'libs/api';
import { deleteFolderMember } from 'libs/api/folder';
import { getMembers } from 'libs/api/member';
import { deleteVariableMember } from 'libs/api/variable';
import { Membership, OrganizationUser, User } from 'models/user';
import { deleteScheduleMember } from 'libs/api/schedule';
import { UserGroup } from 'models/user_group';
import { Avatar } from 'ui/common/avatar';
import { Dialog } from 'components/ui/common/dialog';
import { MemberShips } from 'components/class/members';

const styles = (theme: Theme) =>
  createStyles({
    invitePaper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 100,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: '#f5f5f5'
    }
  });

export interface BaseShareResourceProps {
  id?: string | number;
  editable: boolean;
  title: string;
  open: boolean;
  type:
    | 'project'
    | 'datasource'
    | 'connection'
    | 'builder'
    | 'report'
    | 'folder'
    | 'variable'
    | 'schedule'
    | 'notification_dst'
    | 'notification';
}

interface SelectedOption {
  id: string;
  label: string;
  isGroup: boolean;
}

interface Props extends BaseShareResourceProps {
  onClose: () => void;
}

interface State {
  loaded: boolean;
  loading: boolean;
  error?: string;
  newOption: SelectedOption | null;
  newAccessLevel: AccessLevel;
  addingUser: boolean;
  addError: string;
  users: Membership[];
  organizationUsers: OrganizationUser[];
  userGroups: UserGroup[];
  adminCount: number;
  developerCount: number;
  viewerCount: number;
  inherit: { id: string; name: string };
  editable: boolean;
}

const LoadingSpinner: React.FunctionComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <CircularProgress variant="indeterminate" disableShrink={true} />
    </div>
  );
};

const UserLabel = (u: User | OrganizationUser) => {
  if (u.last_name || u.first_name) {
    return u.last_name + ' ' + u.first_name;
  }
  return u.email;
};

class ShareResourceDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  static contextType = ConfirmationServiceContext;
  context!: React.ContextType<typeof ConfirmationServiceContext>;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading: false,
      newOption: null,
      newAccessLevel: AccessLevel.Developer,
      addingUser: false,
      addError: '',
      users: [] as Membership[],
      organizationUsers: [] as OrganizationUser[],
      userGroups: [] as UserGroup[],
      adminCount: 0,
      developerCount: 0,
      viewerCount: 0,
      inherit: { id: '', name: '' },
      editable: false
    };
  }

  componentDidMount() {
    if (this.props.open && this.props.id != undefined) {
      this.loadUsers();
    }
    this.loadOrganizationUsers();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.open || this.props.id == undefined) {
      return;
    }

    const resourceChanged = !prevProps.open && this.props.open;
    if (resourceChanged || !this.state.loaded) {
      this.loadUsers();
    }
  }

  loadOrganizationUsers = async () => {
    const { data: organizationUsers } = await getOrganizationUsers();
    const { data: userGroups } = await getUserGroups();
    this.setState({ organizationUsers, userGroups });
  };

  loadUsers = async () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });
    try {
      const { data } = await getMembers({
        id: this.props.id!,
        type: this.props.type
      });

      const memberships = new MemberShips(data.members, this.state.userGroups);
      const adminCount = memberships.memberCount(AccessLevel.Admin);
      const developerCount = memberships.memberCount(AccessLevel.Developer);
      const viewerCount = memberships.memberCount(AccessLevel.Viewer);
      this.setState({
        inherit: data.inherit_from,
        users: data.members,
        editable: data.editable,
        loaded: true,
        loading: false,
        error: undefined,
        adminCount,
        developerCount,
        viewerCount
      });
    } catch (e) {
      if (e.response && e.response.status === 403) {
        this.setState({
          loaded: true,
          loading: false,
          error: '共有設定の権限がありません'
        });
      } else {
        this.setState({
          loaded: true,
          loading: false,
          error: '読み込みでエラーが発生しました'
        });
      }
    }
  };

  handleChangeOption = (
    _: React.ChangeEvent<HTMLInputElement>,
    newValue: SelectedOption
  ) => {
    this.setState({
      newOption: newValue
    });
  };

  handleChangeNewAccessLevel = (ev: SelectChangeEvent) => {
    const al = parseInt(ev.target.value, 10) as AccessLevel;
    this.setState({
      newAccessLevel: al
    });
  };

  addMember = async (
    id: string | number,
    entity_id: string,
    is_group: boolean,
    al: AccessLevel
  ) => {
    return addMembers(this.props.type, id as string, entity_id, is_group, al);
  };

  updateMember = async (
    id: string | number,
    entity_id: string,
    is_group: boolean,
    al: AccessLevel
  ) => {
    return updateMembers(
      this.props.type,
      id as string,
      entity_id,
      is_group,
      al
    );
  };

  submitAddUser = async (ev: React.FormEvent) => {
    ev.preventDefault();
    const { newOption } = this.state;
    if (!newOption) {
      return;
    }

    this.setState({ addingUser: true });
    try {
      await this.addMember(
        this.props.id!,
        newOption.id,
        newOption.isGroup,
        this.state.newAccessLevel
      );
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ addError: e.response.data.message });
      }
      this.setState({ addingUser: false });
      return;
    }
    this.setState({ addingUser: false, newOption: null, addError: '' });
    await this.loadUsers();
    return;
  };

  changeMemberAccessLevel = async (
    id: string,
    is_group: boolean,
    ev: SelectChangeEvent<AccessLevel>
  ) => {
    const al = parseInt(ev.target.value as string, 10) as AccessLevel;
    await this.updateMember(this.props.id!, id, is_group, al);
    await this.loadUsers();
  };

  deleteUser = async (id: string, is_group: boolean) => {
    switch (this.props.type) {
      case 'project':
        await deleteProjectMember(this.props.id! as string, id, is_group);
        break;
      case 'datasource':
        await deleteDatasourceMember(this.props.id! as string, id, is_group);
        break;
      case 'connection':
        await deleteConnectionMember(this.props.id! as number, id, is_group);
        break;
      case 'builder':
        await deleteBuilderMember(this.props.id! as string, id, is_group);
        break;
      case 'report':
        await deleteReportMember(this.props.id! as string, id, is_group);
        break;
      case 'folder':
        await deleteFolderMember(this.props.id! as string, id, is_group);
        break;
      case 'variable':
        await deleteVariableMember(this.props.id! as string, id, is_group);
        break;
      case 'schedule':
        await deleteScheduleMember(this.props.id! as string, id, is_group);
        break;
      case 'notification':
        await deleteNotificationMember(this.props.id! as string, id, is_group);
        break;
      case 'notification_dst':
        await deleteNotificationDstMember(
          this.props.id! as string,
          id,
          is_group
        );
        break;
      default:
        throw new Error('未定義のtypeです: ' + this.props.type);
    }
    await this.loadUsers();
  };

  dialogContent = () => {
    if (this.state.loading) {
      return (
        <div style={{ height: 500 }}>
          <LoadingSpinner />
        </div>
      );
    }

    if (this.state.error != undefined) {
      return (
        <div style={{ height: 500 }}>
          <Typography color="error">{this.state.error}</Typography>
        </div>
      );
    }

    const { newOption, newAccessLevel, users, adminCount } = this.state;

    const editable = this.props.editable && this.state.editable;
    const addOptions: Array<{ id: string; label: string; isGroup: boolean }> =
      [];
    this.state.organizationUsers.forEach((u) => {
      addOptions.push({
        id: u.id,
        label: UserLabel(u),
        isGroup: false
      });
    });
    this.state.userGroups.forEach((g) => {
      addOptions.push({ id: g.id, label: g.name, isGroup: true });
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 500 }}>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <Table size="small">
            <TableBody>
              {users.map((u) => {
                return (
                  <TableRow key={u.user.id}>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {u.entity_type === 'g' ? (
                          <Group color="action" fontSize="large" />
                        ) : (
                          <Avatar user={u.user} />
                        )}
                        &nbsp;
                        {u.entity_type === 'g'
                          ? u.user_group.name
                          : UserLabel(u.user)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Select
                        variant="standard"
                        value={u.access_level}
                        onChange={(ev) =>
                          this.changeMemberAccessLevel(
                            u.entity_type === 'g' ? u.user_group.id : u.user.id,
                            u.entity_type === 'g',
                            ev
                          )
                        }
                        disabled={
                          (u.access_level >= AccessLevel.Admin &&
                            adminCount === 1) ||
                          !editable
                        }
                      >
                        <MenuItem value={AccessLevel.Viewer}>
                          {AccessLevelString(AccessLevel.Viewer)}
                        </MenuItem>
                        <MenuItem value={AccessLevel.Developer}>
                          {AccessLevelString(AccessLevel.Developer)}
                        </MenuItem>
                        <MenuItem value={AccessLevel.Admin}>
                          {AccessLevelString(AccessLevel.Admin)}
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      {users.length >= 2 &&
                        !(
                          u.access_level >= AccessLevel.Admin &&
                          adminCount === 1
                        ) &&
                        this.props.editable &&
                        this.state.editable && (
                          <IconButton
                            onClick={() =>
                              this.deleteUser(
                                u.entity_type === 'g'
                                  ? u.user_group.id
                                  : u.user.id,
                                u.entity_type === 'g'
                              )
                            }
                            size="large"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {this.props.editable && this.state.editable && (
          <Paper
            elevation={0}
            classes={{ root: this.props.classes.invitePaper }}
          >
            <Typography variant="caption">
              共有先と付与する権限を選択してください
            </Typography>
            <form onSubmit={this.submitAddUser}>
              <div style={{ display: 'flex' }}>
                <Autocomplete<SelectedOption, false, false, false>
                  style={{ flex: '1 1 auto' }}
                  value={newOption || null}
                  onChange={this.handleChangeOption}
                  options={addOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="ユーザーまたはグループ名"
                      fullWidth={true}
                      InputProps={{
                        ...params.InputProps,
                        required: true
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li style={{ display: 'flex', gap: 8 }} {...props}>
                      {option.isGroup ? (
                        <Group color="action" fontSize="small" />
                      ) : (
                        <Avatar width={20} height={20} label={option.label} />
                      )}
                      {option.label}
                    </li>
                  )}
                  openOnFocus={true}
                />
                &nbsp;
                <FormControl variant="standard">
                  <InputLabel>権限</InputLabel>
                  <Select
                    variant="standard"
                    value={newAccessLevel as unknown as string}
                    onChange={this.handleChangeNewAccessLevel}
                  >
                    <MenuItem value={AccessLevel.Viewer}>
                      {AccessLevelString(AccessLevel.Viewer)}
                    </MenuItem>
                    <MenuItem value={AccessLevel.Developer}>
                      {AccessLevelString(AccessLevel.Developer)}
                    </MenuItem>
                    <MenuItem value={AccessLevel.Admin}>
                      {AccessLevelString(AccessLevel.Admin)}
                    </MenuItem>
                  </Select>
                </FormControl>
                &nbsp;
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={this.state.addingUser}
                  style={{ alignSelf: 'flex-end' }}
                >
                  追加
                </Button>
              </div>
            </form>
            {this.state.addError !== '' && (
              <Typography variant="caption" color="error">
                {this.state.addError}
              </Typography>
            )}
          </Paper>
        )}
      </div>
    );
  };

  onClose = () => {
    this.props.onClose();
    this.setState({ addError: '' });
  };

  render() {
    const { open } = this.props;
    const { adminCount, developerCount, viewerCount, inherit } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        title={`${this.props.title} 共有設定`}
        helpButtonProps={{ manualUrlKey: 'share_about' }}
        contentProps={{
          style: { width: 512 }
        }}
        hideOKButton={true}
      >
        {inherit.id !== '' && (
          <DialogContentText color="secondary">
            フォルダ {inherit.name} の権限を表示しています。
            <br />
            権限はトップ階層のフォルダ・オブジェクトしか設定できません。
          </DialogContentText>
        )}
        <DialogContentText>アクセス可能なユーザー</DialogContentText>
        <DialogContentText>
          {AccessLevelString(AccessLevel.Admin)}: {adminCount} /{' '}
          {AccessLevelString(AccessLevel.Developer)}: {developerCount} /{' '}
          {AccessLevelString(AccessLevel.Viewer)}: {viewerCount}
        </DialogContentText>
        {this.dialogContent()}
      </Dialog>
    );
  }
}

export default withStyles(styles)(ShareResourceDialog);
