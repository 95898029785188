import React from 'react';
import { ClassNameMap } from '@mui/styles';
import { DatePicker } from './datePicker';
import clsx from 'clsx';
import { DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const StyledDatePicker: React.FC<
  {
    date: string; // YYYY-MM-DD
    setDate: (date: string) => void;
    isValid: boolean;
    classes: ClassNameMap<
      | 'datePickerWrapper'
      | 'datePickerBorder'
      | 'datePickerBorderError'
      | 'datePickerIcon'
      | 'datePicker'
      | 'datePickerInput'
      | 'datePickerErrorMSg'
    >;
  } & DatePickerProps<dayjs.Dayjs>
> = ({ setDate, date, isValid, classes, ...props }) => {
  return (
    <div className={classes.datePickerWrapper}>
      <div
        className={clsx({
          [classes.datePickerBorderError]: !isValid
        })}
      >
        <DatePicker
          className={classes.datePicker}
          date={date}
          setDate={setDate}
          {...props}
        />
      </div>
      {!isValid && (
        <span className={classes.datePickerErrorMSg}>日付が不正です</span>
      )}
    </div>
  );
};
