import * as React from 'react';
import {
  BaseItem,
  DatasourceItem,
  ExportItem,
  ListItem,
  ProjectItem,
  ReportItem
} from '../../../models/dependency';
import { TableCell } from '@mui/material';
import { AccessLevelString } from 'libs/accessLevel';
import { Folder } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox,
  ConnectionCell,
  DatasourceStatusCell,
  LinkCell,
  NodeStatusCell,
  ProjectStatusCell,
  TimestampCell
} from 'ui/listViewTableCell';
import { NodeBackend } from 'models/websocket';
import { ReportStatusCell } from 'ui/listView/reportRow';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const SelectorReportRow: React.FC<{
  item: ReportItem;
  onClickItem?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
  checked: boolean;
  disableCheck?: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
}> = ({
  item,
  onClickItem,
  clearForm,
  checked,
  disableCheck,
  onCheckChanged
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  const onClickFolder = onClickItem
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;

  if (item.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell
          href="#"
          name={item.name}
          icon={<Folder />}
          onClick={onClickFolder}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell href="#" name={item.name} onClick={onClick} />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <ReportStatusCell item={item} />
        <TimestampCell time={item.created_at} />
        <TimestampCell time={item.updated_at} />
      </>
    );
  }
};

export const SelectorProjectRow: React.FC<{
  item: ProjectItem;
  onClickItem?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
  checked: boolean;
  disableCheck?: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  disableItem?: (item: ListItem) => boolean;
}> = ({
  item,
  onClickItem,
  clearForm,
  checked,
  disableCheck,
  onCheckChanged,
  disableItem
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  const onClickFolder = onClickItem
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  const disabled = disableItem ? disableItem(item) : false;

  if (item.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell
          href="#"
          name={item.name}
          icon={<Folder />}
          onClick={onClickFolder}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && !disabled && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell href="#" name={item.name} onClick={onClick} />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <ProjectStatusCell item={item} />
        <TimestampCell time={item.created_at} />
        <TimestampCell time={item.updated_at} />
      </>
    );
  }
};

export const SelectorDatasourceRow: React.FC<{
  item: BaseItem;
  onClickItem?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
  checked: boolean;
  disableCheck?: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
}> = ({
  item,
  onClickItem,
  clearForm,
  checked,
  disableCheck,
  onCheckChanged
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  const onClickFolder = onClickItem
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;

  if (item.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell
          href="#"
          name={item.name}
          icon={<Folder />}
          onClick={onClickFolder}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
      </>
    );
  } else {
    const ds = item as DatasourceItem;
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell href="#" name={ds.name} onClick={onClick} />
        <TableCell>{AccessLevelString(ds.access_level)}</TableCell>
        <ConnectionCell
          type={ds.type}
          spark={ds.backend === NodeBackend.Spark}
        />
        <DatasourceStatusCell item={ds} />
        <TimestampCell time={ds.created_at} />
        <TimestampCell time={ds.updated_at} />
      </>
    );
  }
};

export const SelectorExportRow: React.FC<{
  item: ExportItem;
  onClickItem?: (item: ListItem) => void;
}> = ({ onClickItem, item }) => {
  const exp = item;
  const onClick = onClickItem
    ? React.useCallback(() => {
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  return (
    <>
      <LinkCell href="#" name={exp.destination} onClick={onClick} />
      <TableCell>{exp.project_name}</TableCell>
      <TableCell>{exp.workflow_name}</TableCell>
      <NodeStatusCell
        project_id={exp.project_uuid}
        node_id={exp.uuid}
        status={exp.status}
        error={exp.error}
      />
      <ConnectionCell type={exp.type} />
      <TimestampCell time={exp.started_at} />
      <TimestampCell time={exp.ended_at} />
    </>
  );
};
