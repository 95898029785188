import * as React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

export const Checkbox = withStyles({
  root: {
    color: '#D47C3E',
    '&$checked': {
      color: '#D47C3E'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);
