import * as React from 'react';
import { Autocomplete } from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/browser';

import MUITextField, {
  StandardTextFieldProps as MUITextFieldProps
} from '@mui/material/TextField';

import { Variable } from 'models/project';
import { SwitchFieldSchema } from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  flex: {
    display: 'flex',
    width: '100%'
  },
  autoComplete: {
    flexGrow: 1
  }
});

const hookstyles = makeStyles(styles());
export interface TextFieldProps extends MUITextFieldProps {
  variables?: Variable[];
  options?: string[];
  schema: SwitchFieldSchema;
  placeholder: string;
  errors: FieldValidationError;
  onChangeField: (val: string) => void;
  forcePopupIcon?: boolean;
  detailedHelpTooltip?: React.ReactNode;
}

interface TextFieldState {
  value: string;
}

function getSuggestions(
  suggestions: AutoCompleteOptiopn[] | undefined,
  value?: string | null
) {
  if (suggestions == undefined) {
    return [];
  }

  if (!value) {
    return suggestions;
  }

  // カンマ区切りの場合、最後の文字列で補完する
  const inputValues = value.split(',');
  const inputValue = inputValues[inputValues.length - 1].trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return suggestions;
  }

  return suggestions.filter((suggestion) => {
    if (suggestion == undefined || suggestion.value == undefined) {
      Sentry.captureException('Suggestion value is invalid', {
        extra: { suggestions }
      });
      return false;
    }
    return suggestion.value.includes(inputValue);
  });
}

const TextField: React.FunctionComponent<TextFieldProps> = ({
  onChangeField,
  schema,
  errors,
  classes,
  placeholder,
  detailedHelpTooltip,
  ...props
}) => {
  if (schema.detailHelpName && detailedHelpTooltip != undefined) {
    props.fullWidth = false;
  } else if (schema.fullWidth) {
    props.fullWidth = schema.fullWidth;
  } else {
    props.fullWidth = true;
  }

  props.label = placeholder;
  const readOnly = schema.readonly != undefined ? schema.readonly : false;
  errors = Array.isArray(errors) ? errors : [];

  const styleClasses = hookstyles();

  return (
    <div className={styleClasses.flex}>
      <MUITextField
        className={styleClasses.autoComplete}
        onChange={(ev) => onChangeField(ev.target.value)}
        //error={errors.length > 0}
        //helperText={errors.length > 0 ? errors[0] : ''}
        variant={'outlined'}
        size="small"
        InputProps={{
          readOnly,
          style: { height: '48.2px', backgroundColor: '#ffffff' }
        }}
        InputLabelProps={{
          style: { paddingTop: '7px' }
        }}
        {...props}
      />
      {detailedHelpTooltip}
    </div>
  );
};

interface AutoCompleteOptiopn {
  label: string;
  value: string;
}

class AutoCompleteTextField extends React.Component<
  TextFieldProps & WithStyles<typeof styles>,
  TextFieldState
> {
  static getDerivedStateFromProps(
    nextProps: TextFieldProps,
    prevState: TextFieldState
  ) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  handleChangeAutocompleteValue = (_, option: AutoCompleteOptiopn) => {
    // autocompleteでのinputの場合
    if (!option) {
      return;
    }
    const { value } = this.state;
    const { onChangeField } = this.props;
    const selectedValue = option.value;

    // カンマで区切られてた場合、カンマの後の文字列がautocompleteの対象になる
    const lastCommaIndex = value == null ? -1 : value.lastIndexOf(',');
    const newInput =
      lastCommaIndex === -1
        ? selectedValue
        : value.substring(0, lastCommaIndex + 1) + selectedValue;

    this.setState({ value: newInput });
    onChangeField(newInput);
  };

  handleChangeInputValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // autocomplete以外のinputの場合
    const { onChangeField } = this.props;
    this.setState({ value: ev.target.value });
    onChangeField(ev.target.value);
  };

  render() {
    const { value } = this.state;
    const {
      forcePopupIcon,
      variables,
      options,
      errors,
      detailedHelpTooltip,
      classes,
      placeholder
    } = this.props;

    let suggetions: AutoCompleteOptiopn[] = [];
    if (options != undefined) {
      suggetions = suggetions.concat(
        options.map((o) => ({ label: o, value: o }))
      );
    }
    if (variables != undefined) {
      suggetions = suggetions.concat(
        variables.map((v) => ({
          label: `変数 ${v.name}`,
          value: `$${v.name}`
        }))
      );
    }
    return (
      (<div className={classes.flex}>
        <Autocomplete
          className={classes.autoComplete}
          style={{ flex: '1 1 auto' }}
          forcePopupIcon={forcePopupIcon}
          disableClearable={true}
          freeSolo={true}
          value={value}
          onChange={this.handleChangeAutocompleteValue}
          options={suggetions}
          filterOptions={(options: AutoCompleteOptiopn[]) =>
            getSuggestions(options, value)
          }
          getOptionLabel={(option: AutoCompleteOptiopn | string) =>
            typeof option === 'string' ? option : option.label
          }
          isOptionEqualToValue={(option: AutoCompleteOptiopn, val: string) =>
            option.value === val
          }
          renderInput={(params) => (
            <MUITextField
              {...params}
              onChange={this.handleChangeInputValue}
              label={placeholder}
              fullWidth={true}
              variant={'outlined'}
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { height: '48.96px', backgroundColor: '#ffffff' }
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: '6px 0',
                  height: '26.5px'
                }
              }}
              InputLabelProps={{
                style: { paddingTop: '9px' }
              }}
              error={errors.length > 0}
              helperText={errors.length > 0 ? errors[0] : ''}
            />
          )}
          openOnFocus={true}
        />
        {detailedHelpTooltip}
      </div>)
    );
  }
}

const StyledComponent = withStyles(styles)(AutoCompleteTextField);

export default function (props: TextFieldProps) {
  if (!isEmpty(props.variables) || !isEmpty(props.options)) {
    const { ref, classes, ...rest } = props;
    return <StyledComponent {...rest} />;
  }

  return <TextField {...props} />;
}
