import * as React from 'react';
import {
  Button,
  ButtonProps,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles({
  root: {
    justifyContent: 'center'
  }
});

export type DialogActionsButtonProps =
  // | JSX.Element
  ButtonProps & {
    label: string;
  };

export type DialogActionsProps = MuiDialogActionsProps & {
  secondaryOKButton?: DialogActionsButtonProps;
};

export const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  secondaryOKButton,
  ...props
}) => {
  const classes = styles();

  const renderSecondaryOKButton = React.useCallback(() => {
    if (!secondaryOKButton) {
      return <></>;
    }
    if (React.isValidElement(secondaryOKButton)) {
      return { secondaryOKButton };
    }

    if (typeof secondaryOKButton === 'object' && 'label' in secondaryOKButton) {
      return (
        <Button variant="contained" color="common_color" {...secondaryOKButton}>
          {secondaryOKButton.label}
        </Button>
      );
    }
  }, [secondaryOKButton]);

  return (
    <MuiDialogActions className={classes.root} {...props}>
      {children}
      {renderSecondaryOKButton()}
    </MuiDialogActions>
  );
};
