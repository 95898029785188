import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { TextColorBase } from '../../theme';

export const Tabs = styled(MuiTabs)(() => ({
  color: '#e2762a',
  height: 64,
  borderBottom: 'none',
  alignItems: 'center',
  '& .MuiTabs-indicator': {
    height: 0
  }
}));

export const Tab = styled(MuiTab)(() => ({
  height: 32,
  minHeight: 32,
  borderRadius: 3,
  fontWeight: 500,
  paddingTop: 0,
  paddingBottom: 0,
  color: TextColorBase,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  marginRight: 10,
  opacity: 1,
  borderBottom: 'none',
  '&:hover': {
    color: '#e2762a',
    backgroundColor: 'rgba(0, 0, 0, 0.29)'
  },
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#e2762a',
    borderBottom: 'none',
    '&:hover': {
      opacity: 1,
      color: '#fff',
      backgroundColor: '#e2762a'
    }
  }
}));
