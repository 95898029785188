import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Button, ButtonProps } from '@mui/material';

const useStyles = makeStyles({
  root: {
    fontSize: 12,
    minWidth: 140,
    height: 32,
    color: '#344955',
    backgroundColor: 'rgba(52, 73, 85, 0.1)',
    fontWeight: 'bold',
    '&:hover': {
      color: '#344955',
      backgroundColor: 'rgba(52, 73, 85, 0.29)'
    }
  },
  active: {
    color: '#fff',
    backgroundColor: '#344955',
    '&:hover': {
      opacity: 1,
      color: '#fff',
      backgroundColor: '#47616f'
    }
  }
});

export const TabButton: React.FC<
  {
    children?: React.ReactNode;
    onClick?: () => void;
    active: boolean;
  } & ButtonProps
> = ({ children, onClick, active, ...rest }) => {
  const classes = useStyles();
  const classname = clsx(classes.root, { [classes.active]: active });

  return (
    <Button
      classes={{ root: classname }}
      style={{ textTransform: 'none' }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};
