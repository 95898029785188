import * as React from 'react';
import {
  Button,
  Typography,
  Tab,
  Tabs,
  Box,
  IconButton,
  SvgIcon
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { Dialog as Dialog } from 'ui/common/dialog';
import { DataSummary } from 'models/data';
import {
  FieldTypes,
  SelectFieldSchema,
  mlPreprocessFieldSchema,
  RadioFieldSchema,
  SelectFieldValueElement
} from 'models/form/schema';
import {
  ColumnSelectV2Value,
  mlPreprocessValue,
  mlPreprocessTransformer,
  mlPreprocessImputer,
  mlPreprocessFeatureSelector,
  SelectTypes
} from 'models/form/value';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';
import { PortStatus } from 'models/graph';
import { Dtypes } from 'Utils/dataTypes';
import SelectField from 'components/form/selectField';
import { UseColumnCondition } from 'components/form/switchFieldComponent/useColumnCondition';
import { ColumnSelectV2Field } from 'components/form/columnSelectV2Field';
import RadioField from 'components/form/radioField';
import { DialogDetailedHelpTooltip } from '../ui/DialogDetailedHelpTooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import TextField from 'components/form/switchFieldComponent/textField';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';

interface mlPreprocessFieldProps {
  value: mlPreprocessValue | null;
  columns: DataSummary['columns'];
  dtypes: DataSummary['dtypes'];
  schema: mlPreprocessFieldSchema;
  onChangeField: ChangeFieldHandler;
  projectId: DataSummary['projectId'];
  portId?: string;
  dataStatus: PortStatus;
  excludeValues: string[];
  includeValues: string[];
  detailedHelpTooltip?: React.ReactNode;
}

export const generateNewRule = (): mlPreprocessValue => ({
  num_imputer: [{ strategy: ['mean'], use_all_cols: true }],
  cat_imputer: [{ strategy: ['most_frequent'], use_all_cols: true }],
  cat_transformer: [{ strategy: ['onehot'], use_all_cols: true }]
});

const get_success_process = (
  value: (string | undefined)[]
): JSX.Element[] | null => {
  if (value == undefined) {
    return null;
  }
  const tmp = value.filter((v: string) => v != undefined && v !== '');
  const comment = tmp.map((v) => {
    return (
      <>
        <Typography>{'・' + v}</Typography>
      </>
    );
  });
  return comment;
};

export const getMlpreprosessError = (value: mlPreprocessValue) => {
  const tmp_check_preprocess = [
    { value: value.num_imputer, process_name: '欠損値処理(数値)' },
    { value: value.cat_imputer, process_name: '欠損値処理(文字列)' },
    { value: value.cat_transformer, process_name: '文字列変換' },
    { value: value.num_transformer, process_name: '数値列変換' },
    { value: value.outlier_remover, process_name: '外れ値処理' },
    { value: value.feature_selector, process_name: '特徴量選択' }
  ].map((v) => {
    const value = v.value;
    const process_name = v.process_name;
    if (value == undefined) {
      return { status: 'error', message: '', process_name: process_name };
    }
    if (process_name === '特徴量選択') {
      const [status, message] = validateFeatureSelect(
        value as mlPreprocessFeatureSelector
      );
      return { status: status, message: message, process_name: process_name };
    } else {
      const [status, message] = validateValues(value as mlPreprocessImputer[]);
      return { status: status, message: message, process_name: process_name };
    }
  });

  const check_error_preprocess = tmp_check_preprocess.find(
    (v: { status: string; message: string; process_name: string }) =>
      v.status === 'error' && v.message !== ''
  );
  return check_error_preprocess;
};

export const MlPreprocessField: React.FC<mlPreprocessFieldProps> = ({
  value,
  columns,
  dtypes,
  schema,
  onChangeField,
  projectId,
  portId,
  dataStatus,
  includeValues,
  detailedHelpTooltip
}) => {
  const [open, setOpen] = React.useState(false);
  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const onChange = React.useCallback(
    (value: mlPreprocessValue) => {
      onChangeField(schema.key, value, []);
    },
    [schema, onChangeField]
  );
  React.useEffect(() => {
    if (!value) {
      onChange(generateNewRule());
      if (schema.autoOpen) {
        setOpen(true);
      }
    }
  }, []);
  if (!value) {
    return null;
  }
  const includeIndex = columns.flatMap((s, i) =>
    includeValues.includes(s) ? i : []
  );
  const includeColumns = includeIndex.map((s) => columns[s]);
  const includeDtypes = includeIndex.map((s) => dtypes[s]);

  const tmp_check_preprocess = [
    { value: value.cat_imputer, process_name: '欠損値処理(文字列)' },
    { value: value.cat_transformer, process_name: '文字列変換' },
    { value: value.num_imputer, process_name: '欠損値処理(数値)' },
    { value: value.num_transformer, process_name: '数値列変換' },
    { value: value.outlier_remover, process_name: '外れ値処理' },
    { value: value.feature_selector, process_name: '特徴量選択' }
  ].map((v) => {
    const value = v.value;
    const process_name = v.process_name;
    if (value == undefined) {
      return { status: 'error', message: '', process_name: process_name };
    }
    if (process_name === '特徴量選択') {
      const [status, message] = validateFeatureSelect(
        value as mlPreprocessFeatureSelector
      );
      return { status: status, message: message, process_name: process_name };
    } else {
      const [status, message] = validateValues(value as mlPreprocessImputer[]);
      return { status: status, message: message, process_name: process_name };
    }
  });

  const check_error_preprocess = tmp_check_preprocess.find(
    (v: { status: string; message: string; process_name: string }) =>
      v.status === 'error' && v.message !== ''
  );

  const check_success_process = tmp_check_preprocess.map(
    (v: { status: string; message: string; process_name: string }) => {
      if (v.status === 'setting') {
        return v.process_name;
      }
    }
  );
  const success_process_message = get_success_process(check_success_process);

  return (
    <div>
      {dataStatus === PortStatus.Loading && <div>データをロード中です...</div>}
      {columns.length === 0 && dataStatus === PortStatus.Loaded && (
        <Typography color="error" variant="body2">
          選択可能な列がありません
        </Typography>
      )}
      <Button color="primary" variant="outlined" onClick={onOpen}>
        前処理設定
      </Button>
      <Typography
        variant="subtitle2"
        gutterBottom={true}
        display="inline"
        style={{ marginLeft: 8 }}
      ></Typography>
      <MlPreprocessDialog
        value={value}
        open={open}
        schema={schema}
        onChange={onChange}
        onClose={onClose}
        projectId={projectId}
        portId={portId}
        columns={includeColumns}
        dtypes={includeDtypes}
        dataStatus={dataStatus}
      />
      {check_error_preprocess != undefined && (
        <div>
          <Typography
            color="error"
            variant="body2"
            style={{ whiteSpace: 'pre' }}
          >
            {check_error_preprocess.process_name +
              '\n' +
              '・' +
              check_error_preprocess.message}
          </Typography>
        </div>
      )}
      {check_error_preprocess == undefined &&
        success_process_message !== null && (
          <div>
            <Typography style={{ fontWeight: 'bold' }}>設定済み</Typography>
            {success_process_message}
          </div>
        )}
      {detailedHelpTooltip}
    </div>
  );
};

const dialogStyles = makeStyles({
  title: {
    backgroundColor: '#f0f0f0'
  },
  content: {
    backgroundColor: '#e0e0e0',
    padding: 0
  },
  columnListContainer: {
    padding: '24px 10px',
    width: 800,
    height: 300,
    overflow: 'hidden',
    paddingRight: 10
  },
  columnListTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#414141',
    paddingBottom: 5,
    borderBottom: '2px solid #b0b0b0'
  },
  rulesContainer: {
    width: 704,
    minHeight: 350,
    backgroundColor: '#fff',
    padding: '24px 32px',
    overflowY: 'auto'
  },
  dialogActions: {
    backgroundColor: '#f0f0f0'
  },
  actions: {
    width: 704,
    justifyContent: 'center'
  }
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '700px', overflow: 'scroll', maxHeight: '430px' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

interface VerticalTabsProps {
  projectId: string;
  portId?: string;
  processValues: mlPreprocessValue;
  onChange: (val: mlPreprocessValue) => void;
  columns: string[];
  dtypes: Dtypes[];
  dataStatus: PortStatus;
  schema: mlPreprocessFieldSchema;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#eee'
    },
    activeTab: {
      fontWeight: 'bold'
    },
    indicator: {
      left: '0px',
      backgroundColor: '#344955',
      width: '8px'
    },
    labelContainer: {
      width: 'auto',
      padding: 0
    },
    tabsroot: {
      width: '184px',
      height: '72px',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  })
);

function getDefaultedValues(
  processValues: mlPreprocessValue
): Required<mlPreprocessValue> {
  return {
    outlier_remover: processValues.outlier_remover ?? [],
    num_imputer: processValues.num_imputer ?? [
      { strategy: ['mean'], use_all_cols: true }
    ],
    cat_imputer: processValues.cat_imputer ?? [
      { strategy: ['most_frequent'], use_all_cols: true }
    ],
    num_transformer: processValues.num_transformer ?? [],
    cat_transformer: processValues.cat_transformer ?? [
      { strategy: ['onehot'], use_all_cols: true }
    ],
    feature_selector: processValues.feature_selector ?? {
      feature_selector_strategy: 'disable'
    }
  };
}

const get_detail_help = (
  schema: mlPreprocessFieldSchema,
  key: string
): React.ReactNode => {
  const detail_help_schema = schema.dialogDetailHelps;
  if (detail_help_schema == undefined) {
    return null;
  }
  const target_help = detail_help_schema.find((v) => v.key === key);
  if (target_help == undefined) {
    return null;
  }
  if (target_help.help == undefined) {
    return null;
  }
  return (
    <DialogDetailedHelpTooltip
      moduleName="ml_preprocess"
      filename={target_help.help}
    />
  );
};

function getField(
  process_type: preprocessType,
  schema: mlPreprocessFieldSchema,
  base_props,
  props
): React.ReactNode {
  if (process_type === preprocessType.transform) {
    return (
      <ProcessTransformerSetting
        schema={schema}
        {...{ ...base_props, ...props }}
      />
    );
  }
  if (process_type === preprocessType.impute) {
    return (
      <ProcessImputerSetting schema={schema} {...{ ...base_props, ...props }} />
    );
  }
  return;
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  projectId,
  portId,
  processValues,
  onChange,
  columns,
  dtypes,
  dataStatus,
  schema
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const values = getDefaultedValues(processValues);
  const onChangeHandler = (key: keyof mlPreprocessValue) => (newVal) => {
    onChange({ ...values, [key]: newVal });
  };

  const base_props = {
    projectId: projectId,
    portId: portId,
    columns: columns,
    dtypes: dtypes,
    dataStatus: dataStatus
  };

  const tab_map = [
    {
      name: '欠損値処理(数値)',
      value: values.num_imputer,
      field: preprocessType.impute,
      props: {
        values: values.num_imputer,
        type: preprocessType.impute,
        onChange: onChangeHandler('num_imputer'),
        items: NumImputerOperator,
        validate_dtypes: [Dtypes.NUMBER],
        init_value: ['mean'],
        column_select_text: '全数値列を対象にする',
        help_key: 'impute_num',
        placceholder: '数値を入力(複数可)'
      }
    },
    {
      name: '欠損値処理(文字列)',
      value: values.cat_imputer,
      field: preprocessType.impute,
      props: {
        values: values.cat_imputer,
        type: preprocessType.impute,
        onChange: onChangeHandler('cat_imputer'),
        items: CatImputerOperator,
        validate_dtypes: [Dtypes.STRING],
        init_value: ['most_frequent'],
        column_select_text: '全文字列を対象にする',
        help_key: 'impute_cat',
        placceholder: '文字を入力(複数可)'
      }
    },
    {
      name: '文字列変換',
      value: values.cat_transformer,
      field: preprocessType.transform,
      props: {
        values: values.cat_transformer,
        type: preprocessType.transform,
        onChange: onChangeHandler('cat_transformer'),
        items: CatTransformerOperator,
        validate_dtypes: [Dtypes.STRING],
        init_value: ['label'],
        help_key: 'transform_cat',
        column_select_text: '全文字列を対象にする'
      }
    },
    {
      name: '数値列変換',
      value: values.num_transformer,
      field: preprocessType.transform,
      props: {
        isUnprocessedComparison: true,
        values: values.num_transformer,
        type: preprocessType.transform,
        onChange: onChangeHandler('num_transformer'),
        items: NumTransformerOperator,
        validate_dtypes: [Dtypes.NUMBER],
        help_key: 'transform_num',
        column_select_text: '全数値列を対象にする'
      }
    },
    {
      name: '外れ値処理',
      value: values.outlier_remover,
      field: preprocessType.transform,
      props: {
        isUnprocessedComparison: true,
        values: values.outlier_remover,
        type: preprocessType.transform,
        onChange: onChangeHandler('outlier_remover'),
        items: OutlierRemoverOperator,
        validate_dtypes: [Dtypes.NUMBER],
        help_key: 'outlier',
        column_select_text: '全数値列を対象にする'
      }
    }
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex'
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        aria-label="Vertical tabs example"
        onChange={handleChange}
        className={classes.root}
        classes={{
          indicator: classes.indicator
        }}
      >
        {tab_map.map((k, index) => {
          return (
            <Tab
              label={<span className={classes.activeTab}>{k.name}</span>}
              classes={{
                root: classes.tabsroot
              }}
              key={k.name}
              icon={icon(validateValues(k.value))}
              {...a11yProps(index)}
            />
          );
        })}
        <Tab
          label={<span className={classes.activeTab}>特徴量選択</span>}
          classes={{
            root: classes.tabsroot
          }}
          icon={icon(validateFeatureSelect(values.feature_selector))}
          {...a11yProps(5)}
        />
      </Tabs>
      {tab_map.map((k, index) => {
        const field = getField(k.field, schema, base_props, k.props);
        return (
          <TabPanel value={value} index={index} key={index.toString()}>
            {field}
          </TabPanel>
        );
      })}
      <TabPanel value={value} index={5}>
        <FeatureSelectSetting
          values={values.feature_selector}
          schema={schema}
          onChange={onChangeHandler('feature_selector')}
        />
      </TabPanel>
    </Box>
  );
};

export enum preprocessType {
  impute = 'impute',
  transform = 'transform'
}

interface ProcessImputerSettingProps {
  projectId: DataSummary['projectId'];
  portId?: string;
  isUnprocessedComparison?: boolean;
  type: preprocessType;
  values: mlPreprocessImputer[];
  items: SelectFieldValueElement[];
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: mlPreprocessImputer[]) => void;
  dataStatus: PortStatus;
  validate_dtypes: Dtypes[];
  init_value?: string[];
  column_select_text: string;
  placceholder?: string;
  schema: mlPreprocessFieldSchema;
  help_key: string;
}

interface ProcessTransformerSettingProps {
  projectId: DataSummary['projectId'];
  portId?: string;
  isUnprocessedComparison?: boolean;
  type: preprocessType;
  values: mlPreprocessTransformer[];
  items: SelectFieldValueElement[];
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: mlPreprocessTransformer[]) => void;
  dataStatus: PortStatus;
  validate_dtypes: Dtypes[];
  init_value?: string[];
  column_select_text: string;
  schema: mlPreprocessFieldSchema;
  help_key: string;
}

const OneOfArrayRuleStyles = makeStyles({
  columnSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '14%'
    //width: '230px'
  },
  elementSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '18%'
  },
  operatorSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '18%'
    //width: '230px'
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 10px',
    //overflowY: 'auto',
    backgroundColor: '#ededed',
    border: '2px #c3c3c3 solid',
    borderRadius: '3px',
    height: '100%',
    width: '100%',
    marginBottom: '10px'
  },
  conditionContainer: {
    //overflowY: 'auto',
    backgroundColor: '#ffffff',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: '3px',
    position: 'relative',
    paddingBottom: 10
  },
  elseContainer: {
    width: 990,
    minHeight: 350,
    backgroundColor: '#e0e0e0',
    padding: '24px 32px',
    //overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex'
  }
});

const ProcessTransformerSetting: React.FC<ProcessTransformerSettingProps> = ({
  projectId,
  portId,
  values,
  items,
  columns,
  dtypes,
  onChange,
  dataStatus,
  validate_dtypes,
  init_value,
  column_select_text,
  isUnprocessedComparison,
  schema,
  help_key
}) => {
  const classes = OneOfArrayRuleStyles();
  const detail_help = get_detail_help(schema, help_key);

  const handleChange = React.useCallback(
    (val: any) => onChange(val as mlPreprocessTransformer[]),
    [onChange]
  );

  return (
    <>
      <div>
        {values.map((v: mlPreprocessTransformer, index: number) => {
          const strategy = v.strategy != undefined ? v.strategy : undefined;
          const selectColumn =
            v.target_cols != undefined ? v.target_cols : null;
          const useAllCols =
            v.use_all_cols != undefined ? v.use_all_cols : true;
          const compare_with_disable =
            v.compare_with_disable != undefined
              ? v.compare_with_disable
              : false;

          return (
            <div style={{ display: 'flex' }} key={index.toString()}>
              <div key="a-root" className={classes.ruleContainer}>
                <div key={index} className={classes.conditionContainer}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '6px 20px',
                      justifyContent: 'space-around'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 275
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          style={{
                            color: '#6e6e6e',
                            paddingTop: '16px',
                            fontSize: 12
                          }}
                        >
                          手法選択
                        </Typography>
                        {detail_help}
                      </div>
                      <SelectOperator
                        value={strategy}
                        items={items}
                        onChange={(newVal) => {
                          const newState = produce(values, (draft) => {
                            draft[index].strategy = newVal;
                          });
                          handleChange(newState);
                        }}
                        placeholder=""
                        index={index}
                        detailedHelpTooltip={null}
                      />
                      {isUnprocessedComparison === true && (
                        <div style={{ display: 'flex' }}>
                          <UseColumnCondition
                            operator={null}
                            check={compare_with_disable}
                            onChange={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].compare_with_disable = newVal;
                              });
                              handleChange(newState);
                            }}
                            label="処理を行わない場合とも比較する"
                            isReturnValue={true}
                          />
                          {get_detail_help(schema, 'not_process')}
                        </div>
                      )}
                    </div>
                    {useAllCols !== true && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '30px'
                        }}
                      >
                        <ColumnSelectV2Field
                          value={selectColumn}
                          schema={{
                            key: 'column_select',
                            type: FieldTypes.column_select_v2,
                            dtypes: validate_dtypes
                          }}
                          columns={columns}
                          dtypes={dtypes}
                          onChangeField={(
                            _,
                            newVal: ColumnSelectV2Value,
                            __
                          ) => {
                            const newState = produce(values, (draft) => {
                              draft[index].target_cols = newVal;
                            });
                            handleChange(newState);
                          }}
                          projectId={projectId}
                          portId={portId}
                          dataStatus={dataStatus}
                          detailedHelpTooltip={null}
                        />
                        <div style={{ display: 'flex' }}>
                          <UseColumnCondition
                            operator={null}
                            check={useAllCols}
                            onChange={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].use_all_cols = newVal;
                              });
                              handleChange(newState);
                            }}
                            label={column_select_text}
                            isReturnValue={true}
                          />
                          {get_detail_help(schema, 'column_checkbox')}
                        </div>
                      </div>
                    )}
                    {useAllCols === true && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            position: 'inherit',
                            top: '50%',
                            transform: 'translate(0, -50%)'
                          }}
                        >
                          <UseColumnCondition
                            operator={null}
                            check={useAllCols}
                            onChange={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].use_all_cols = newVal;
                              });
                              handleChange(newState);
                            }}
                            label={column_select_text}
                            isReturnValue={true}
                          />
                          {get_detail_help(schema, 'column_checkbox')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DeleteRule
                  conditions={values}
                  index={index}
                  conditionOnChange={handleChange}
                />
              </div>
            </div>
          );
        })}
        <AddRule
          conditions={values}
          index={values.length}
          strategy={init_value}
          conditionOnChange={handleChange}
        />
      </div>
    </>
  );
};

const ProcessImputerSetting: React.FC<ProcessImputerSettingProps> = ({
  projectId,
  portId,
  values,
  items,
  columns,
  dtypes,
  onChange,
  dataStatus,
  validate_dtypes,
  init_value,
  column_select_text,
  placceholder,
  schema,
  help_key
}) => {
  const classes = OneOfArrayRuleStyles();
  const detail_help = get_detail_help(schema, help_key);

  const handleChange = React.useCallback(
    (val: any) => onChange(val as mlPreprocessImputer[]),
    [onChange]
  );

  return (
    <>
      <div>
        {values.map((v: mlPreprocessImputer, index: number) => {
          const strategy = v.strategy != undefined ? v.strategy : undefined;
          const selectColumn =
            v.target_cols != undefined ? v.target_cols : null;
          const useAllCols =
            v.use_all_cols != undefined ? v.use_all_cols : true;
          const fillValue = v.fill_value != undefined ? v.fill_value : '';

          let isFixValue = false;
          if (strategy != undefined) {
            isFixValue = strategy.includes('constant');
          }
          return (
            <div style={{ display: 'flex' }} key={index.toString()}>
              <div key="a-root" className={classes.ruleContainer}>
                <div key={index} className={classes.conditionContainer}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '6px 20px',
                      justifyContent: 'space-around'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 275
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          style={{
                            color: '#6e6e6e',
                            paddingTop: '16px',
                            fontSize: 12
                          }}
                        >
                          手法選択
                        </Typography>
                        {detail_help}
                      </div>
                      <SelectOperator
                        value={strategy}
                        items={items}
                        onChange={(newVal) => {
                          const newState = produce(values, (draft) => {
                            draft[index].strategy = newVal;
                          });
                          handleChange(newState);
                        }}
                        placeholder=""
                        index={index}
                        detailedHelpTooltip={null}
                      />
                      {isFixValue && (
                        <>
                          <div style={{ display: 'flex' }}>
                            <Typography
                              style={{
                                color: '#6e6e6e',
                                paddingTop: '16px',
                                fontSize: 12
                              }}
                            >
                              補完する値を入力(複数可)
                            </Typography>
                            {get_detail_help(schema, 'fixed_value')}
                          </div>
                          <TextField
                            value={String(fillValue)}
                            errors={[]}
                            placeholder={
                              placceholder != undefined ? placceholder : ''
                            }
                            schema={{
                              key: 'text',
                              type: FieldTypes.switch
                            }}
                            onChangeField={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].fill_value = newVal;
                              });
                              handleChange(newState);
                            }}
                            detailedHelpTooltip={null}
                          />
                        </>
                      )}
                    </div>
                    {useAllCols !== true && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '30px'
                        }}
                      >
                        <ColumnSelectV2Field
                          value={selectColumn}
                          schema={{
                            key: 'column_select',
                            type: FieldTypes.column_select_v2,
                            dtypes: validate_dtypes
                          }}
                          columns={columns}
                          dtypes={dtypes}
                          onChangeField={(
                            _,
                            newVal: ColumnSelectV2Value,
                            __
                          ) => {
                            const newState = produce(values, (draft) => {
                              draft[index].target_cols = newVal;
                            });
                            handleChange(newState);
                          }}
                          projectId={projectId}
                          portId={portId}
                          dataStatus={dataStatus}
                          detailedHelpTooltip={null}
                        />
                        <div style={{ display: 'flex' }}>
                          <UseColumnCondition
                            operator={null}
                            check={useAllCols}
                            onChange={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].use_all_cols = newVal;
                              });
                              handleChange(newState);
                            }}
                            label={column_select_text}
                            isReturnValue={true}
                          />
                          {get_detail_help(schema, 'column_checkbox')}
                        </div>
                      </div>
                    )}
                    {useAllCols === true && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            position: 'inherit',
                            top: '50%',
                            transform: 'translate(0, -50%)'
                          }}
                        >
                          <UseColumnCondition
                            operator={null}
                            check={useAllCols}
                            onChange={(newVal) => {
                              const newState = produce(values, (draft) => {
                                draft[index].use_all_cols = newVal;
                              });
                              handleChange(newState);
                            }}
                            label={column_select_text}
                            isReturnValue={true}
                          />
                          {get_detail_help(schema, 'column_checkbox')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DeleteRule
                  conditions={values}
                  index={index}
                  conditionOnChange={handleChange}
                />
              </div>
            </div>
          );
        })}
        <AddRule
          conditions={values}
          index={values.length}
          strategy={init_value}
          conditionOnChange={handleChange}
        />
      </div>
    </>
  );
};

const generateCondition = (
  strategy?: string[]
): mlPreprocessImputer | mlPreprocessTransformer => ({
  strategy: strategy,
  use_all_cols: true
});

export const AddRule: React.FC<{
  conditions: (mlPreprocessImputer | mlPreprocessTransformer)[];
  index: number;
  strategy?: string[];
  conditionOnChange: (
    val: (mlPreprocessImputer | mlPreprocessTransformer)[]
  ) => void;
}> = ({ conditions, index, strategy, conditionOnChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onAdd = (index: number) => () => {
    const newConditions = [
      ...conditions.slice(0, index + 1),
      generateCondition(strategy),
      ...conditions.slice(index + 1)
    ];
    // index + 1の場所にアイテムを追加する
    conditionOnChange(newConditions);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={onAdd(index)}
        size="small"
        style={{ marginRight: 10, marginLeft: 10, color: '#344955' }}
      >
        <AddCircle fontSize="medium" />
      </IconButton>
    </div>
  );
};

export const DeleteRule: React.FC<{
  conditions: (mlPreprocessImputer | mlPreprocessTransformer)[];
  index: number;
  conditionOnChange: (
    val: (mlPreprocessImputer | mlPreprocessTransformer)[]
  ) => void;
}> = ({ conditions, index, conditionOnChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onDelete = (index: number) => () => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    // indexのアイテムを削除する
    conditionOnChange(newConditions);
    setAnchorEl(null);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={onDelete(index)}
        size="small"
        style={{ marginRight: 10, marginLeft: 10, color: '#344955' }}
      >
        <DeleteIcon fontSize="medium" />
      </IconButton>
    </div>
  );
};

interface FeatureSelectSettingProps {
  values: mlPreprocessFeatureSelector;
  onChange: (val: mlPreprocessFeatureSelector) => void;
  schema: mlPreprocessFieldSchema;
}

const FeatureSelectSetting: React.FC<FeatureSelectSettingProps> = ({
  values,
  onChange,
  schema
}) => {
  const radio_schema: RadioFieldSchema = {
    key: 'feature_selector_strategy',
    type: FieldTypes.radio,
    items: [
      { label: '特徴量選択を行わない', value: 'disable' },
      { label: '影響度上位1列、２列、3列…と全列試す', value: 'explore' },
      { label: '影響度上位から何列選択するかを自分で設定する', value: 'manual' }
    ]
  };
  const handleOnChange = React.useCallback(
    (_, val: string, __) =>
      onChange({
        feature_selector_strategy: val,
        feature_selector_topn: values.feature_selector_topn
      }),
    [onChange]
  );
  const handleOnChangeText = React.useCallback(
    (val: string) =>
      onChange({
        feature_selector_strategy: values.feature_selector_strategy,
        feature_selector_topn: val
      }),
    [onChange]
  );
  const detail_help = get_detail_help(schema, 'feature_select');
  const detail_feature_num = get_detail_help(schema, 'feature_num');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ transform: 'translate(-2%, 0px)' }}>{detail_help}</div>
      <RadioField
        value={values.feature_selector_strategy}
        schema={radio_schema}
        onChangeField={handleOnChange}
      />
      {values.feature_selector_strategy === 'manual' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            paddingLeft: '30px'
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            <Typography
              style={{
                color: '#6e6e6e',
                paddingTop: '15px',
                fontSize: 12
              }}
            >
              列数を入力
            </Typography>
            {detail_feature_num}
          </div>
          <TextField
            value={values.feature_selector_topn}
            errors={[]}
            placeholder=""
            schema={{
              key: 'feature_selector_topn',
              type: FieldTypes.switch
            }}
            onChangeField={handleOnChangeText}
            detailedHelpTooltip={null}
          />
        </div>
      )}
    </div>
  );
};

interface mlPreprocessDialogProps {
  open: boolean;
  value: mlPreprocessValue;
  schema: mlPreprocessFieldSchema;
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: mlPreprocessValue) => void;
  onClose: () => void;
  projectId: DataSummary['projectId'];
  portId?: string;
  dataStatus: PortStatus;
  colnameMap?: null;
}

export const initializeRules = (
  value: mlPreprocessValue | undefined
): mlPreprocessValue => {
  if (value == undefined) {
    return generateNewRule();
  }
  return value;
};

const MlPreprocessDialog: React.FC<mlPreprocessDialogProps> = ({
  value,
  open,
  onChange,
  onClose,
  projectId,
  portId,
  columns,
  dtypes,
  dataStatus,
  schema
}) => {
  const classes = dialogStyles();
  const [rules, setRules] = React.useState<mlPreprocessValue>({});
  const onOKClicked = React.useCallback(() => {
    onChange(rules);
    onClose();
  }, [rules, onChange, onClose]);
  React.useEffect(() => {
    // ダイアログを開いたら、rulesにvalueをコピーする
    if (open) {
      setRules(initializeRules(value));
    }
  }, [open]);
  //const [selected, notSelected] = selectColumnsByRule(columns, dtypes, rules);
  //const errors = checkDtypes(selected, schema.dtypes);
  const isError = getMlpreprosessError(rules) != undefined ? true : false;

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={onClose}
      data-cy="column-select-dialog"
      title="学習前に行う事前前処理を設定"
      contentProps={{ className: classes.content }}
      OKButton={{
        onClick: onOKClicked,
        disabled: isError
      }}
    >
      <VerticalTabs
        projectId={projectId}
        portId={portId}
        processValues={rules}
        columns={columns}
        dtypes={dtypes}
        onChange={setRules}
        dataStatus={dataStatus}
        schema={schema}
      />
    </Dialog>
  );
};

const SelectOperator: React.FC<{
  value?: string[];
  items: SelectFieldValueElement[];
  onChange: (val: string[]) => void;
  placeholder: string;
  index: number;
  detailedHelpTooltip: React.ReactNode;
}> = ({ value, items, onChange, placeholder, index, detailedHelpTooltip }) => {
  const handleChange = React.useCallback(
    (_, val: any) => onChange(val as string[]),
    [onChange]
  );

  //const val: SelectFieldValueElement = operator.filter((v) => {
  //  v.value === value;
  //})[0];

  const schema: SelectFieldSchema = {
    key: String(index),
    type: FieldTypes.select,
    items: items,
    multi: true,
    allDtypes: [],
    placeholder: placeholder,
    fullWidth: true,
    variant: 'outlined'
  };

  return (
    <div>
      <SelectField
        clearable={false}
        value={value as any}
        //errors={errors}
        schema={schema}
        onChangeField={handleChange}
        detailedHelpTooltip={detailedHelpTooltip}
      />
    </div>
  );
};

const NumImputerOperator: SelectFieldValueElement[] = [
  {
    value: 'iterative',
    label: '欠損値を予測して補完'
  },
  {
    value: 'mean',
    label: '平均値補完'
  },
  {
    value: 'median',
    label: '中央値補完'
  },
  {
    value: 'most_frequent',
    label: '最頻値補完'
  },
  {
    value: 'constant',
    label: '固定値補完'
  }
];

const CatImputerOperator: SelectFieldValueElement[] = [
  {
    value: 'most_frequent',
    label: '最頻値補完'
  },
  {
    value: 'constant',
    label: '固定値補完'
  }
];

const NumTransformerOperator: SelectFieldValueElement[] = [
  {
    value: 'standard',
    label: '標準化'
  },
  {
    value: 'minmax',
    label: 'MinMax正規化'
  },
  {
    value: 'robust',
    label: 'ロバストZscore正規化'
  },
  {
    value: 'boxcox',
    label: 'boxcox変換'
  },
  {
    value: 'log',
    label: '対数変換'
  }
];

const CatTransformerOperator: SelectFieldValueElement[] = [
  {
    value: 'label',
    label: 'LabelEncode'
  },
  {
    value: 'onehot',
    label: 'OneHotEncode'
  }
];

const OutlierRemoverOperator: SelectFieldValueElement[] = [
  {
    value: 'IsolationForest',
    label: 'IsolationForest'
  },
  {
    value: 'LocalOutlierFactor',
    label: 'LocalOutlierFactor'
  }
];

const isEmpty = (val: Dtypes | string | string[]): boolean => {
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return val === '';
};

export const validateValues = (
  value: mlPreprocessImputer[]
): [string, string] => {
  if (value == undefined) {
    return ['error', '不明なエラー'];
  }
  if (value == undefined || value.length === 0) {
    return ['normal', ''];
  }

  const select_column_check = value.map((v: mlPreprocessImputer): boolean => {
    if (v.use_all_cols === true) {
      return true;
    }
    let tmp = true;
    if (v.use_all_cols === false && v.target_cols != undefined) {
      const rules = v.target_cols.rules;
      rules.forEach((r) => {
        // ルールが空の時はエラー
        if (r.type !== SelectTypes.all_columns && isEmpty(r.value)) {
          tmp = false;
        }
      });
    }
    return tmp;
  });
  if (!select_column_check.every((v) => v)) {
    return ['error', '列選択がされていません'];
  }

  const strategy_check = value.map((v: mlPreprocessImputer): boolean => {
    if (v.strategy == undefined || v.strategy.length === 0) {
      return false;
    }
    return true;
  });

  if (!strategy_check.every((v) => v)) {
    return ['error', '手法が選択されていません'];
  }

  const fill_value_check = value.map((v: mlPreprocessImputer): boolean => {
    const check_strategy =
      v.strategy != undefined && v.strategy.includes('constant');
    if (check_strategy && (v.fill_value == undefined || v.fill_value === '')) {
      return false;
    }
    return true;
  });

  if (!fill_value_check.every((v) => v)) {
    return ['error', '補完する値が入力されていません'];
  }

  return ['setting', ''];
};

export const validateFeatureSelect = (
  value: mlPreprocessFeatureSelector | undefined
): [string, string] => {
  if (value == undefined) {
    return ['error', '不明なエラー'];
  }
  if (value.feature_selector_strategy === 'disable') {
    return ['normal', ''];
  }
  if (value.feature_selector_strategy === 'explore') {
    return ['setting', ''];
  }

  if (
    value.feature_selector_strategy === 'manual' &&
    value.feature_selector_topn != undefined &&
    value.feature_selector_topn !== ''
  ) {
    return ['setting', ''];
  }

  return ['error', '列数が入力されていません'];
};

const icon = (v: string[]) => {
  const status = v[0];
  if (status === 'error') {
    return <ErrorIcon style={{ color: red['A700'] }} />;
  }
  if (status === 'normal') {
    return <EmptyIcon />;
  }
  return <DoneIcon style={{ color: '#7fce5d' }} />;
};

function EmptyIcon(props) {
  return <SvgIcon {...props}></SvgIcon>;
}
