import * as React from 'react';
import { Button } from '@mui/material';
import { ButtonOwnProps, ButtonProps } from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

interface LabeledButtonProps {
  color: ButtonOwnProps['color'];
  onClick: ButtonProps['onClick'];
  label: string;
  disabled?: boolean;
}

const styles = (theme) =>
  createStyles({
    root: {
      height: 64,
      border: 0,
      margin: 0,
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      textTransform: 'none',
      backgroundColor: 'transparent',
      fontSize: theme.typography.pxToRem(12)
    }
  });

const LabeledButton: React.FC<
  LabeledButtonProps & WithStyles<typeof styles>
> = ({ classes, color, onClick, label, disabled, children }) => {
  return (
    <Button
      color={color}
      onClick={onClick}
      classes={{ root: classes.root }}
      disabled={disabled}
    >
      {children}
      {label}
    </Button>
  );
};

export default withStyles(styles)(LabeledButton);
