import * as React from 'react';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { NameEditDialog } from 'components/visualize/form/title';
import { DtypeItems, Dtypes } from 'Utils/dataTypes';
import { getColumnIcon as getColumnIconComponent } from 'components/dataTable/headerCell';

export const MenuItemNameChange: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange, children }) => {
  const [name, setName] = React.useState<string>(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    []
  );

  React.useEffect(() => {
    if (name !== value) {
      setName(value);
    }
  }, [value]);

  const handleSave = React.useCallback(() => {
    onChange(name);
    handleClose();
  }, [name, onChange]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={handleOpen} data-cy="menu-item-name-change">
        {children}
      </MenuItem>
      <NameEditDialog
        anchorEl={anchorEl}
        name={name}
        onChange={handleOnChange}
        onClose={handleClose}
        onClickSave={handleSave}
      />
    </>
  );
};

export const MenuItemDtypeChange: React.FC<{
  onChange: (value: Dtypes) => void;
}> = ({ onChange, children }) => {
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);

  const onClose = () => setMenuEl(null);

  const onClickMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    setMenuEl(ev.currentTarget);
  }, []);

  return (
    <>
      <MenuItem onClick={onClickMenu} data-cy="menu-item-dtype-change">
        {children}
      </MenuItem>
      <Menu
        anchorEl={menuEl}
        keepMounted
        open={Boolean(menuEl)}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 0, horizontal: 'center' }}
        data-cy="change-column-dtype-dialog"
      >
        {DtypeItems.map((item) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => {
                onChange(item.value);
                onClose();
              }}
              data-cy={`change-column-dtype-dialog-${item.value}`}
            >
              <ListItemIcon style={{ marginRight: 10, minWidth: 'unset' }}>
                {getColumnIconComponent(item.value)}
              </ListItemIcon>
              {item.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
