import * as React from 'react';
import { ListItem, VariableItem } from '../../../models/dependency';
import { TableCell } from '@mui/material';
import { AccessLevelString } from 'libs/accessLevel';
import { Folder } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { toDtypeLabel } from 'Utils/dataTypes';
import { toValueLabel } from 'components//variableListView';
import { itemToVariable } from 'actions/variable';
import {
  Checkbox,
  LinkCell,
  OpenMenuType,
  OpenShareDialogType,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const VariableRow: React.FC<{
  item: VariableItem;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  onClickLink?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({
  item,
  onOpenShareDialog,
  onOpenMenu,
  onCheckChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  onClickLink,
  clearForm
}) => {
  const classes = cellStyle();
  const onClick = onClickLink
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickLink(item);
      }, [basePath, clearForm, item, onClickLink])
    : React.useCallback(() => {
        clearForm(item.uuid, item.is_folder, `${basePath}/f/${item.uuid}`);
      }, [basePath, clearForm, item]);
  const isAdmin = location.pathname.startsWith('/admin');

  if (item.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell
          href={`${basePath}/f/${item.uuid}`}
          name={item.name}
          icon={<Folder />}
          onClick={onClick}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
        <ShareDialogCell
          name={item.name}
          uuid={item.uuid}
          accessLevel={item.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        {folderOnly ? (
          <TableCell>{item.name}</TableCell>
        ) : (
          <LinkCell
            href={`${isAdmin ? '/admin' : ''}/variables/${item.uuid}`}
            name={item.name}
          />
        )}
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>{toDtypeLabel(item.dtype)}</TableCell>
        <TableCell>{toValueLabel(itemToVariable(item))}</TableCell>
        <TableCell>{item.desc}</TableCell>
        <TimestampCell time={item.created_at} />
        <TimestampCell time={item.updated_at} />
        <ShareDialogCell
          name={item.name}
          uuid={item.uuid}
          accessLevel={item.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  }
};
