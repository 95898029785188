import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import ReactCalendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Typography } from '@mui/material';

type Range<T> = [T, T];
type NullableDayjs = dayjs.Dayjs | null;
export type DayjsValue = NullableDayjs | Range<NullableDayjs>;

type NullableDate = Date | null;
export type DateValue = NullableDate | Range<NullableDate>;

interface Props<T extends DayjsValue> {
  date: T;
  calendarMode: 'month' | 'year';
  setCalendarMode: React.Dispatch<React.SetStateAction<'month' | 'year'>>;
  setStartMonth: (month: dayjs.Dayjs) => void;
  activeStartDate: dayjs.Dayjs;
  calendarProps?: CalendarProps;
}

const dayjsValueToDateValue = (dateValue: DayjsValue): DateValue => {
  if (Array.isArray(dateValue)) {
    return dateValue.map((d) => (d ? d.toDate() : null)) as Range<NullableDate>;
  }
  return dateValue ? dateValue.toDate() : null;
};

export const MonthCalendar = <T extends DayjsValue>({
  date: _date,
  calendarMode,
  setCalendarMode,
  setStartMonth,
  activeStartDate,
  calendarProps
}: Props<T>) => {
  const date = useMemo(() => {
    return dayjsValueToDateValue(_date);
  }, [_date]);

  return (
    <>
      <Typography variant="subtitle1" align="center">
        {activeStartDate.format('YYYY年')}
      </Typography>
      <ReactCalendar
        onClickMonth={(value) => {
          setStartMonth(dayjs(value));
          setCalendarMode('month');
        }}
        value={date}
        defaultValue={date}
        showNavigation={false}
        view={calendarMode}
        formatDay={(_, date) => date.getDate().toString()}
        activeStartDate={activeStartDate.toDate()}
        {...calendarProps}
        showWeekNumbers={false}
        showFixedNumberOfWeeks={false}
        showDoubleView={false}
        showNeighboringMonth={false}
        allowPartialRange={true}
        minDate={dayjs('1900-01-01').toDate()}
        maxDate={dayjs().add(10, 'year').toDate()}
      />
    </>
  );
};
