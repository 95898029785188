/* tslint:disable */
import { createTheme, darken } from '@mui/material/styles';
import { alpha, Color } from '@mui/material';

// interface NehanPaletteColorOptions extends SimplePaletteColorOptions {
//   headerBorder?: string;
//   headerBg?: string;
//   subHeaderBorder?: string;
//   subHeaderBg?: string;
// }

// interface CustomPalette extends Palette {
//   nehan: NehanPaletteColorOptions;
//   nehan_orange: SimplePaletteColorOptions;
//   common_color: SimplePaletteColorOptions;
//   cancel: SimplePaletteColorOptions;
// }

// export interface CustomTheme extends Theme {
//   palette: CustomPalette;
// }

// interface CustomPaletteOptions extends PaletteOptions {
//   nehan?: NehanPaletteColorOptions;
//   common_color?: SimplePaletteColorOptions;
//   cancel?: SimplePaletteColorOptions;
// }

// export interface CustomThemeOptions extends ThemeOptions {
//   palette?: CustomPaletteOptions;
// }

export const nehanOrangeColor = '#D57B3D';
export const nehanOrangeColorDark = darken(nehanOrangeColor, 0.2);
export const nehanColor = '#0088a7';
export const nehanColorDark = '#0089a7';
export const color = '#344955';
export const NehanProjectColor = '#D47C3E';
export const NehanDatasourceColor = '#b2b351';
export const NehanReportColor = '#C66C6C';
export const NehanScheduleColor = '#0089a7';
export const TextColorBase = color;

export type NehanColors = Color | 'nehan' | 'common_color';

export const fontFamily = [
  'Roboto',
  '"Noto Sans JP"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif'
].join(',');

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    white: Palette['primary'];
    common_color: Palette['primary'];
    nehan_orange: Palette['primary'];
    nehan: Palette['primary'];
    cancel: Palette['primary'];
    page: Palette['primary'];
  }

  interface PaletteOptions {
    black?: Palette['primary'];
    white?: Palette['primary'];
    common_color?: Palette['primary'];
    nehan_orange?: Palette['primary'];
    nehan?: Palette['primary'];
    cancel?: Palette['primary'];
    page?: Palette['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    white: true;
    nehan: true;
    nehan_orange: true;
    common_color: true;
    cancel: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    black: true;
    white: true;
    nehan: true;
    nehan_orange: true;
    common_color: true;
    cancel: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    black: true;
    white: true;
    nehan: true;
    nehan_orange: true;
    common_color: true;
    cancel: true;
  }
}

declare module '@mui/material/Tab' {
  interface TabPropsColorOverrides {
    black: true;
    white: true;
    nehan: true;
    nehan_orange: true;
    common_color: true;
    cancel: true;
  }
}

const defaultTheme = createTheme({});
const augmentColor = defaultTheme.palette.augmentColor;
const theme = createTheme({
  typography: {
    fontFamily
  },
  palette: {
    black: {
      main: TextColorBase,
      light: alpha(TextColorBase, 0.5),
      dark: TextColorBase,
      contrastText: '#ffffff'
    },
    white: augmentColor({
      color: {
        main: '#ffffff',
        contrastText: TextColorBase
      },
      name: 'white'
    }),
    primary: augmentColor({
      color: {
        main: '#3f51b5',
        contrastText: '#e0e0e0'
      },
      name: 'primary'
    }),
    secondary: {
      main: nehanOrangeColor,
      light: alpha(nehanOrangeColor, 0.5),
      dark: nehanOrangeColorDark,
      contrastText: '#ffffff'
    },
    common_color: augmentColor({
      color: {
        main: '#0088a7',
        contrastText: '#ffffff'
      },
      name: 'common_color'
    }),
    cancel: augmentColor({
      color: {
        main: '#e0e0e0',
        contrastText: TextColorBase
      },
      name: 'cancel'
    }),
    nehan_orange: augmentColor({
      color: {
        main: nehanOrangeColor,
        contrastText: '#ffffff'
      },
      name: 'nehan_orange'
    }),
    nehan: {
      main: nehanOrangeColor,
      light: alpha(nehanOrangeColor, 0.5),
      dark: nehanOrangeColorDark,
      contrastText: '#ffffff'
    },
    page: {
      main: '#C66C6C',
      light: '#fcf7f7',
      dark: '#C66C6C',
      contrastText: '#ffffff'
    },
    text: {
      primary: TextColorBase
    }
  },
  components: {
    // フォントサイズをMUI v4のデフォルトに合わせる
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color,
          backgroundColor: '#eeeeee',
          borderTop: '4px solid',
          height: 68,
          boxShadow: 'none'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard' as const
      }
    }
  }
});
export default theme;

export const projectTheme = createTheme(theme, {
  palette: {
    nehan: theme.palette.augmentColor({
      color: {
        main: '#e2762a'
      },
      name: 'nehan'
    })
  }
});

const dashboard = {
  palette: {
    nehan: {
      headerBorder: '#C66C6C',
      headerBg: '#eeeeee',
      subHeaderBorder: '#C66C6C',
      subHeaderBg: '#fcf7f7',
      main: '#0088a7',
      dark: '#005f74',
      contrastText: '#ffffff'
    }
  }
};
export const dashboardTheme = createTheme(theme, dashboard);

const visualize = {
  palette: {
    nehan: {
      main: '#51a8dd',
      dark: '#38759a',
      contrastText: '#ffffff'
    }
  }
};
export const visualizeTheme = createTheme(theme, visualize);

const datasource = {
  palette: {
    nehan: {
      main: '#0088a7',
      dark: '#005f74',
      contrastText: '#ffffff'
    }
  }
};
export const datasourceTheme = createTheme(theme, datasource);
