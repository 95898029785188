import * as React from 'react';
import { ListItem, ProjectItem } from '../../../models/dependency';
import { TableCell } from '@mui/material';
import { AccessLevelString } from 'libs/accessLevel';
import { Folder } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox,
  LinkCell,
  OpenMenuType,
  OpenShareDialogType,
  ProjectStatusCell,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const ProjectRow: React.FC<{
  project: ProjectItem;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  anchorEl?: HTMLElement;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({
  project,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  onCheckChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  clearForm
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        clearForm(project.uuid, project.is_folder);
        onClickItem(project);
      }, [basePath, clearForm, onClickItem, project])
    : React.useCallback(() => {
        clearForm(
          project.uuid,
          project.is_folder,
          `${basePath}/f/${project.uuid}`
        );
      }, [basePath, clearForm, project]);

  if (project.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, project)}
            />
          </TableCell>
        )}
        <LinkCell
          href={`${basePath}/f/${project.uuid}`}
          name={project.name}
          icon={<Folder />}
          onClick={onClick}
        />
        <TableCell>{AccessLevelString(project.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={project.created_at} />
        <TableCell>-</TableCell>
        <ShareDialogCell
          name={project.name}
          uuid={project.uuid}
          accessLevel={project.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, project)}
            />
          </TableCell>
        )}
        {folderOnly ? (
          <TableCell>{project.name}</TableCell>
        ) : (
          <LinkCell href={`/projects/${project.uuid}`} name={project.name} />
        )}
        <TableCell>{AccessLevelString(project.access_level)}</TableCell>
        <ProjectStatusCell item={project} />
        <TimestampCell time={project.created_at} />
        <TimestampCell time={project.updated_at} />
        <ShareDialogCell
          name={project.name}
          uuid={project.uuid}
          accessLevel={project.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  }
};
