import React from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps
} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export const DatePicker: React.FC<
  {
    date: string; // YYYY-MM-DD
    setDate: (date: string) => void;
  } & Partial<DatePickerProps<dayjs.Dayjs>>
> = ({ date, setDate, ...props }) => {
  return (
    <MuiDatePicker
      format="YYYY-MM-DD"
      value={dayjs(date)}
      onChange={(newDate) => {
        if (!newDate) return;
        setDate(newDate.format('YYYY-MM-DD'));
      }}
      slotProps={{
        textField: {
          variant: 'outlined',
          margin: 'normal',
          size: 'small'
        },
        inputAdornment: {
          position: 'start'
        }
      }}
      {...props}
    />
  );
};
