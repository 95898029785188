import * as React from 'react';
import TypeSelectPage from './typeSelectPage';
import NewConnectionList from 'components/connection/newConnectionList';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = () =>
  createStyles({
    content: {
      paddingTop: 10,
      maxWidth: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    }
  });

const NewConnectionListPage = () => {
  const [searchText, setSearchText] = React.useState<string>('');
  const onChange = (ev: any) => {
    setSearchText(ev.target.value);
  };
  return (
    <TypeSelectPage
      title="作成する接続情報を選択"
      menu="connections"
      onChange={onChange}
    >
      <NewConnectionList searchText={searchText} />
    </TypeSelectPage>
  );
};

export default withStyles(styles)(NewConnectionListPage);
