import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useStyles } from '../../styles';
import { RangeDateParameter as RangeDateParameterModel } from 'models/report';
import { RelativeSelect } from '../../relativeSelect';
import { AbusoluteCalendar } from '../../absoluteCalendar/index';
import { useDateInstance } from '../../parameterClass/hooks';
import { StyledDatePicker } from '../styledDatePicker';
import { Grid } from '@mui/material';
import { ParameterDialog, useDialog } from '../../parameterDialog';
import { DateRangeClass } from '../../parameterClass/dateRange';

export const DateRangeParameter: React.FC<{
  title?: string;
  hideHeader?: boolean;
  param: RangeDateParameterModel;
  onSubmit: (param: RangeDateParameterModel) => void;
}> = ({ title, hideHeader, param, onSubmit }) => {
  const classes = useStyles();
  const dialogProps = useDialog();
  const [state, setState] = React.useState(param);

  const dateClass = useDateInstance(state);
  const items = useMemo(() => {
    return dateClass.getRelativeItems();
  }, [dateClass]);

  const isValid = useMemo(() => {
    return dateClass.isValid();
  }, [dateClass]);

  const isValidStartDate = useMemo(() => {
    return (dateClass as DateRangeClass).isValidStartDate();
  }, [dateClass]);

  const isValidEndDate = useMemo(() => {
    return (dateClass as DateRangeClass).isValidEndDate();
  }, [dateClass]);

  return (
    <ParameterDialog
      {...dialogProps}
      onClickOK={() => onSubmit(state)}
      onClose={() => {
        setState(param);
        dialogProps.onClose();
      }}
      isValid={isValid}
      title={title || param.name}
      hideHeader={hideHeader}
      content={
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={5}>
            <RelativeSelect param={state} setParam={setState} items={items} />
          </Grid>
          <Grid item xs={7}>
            <div className={classes.pickerArea}>
              <StyledDatePicker
                classes={classes}
                date={state.values.inputValue.startDate}
                setDate={(date) => {
                  setState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      select: 'manual',
                      inputValue: {
                        ...prev.values.inputValue,
                        startDate: dayjs(date).format('YYYY-MM-DD')
                      }
                    }
                  }));
                }}
                isValid={isValidStartDate}
              />
              <span className={classes.dateRangeDivider}>ー</span>
              <StyledDatePicker
                classes={classes}
                date={state.values.inputValue.endDate}
                setDate={(date) => {
                  setState((prev) => ({
                    ...prev,
                    values: {
                      ...prev.values,
                      select: 'manual',
                      inputValue: {
                        ...prev.values.inputValue,
                        endDate: dayjs(date).format('YYYY-MM-DD')
                      }
                    }
                  }));
                }}
                isValid={isValidEndDate}
              />
            </div>
            <AbusoluteCalendar
              date={[
                dayjs(state.values.inputValue.startDate),
                dayjs(state.values.inputValue.endDate)
              ]}
              setDate={(date) => {
                setState((prev) => ({
                  ...prev,
                  values: {
                    ...prev.values,
                    select: 'manual',
                    inputValue: {
                      startDate: date[0].format('YYYY-MM-DD'),
                      endDate: date[1].format('YYYY-MM-DD')
                    }
                  }
                }));
              }}
              select={state.values.select}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
