import { createSvgIcon } from '@mui/material';
import * as React from 'react';

const SvgIcHelpOn = createSvgIcon(
  <svg width="0.66em" height="0.66em" viewBox="0 0 40 40">
    <path
      d="M26.746 17.787c-.17.44-.38.816-.63 1.128-.251.312-.529.578-.833.798-.305.22-.593.44-.864.66a4.14 4.14 0 00-.722.755c-.21.284-.342.638-.396 1.063V23h-2.744v-.957c.04-.61.152-1.12.335-1.532.183-.412.397-.763.64-1.054a5.22 5.22 0 01.773-.755c.27-.213.521-.425.752-.638.23-.213.417-.447.559-.702.142-.256.206-.575.193-.958 0-.652-.153-1.134-.457-1.447-.305-.311-.729-.468-1.27-.468-.367 0-.682.075-.946.224a1.916 1.916 0 00-.65.596 2.64 2.64 0 00-.376.872 4.523 4.523 0 00-.122 1.074H17a6.322 6.322 0 01.376-2.106c.237-.638.57-1.192.996-1.66a4.489 4.489 0 011.545-1.095c.602-.263 1.277-.394 2.022-.394.962 0 1.765.138 2.409.415.643.276 1.161.62 1.554 1.032.393.411.674.855.844 1.33.17.475.254.918.254 1.33 0 .68-.085 1.24-.254 1.68zM21 27h3v-3h-3v3zM11 17a2 2 0 110-4 2 2 0 010 4zm20.942 7.21c-.053-.002-.053-.048 0-.05C34.196 24.08 36 22.285 36 20.078c0-2.135-1.688-3.884-3.84-4.068C34.312 15.823 36 14.037 36 11.9c0-2.206-1.804-4-4.058-4.081-.053-.002-2.549-.043-2.549-.043V4.188c0-1.191-.144-2.176-.319-2.188h-.162c-3.002 0-5.435 2.361-5.435 5.273v.504h-6.78C9.684 7.777 4 13.292 4 20.095 4 26.9 9.685 32.414 16.696 32.414c0 0 6.784.092 6.78.143v.17c0 2.912 2.434 5.273 5.436 5.273h.162c.175-.012.319-.996.319-2.188v-3.398h2.549c2.254-.08 4.058-1.915 4.058-4.122s-1.804-4.002-4.058-4.082z"
      fill="#ED7926"
      fillRule="evenodd"
    />
  </svg>,
  'Help'
);

export default SvgIcHelpOn;
