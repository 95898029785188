import * as React from 'react';
import { SwitchOperators, SwitchDatetimeElement } from 'models/form/value';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Typography } from '@mui/material';

export const UseColumnCondition: React.FC<{
  operator: SwitchOperators | null;
  datetimeType?: SwitchDatetimeElement | null;
  check: boolean | null;
  onChange: (value: boolean) => void;
  label: string;
  isReturnValue: boolean;
}> = ({
  operator, //datetimeType,
  check,
  onChange,
  label,
  isReturnValue
}) => {
  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      onChange(checked),
    [onChange]
  );
  if (isReturnValue) {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={check === null ? false : check}
              onChange={handleChange}
              disableRipple={true}
            />
          }
          label={<Typography style={{ fontSize: 12 }}>{label}</Typography>}
        />
      </div>
    );
    //} else if (datetimeType === SwitchDatetimeElement.weekday) {
    //  return <div style={{ width: '191px', marginTop: '40px' }}></div>;
  } else {
    switch (operator) {
      case SwitchOperators.eq:
      case SwitchOperators.notEq:
      case SwitchOperators.lt:
      case SwitchOperators.le:
      case SwitchOperators.gt:
      case SwitchOperators.ge:
      case SwitchOperators.between:
      case null:
        return (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={check === null ? false : check}
                  onChange={handleChange}
                  disableRipple={true}
                />
              }
              label={<Typography style={{ fontSize: 12 }}>{label}</Typography>}
            />
          </div>
        );
      default:
        return null;
      //return <div style={{ width: '191px', marginTop: '40px' }}></div>;
    }
  }
};
