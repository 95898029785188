import * as React from 'react';

import { Grid, ThemeProvider } from '@mui/material';
import MainLayout from 'components/layouts/main';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import {
  deleteDatasource,
  duplicateDatasource,
  getDatasources,
  updateDatasourceProps
} from 'libs/api';
import { useHistory, useParams } from 'react-router-dom';
import { projectTheme } from '../../theme';
import { QueryParams } from 'ui/listViewBase';

const DatasourceListPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const loadFunc = React.useMemo(
    () => async (fid: string, queryParams?: QueryParams) => {
      const { data } = await getDatasources(fid, queryParams);
      return {
        data: {
          items: data?.items?.user_data,
          paths: data?.paths,
          emails: data?.emails,
          types: data?.types
        }
      };
    },
    []
  );

  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'datasource'
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    resourceId: string,
    shareEditable: boolean,
    name: string,
    isFolder: boolean
  ) => {
    setState({
      id: resourceId,
      editable: shareEditable,
      open: true,
      title: name,
      type: isFolder ? 'folder' : 'datasource'
    });
  };

  return (
    <MainLayout selectedMenu="datasources">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="datasources"
            folderId={id}
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
            load={loadFunc}
            delete={deleteDatasource}
            duplicate={duplicateDatasource}
            move={async () =>
              history.push({
                pathname: '/datasources/new',
                search: new URLSearchParams({
                  folderId: id || '',
                  first_display: '1'
                }).toString()
              })
            }
            update={updateDatasourceProps}
            showDetailPane={true}
            supportFolder={true}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default DatasourceListPage;
