import * as React from 'react';
import { Autocomplete } from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/browser';

import MUITextField, {
  StandardTextFieldProps as MUITextFieldProps
} from '@mui/material/TextField';

import { Variable } from 'models/project';
import { TextFieldSchema } from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import makeStyles from '@mui/styles/makeStyles';

const styles = () => ({
  flex: {
    display: 'flex',
    width: '100%'
  },
  autoComplete: {
    flexGrow: 1
  }
});

const hookstyles = makeStyles(styles());
export interface TextFieldProps extends MUITextFieldProps {
  variables?: Variable[];
  options?: string[];
  schema: TextFieldSchema;
  placeholder: string;
  errors: FieldValidationError;
  onChangeField: (val: string) => void;
  forcePopupIcon?: boolean;
  detailedHelpTooltip?: JSX.Element | null;
  variant?: any;
  value: string;
}

interface TextFieldState {
  value: string;
}

function getSuggestions(
  suggestions: AutoCompleteOptiopn[] | undefined,
  value?: string | null
) {
  if (suggestions == undefined) {
    return [];
  }

  if (!value) {
    return suggestions;
  }

  // カンマ区切りの場合、最後の文字列で補完する
  const inputValues = value.split(',');
  const inputValue = inputValues[inputValues.length - 1].trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return suggestions;
  }

  return suggestions.filter((suggestion) => {
    if (suggestion == undefined || suggestion.value == undefined) {
      Sentry.captureException('Suggestion value is invalid', {
        extra: { suggestions }
      });
      return false;
    }
    return suggestion.value.includes(inputValue);
  });
}

const TextField: React.FunctionComponent<TextFieldProps> = ({
  onChangeField,
  schema,
  errors,
  placeholder,
  detailedHelpTooltip,
  ...props
}) => {
  if (schema.detailHelpName && detailedHelpTooltip != undefined) {
    props.fullWidth = false;
  } else if (schema.fullWidth) {
    props.fullWidth = schema.fullWidth;
  } else {
    props.fullWidth = true;
  }

  props.label = placeholder;
  const readOnly = schema.readonly != undefined ? schema.readonly : false;
  errors = Array.isArray(errors) ? errors : [];

  const styleClasses = hookstyles();
  const variant = props.variant != undefined ? props.variant : 'outlined';
  return (
    <div className={styleClasses.flex}>
      <MUITextField
        className={styleClasses.autoComplete}
        onChange={(ev) => onChangeField(ev.target.value)}
        //error={errors.length > 0}
        //helperText={errors.length > 0 ? errors[0] : ''}
        variant={variant}
        size="small"
        InputProps={{
          readOnly,
          style: { height: '48.2px', backgroundColor: '#ffffff' }
        }}
        InputLabelProps={{
          style: { paddingTop: '7px' }
        }}
        {...props}
      />
      {detailedHelpTooltip}
    </div>
  );
};

interface AutoCompleteOptiopn {
  label: string;
  value: string;
}

const AutoCompleteTextField: React.FunctionComponent<TextFieldProps> = ({
  onChangeField,
  schema,
  errors,
  placeholder,
  detailedHelpTooltip,
  ...props
}) => {
  const [stateValue, useValue] = React.useState<TextFieldState>({ value: props.value});
  const handleChangeAutocompleteValue = (_, option: AutoCompleteOptiopn) => {
    // autocompleteでのinputの場合
    if (!option) {
      return;
    }
    const selectedValue = option.value;

    // カンマで区切られてた場合、カンマの後の文字列がautocompleteの対象になる
    const lastCommaIndex = stateValue.value.lastIndexOf(',');
    const newInput =
      lastCommaIndex === -1
        ? selectedValue
        : stateValue.value.substring(0, lastCommaIndex + 1) + selectedValue;

    useValue({ value: newInput });
    onChangeField(newInput);
  };

  const handleChangeInputValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // autocomplete以外のinputの場合
    useValue({ value: ev.target.value });
    onChangeField(ev.target.value);
  };
  const classes = hookstyles();
  const value = stateValue.value;
  const { forcePopupIcon, variables, options } = props;

  let suggetions: AutoCompleteOptiopn[] = [];
  if (options != undefined) {
    suggetions = suggetions.concat(
      options.map((o) => ({ label: o, value: o }))
    );
  }
  if (variables != undefined) {
    suggetions = suggetions.concat(
      variables.map((v) => ({
        label: `変数 ${v.name}`,
        value: `$${v.name}`
      }))
    );
  }
  return (
    (<div className={classes.flex}>
      <Autocomplete
        className={classes.autoComplete}
        style={{ flex: '1 1 auto' }}
        forcePopupIcon={forcePopupIcon}
        disableClearable={true}
        freeSolo={true}
        value={value}
        onChange={handleChangeAutocompleteValue}
        options={suggetions}
        filterOptions={(options: AutoCompleteOptiopn[]) =>
          getSuggestions(options, value)
        }
        getOptionLabel={(option: AutoCompleteOptiopn | string) =>
          typeof option === 'string' ? option : option.label
        }
        isOptionEqualToValue={(option: AutoCompleteOptiopn, val: string) =>
          option.value === val
        }
        renderInput={(params) => (
          <MUITextField
            {...params}
            onChange={handleChangeInputValue}
            label={placeholder}
            fullWidth={true}
            variant={'outlined'}
            size="small"
            InputProps={{
              ...params.InputProps,
              style: { height: '48.96px', backgroundColor: '#ffffff' }
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                padding: '6px 0',
                height: '26.5px'
              }
            }}
            InputLabelProps={{
              style: { paddingTop: '9px' }
            }}
            error={errors.length > 0}
            helperText={errors.length > 0 ? errors[0] : ''}
          />
        )}
        openOnFocus={true}
      />
      {detailedHelpTooltip}
    </div>)
  );
};

export default function (props: TextFieldProps) {
  if (!isEmpty(props.variables) || !isEmpty(props.options)) {
    const { ref, ...rest } = props;
    return <AutoCompleteTextField {...rest} />;
  }

  return <TextField {...props} />;
}
