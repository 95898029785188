import * as React from 'react';
import { SvgIcon } from '@mui/material';

function SvgIcSqlBuilder(props) {
  return (
    <SvgIcon viewBox="0 0 28 16.99" {...props}>
      <g>
        <path
          fill="currentcolor"
          d="M26.66,13.25c-.69-.23-1.73.43-2.73,1.12v0c-.31-.45-.63-.9-1-1.34a2.81,2.81,0,0,0,1.16-.79c.32-.49.3-.84-.34-1.74s-1-1-1.48-.81A3.16,3.16,0,0,0,21.13,11,7.49,7.49,0,0,0,16,8.92a13.45,13.45,0,0,0-3.63.38A4.61,4.61,0,0,0,7.89,5.5h0A4.63,4.63,0,0,0,3.32,9.33a2.71,2.71,0,1,0,.91.15A3.7,3.7,0,0,1,7.86,6.42h0a3.7,3.7,0,0,1,3.64,3.19A5.84,5.84,0,0,0,8.17,14a12.92,12.92,0,0,1,2,1.93H7.27c-.11,0-.31,0-.34.24,0,1-.59,5.48,3.07,6,5.08.74,8.35-.06,10.47-1.21a2.57,2.57,0,0,0,.66,1.08c.45.34.77.32,1.62-.38s1-1.05.78-1.61a2.23,2.23,0,0,0-.71-.85,9.88,9.88,0,0,0,.81-.93c1,.75,2.17,1.53,2.92,1.29,1-.34,1.41-.91,1.45-3.15S27.69,13.6,26.66,13.25ZM4.71,10.83a1.86,1.86,0,1,1-2.16-.47,2.47,2.47,0,0,0,1,.46A2.53,2.53,0,0,0,4.71,10.83Zm7.68,3.52a.86.86,0,1,1,.86-.86A.85.85,0,0,1,12.39,14.35Z"
          transform="translate(0 -5.5)"
        />
        <path
          fill="currentcolor"
          d="M.06,16.22c-.19-.25.12-.47.12-.47l.75-.56s.28-.25.49,0-.12.47-.12.47l-.72.54S.29,16.45.06,16.22Z"
          transform="translate(0 -5.5)"
        />
        <path
          fill="currentcolor"
          d="M4.8,17.24c.27-.15.08-.48.08-.48L4.43,16s-.16-.34-.45-.2-.09.48-.09.48l.44.79S4.49,17.36,4.8,17.24Z"
          transform="translate(0 -5.5)"
        />
        <path
          fill="currentcolor"
          d="M2.33,17.19c.31.06.38-.3.38-.3L2.9,16s.1-.36-.22-.44-.37.3-.37.3l-.19.88S2,17.08,2.33,17.19Z"
          transform="translate(0 -5.5)"
        />
      </g>
    </SvgIcon>
  );
}

export default SvgIcSqlBuilder;
