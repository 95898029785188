import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  ListItemButton
} from '@mui/material';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { ListSelect } from './listSelect';
import { TimeParameter, ParameterType, RelativeDateUnit } from 'models/report';
import { NumberInput } from './number';
import {
  DateType,
  DateUnit,
  TimeValues,
  UnionDateUnit,
  dateUnits,
  datetimeRangeUnits,
  datetimeSingleUnits,
  timeRangeUnits,
  timeSingleUnits
} from 'models/report/parameter';
import { Dtypes } from 'Utils/dataTypes';
import { DateParameterClassFactory } from './parameterClass/factory';
import { Switch } from './relativeSelect/switch';

const getDateUniteItems = (dtype: DateType, type: ParameterType) => {
  switch (dtype) {
    case Dtypes.DATE:
      return dateUnits;
    case Dtypes.TIMESTAMP:
      if (type === ParameterType.Single) {
        return datetimeSingleUnits;
      }
      return datetimeRangeUnits;
    case Dtypes.TIME:
      if (type === ParameterType.Single) {
        return timeSingleUnits;
      }
      return timeRangeUnits;
  }
};

const SelectDateUnit: React.FC<{
  unit: string;
  onChange: (unit: RelativeDateUnit) => void;
  dtype: DateType;
  type: ParameterType;
}> = ({ unit, onChange, dtype, type }) => {
  const classes = useStyles();
  return (
    <ListSelect
      value={unit}
      label=""
      onChange={onChange}
      options={getDateUniteItems(dtype, type)}
      formProps={{
        className: classes.relativeListItemInputPastUnitForm
      }}
      selectProps={{
        inputProps: { className: classes.relativeListItemInputPastUnitSelect }
      }}
    />
  );
};

const InputPast: React.FC<{
  value: string;
  onChangeValue: (value: string) => void;
  unit: UnionDateUnit;
  onChangeUnit: (unit: DateUnit) => void;
  dtype: DateType;
  parameterType: ParameterType;
}> = ({ value, onChangeValue, unit, onChangeUnit, dtype, parameterType }) => {
  const classes = useStyles();

  return (
    <div className={classes.condition}>
      <Typography className={classes.relativeListItemPrefix}>過去</Typography>
      <NumberInput
        label={''}
        value={value}
        isInteger={true}
        onChange={onChangeValue}
        className={classes.relativeListItemInputPast}
        width={60}
        inputProps={{
          className: classes.relativeListItemInputPastInput
        }}
      />
      <SelectDateUnit
        unit={unit}
        onChange={onChangeUnit}
        dtype={dtype}
        type={parameterType}
      />
      <Typography className={classes.relativeListItemSuffix}>
        {parameterType === ParameterType.Range ? '間' : '前'}
      </Typography>
    </div>
  );
};

export const RelativeSelect: React.FC<{
  param: TimeParameter;
  setParam: React.Dispatch<React.SetStateAction<TimeParameter>>;
  items: { label: string; value: TimeValues['select'] }[];
}> = ({ param, setParam, items }) => {
  const classes = useStyles();
  const values = param.values;
  const setValues = useCallback(
    (values: TimeValues) => {
      // 相対日付が変わったときはinputValueも更新する
      let updatedParam = {
        ...param,
        values
      } as TimeParameter;
      const factory = new DateParameterClassFactory();
      const instance = factory.createInstance(updatedParam);
      updatedParam = {
        ...updatedParam,
        values: {
          ...updatedParam.values,
          inputValue: instance.calculateInputValue()
        }
      } as TimeParameter;
      setParam(updatedParam);
    },
    [setParam]
  );

  return (
    <>
      <List component="nav" className={classes.relativeList}>
        <ListItemButton
          className={classes.relativeListItem}
          style={{
            backgroundColor:
              values.select === 'manual' ? 'rgba(0, 0, 0, 0.06)' : undefined
          }}
        >
          <ListItemText
            primary="手動選択"
            onClick={() => {
              setValues({
                ...values,
                select: 'manual'
              });
            }}
          />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        {items.map((item) => (
          <ListItemButton
            key={item.value}
            className={classes.relativeListItem}
            style={{
              backgroundColor:
                values.select === item.value ? 'rgba(0, 0, 0, 0.04)' : undefined
            }}
            onClick={() => {
              setValues({
                ...values,
                select: item.value
              } as TimeValues);
            }}
          >
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
        <ListItemButton
          className={classes.relativeListItem}
          style={{
            backgroundColor:
              values.select === 'input_past' ? 'rgba(0, 0, 0, 0.04)' : undefined
          }}
          onClick={() =>
            setValues({
              ...values,
              select: 'input_past'
            })
          }
        >
          <ListItemText
            className={classes.relativeListItemText}
            primary={
              <InputPast
                value={values.inputPastValue.value}
                onChangeValue={(value) =>
                  setValues({
                    ...values,
                    inputPastValue: {
                      ...values.inputPastValue,
                      value
                    }
                  })
                }
                unit={values.inputPastValue.unit}
                onChangeUnit={(unit) =>
                  setValues({
                    ...values,
                    inputPastValue: {
                      ...values.inputPastValue,
                      unit
                    }
                  })
                }
                dtype={param.dtype}
                parameterType={param.type}
              />
            }
          />
        </ListItemButton>
      </List>
      {'includeToday' in values && values.select === 'input_past' && (
        <>
          <Divider />
          <List>
            <ListItem className={classes.relativeListItem}>
              <ListItemText
                primary={
                  <>
                    <Switch
                      checked={values.includeToday}
                      onChange={(_, checked) =>
                        setValues({
                          ...values,
                          includeToday: checked
                        })
                      }
                    />
                    今日を含む
                  </>
                }
              />
            </ListItem>
          </List>
        </>
      )}
    </>
  );
};
