import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Handle, Position } from '@xyflow/react';
import { getDatasourceExpression } from 'libs/datasources';
import {
  Check,
  KeyboardArrowDown,
  NotInterested,
  RadioButtonUnchecked,
  SystemUpdateAlt
} from '@mui/icons-material';
import clsx from 'clsx';
import {
  Avatar,
  Checkbox as MuiCheckbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Zoom,
  styled,
  AvatarProps,
  SvgIconProps
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import {
  deleteBuilder,
  deleteDatasource,
  deleteProject,
  deleteReport,
  deleteWorkflow
} from 'libs/api';
import { createLink } from 'reducers/datasource';
import { NodeItem } from 'components/relationships/relationships';
import { matchPath, useHistory } from 'react-router-dom';
import SvgIcSqlBuilder from 'components/icons/IcSqlBuilder';
import {
  DatasourceIcon,
  ProjectIcon,
  ReportIcon,
  WorkflowIcon
} from 'components/icons/icons';
import { AccessLevel } from 'libs/accessLevel';

const Checkbox = styled(MuiCheckbox)({
  width: 32,
  height: 32,
  color: '#344955',
  backgroundColor: '#fff',
  padding: 0,
  position: 'absolute',
  '&:hover': {
    backgroundColor: '#fff'
  },
  '&.Mui-checked': {
    opacity: 1,
    color: '#fff',
    backgroundColor: '#344955'
  }
});

const useStyle = makeStyles({
  root: {
    width: 180,
    height: 52,
    backgroundColor: 'transparent',
    position: 'relative'
  },
  icon: {
    width: 32,
    height: 32
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: 8,
    borderRadius: 3,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#dadada',
    borderStyle: 'solid',
    height: '100%'
  },
  start: {
    '&:after': {
      position: 'absolute',
      content: "''",
      top: -6,
      left: -6,
      height: 'calc(100% + 12px)',
      width: 'calc(100% + 12px)',
      backgroundColor: 'currentColor',
      zIndex: -10,
      opacity: 0.25,
      borderRadius: 3
    }
  },
  round: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: '1px solid #7fce5d'
  },
  hex: {
    width: 88,
    height: 52
  },
  hexContent: {
    height: '100%',
    width: '100%',
    backgroundColor: '#e2762a',
    clipPath: 'polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: "''",
      top: 1 /* border width */,
      left: 1 /* border width */,
      height: 'calc(100% - 2px)' /* 100% - (2 * border width) */,
      width: 'calc(100% - 2px)' /* 100% - (2 * border width) */,
      backgroundColor: '#fafaf6',
      clipPath: 'polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)'
    }
  },
  titleText: {
    paddingLeft: 8,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  row: {
    height: '1em',
    lineHeight: 1.1
  },
  deleteMenu: {
    color: red[500]
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis'
  },
  forbidden: {
    color: '#f44336'
  },
  comment: {
    padding: 8,
    backgroundColor: '#f1f1f1',
    height: 50,
    borderRadius: 3,
    wordBreak: 'break-all',
    overflow: 'auto'
  },
  selected: {
    boxShadow: '0 0 0 0.5px currentColor'
  },
  project: {
    borderColor: '#e2762a',
    color: '#e2762a',
    backgroundColor: '#fafaf6'
  },
  nehan_storage: {
    borderColor: '#e2762a',
    color: '#e2762a',
    backgroundColor: 'transparent'
  },
  builder: {
    borderColor: '#ada142',
    color: '#ada142',
    backgroundColor: '#fbf9ed'
  },
  report: {
    borderColor: '#C66C6C',
    color: '#C66C6C',
    backgroundColor: '#fcf7f7'
  },
  expander: {
    width: 170,
    height: 44,
    borderColor: grey[700],
    color: grey[700],
    backgroundColor: grey[200]
  },
  overlap: {
    position: 'absolute',
    color: grey[700],
    backgroundColor: grey[200],
    borderColor: grey[700],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    width: 170,
    height: 44
  },
  overlap1: {
    top: 5,
    left: 5,
    zIndex: -5
  },
  overlap2: {
    top: 10,
    left: 10,
    zIndex: -10
  },
  accessOverlay: {
    position: 'absolute',
    top: -0.5,
    left: -0.5,
    right: 0,
    bottom: 0,
    opacity: 0.5,
    color: 'black',
    backgroundColor: 'grey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const StyledAvatar = styled(Avatar)<AvatarProps>(() => ({
  width: 32,
  height: 32,
  marginLeft: -1,
  color: 'inherit',
  backgroundColor: 'inherit'
}));

const StyledUnchecked = styled(RadioButtonUnchecked)<SvgIconProps>(() => ({
  width: 32,
  height: 32,
  background: `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%23f0f0f0" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>') no-repeat center center`,
  backgroundSize: 20
}));

const NodeIcon: React.FC<{
  style?: React.CSSProperties;
  Icon: React.ReactNode;
  disableCheck?: boolean;
  checked: boolean;
  hovered: boolean;
  onCheckChanged?: (checked: boolean) => void;
}> = ({ Icon, checked, hovered, onCheckChanged, style, disableCheck }) => {
  return (
    <StyledAvatar style={style}>
      {!checked && Icon}
      {!disableCheck && (
        <Zoom in={checked || hovered}>
          <Checkbox
            size={checked ? 'small' : 'medium'}
            icon={<StyledUnchecked viewBox="2 2 20 20" />}
            checkedIcon={<Check />}
            checked={checked}
            onMouseDown={(ev) => ev.stopPropagation()}
            onChange={(_, checked) => onCheckChanged && onCheckChanged(checked)}
          />
        </Zoom>
      )}
      {checked && (
        <Check
          sx={{
            color: '#fff',
            backgroundColor: '#344955'
          }}
          fontSize="small"
        />
      )}
    </StyledAvatar>
  );
};

const MenuButton: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="small"
        className="_node_menu_icon"
        onClick={handleClick}
      >
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
};

export const DatasourceNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const [hover, setHover] = React.useState(false);
  const ds = getDatasourceExpression(data.datasource_type ?? '');
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [id, data]
  );
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);
  const checkable =
    data.checkableAccessLevel != undefined
      ? data.access_level >= data.checkableAccessLevel
      : true;

  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          selected && classes.selected,
          data.start_node && classes.start
        )}
      >
        {!checkable && (
          <Tooltip title="編集者以上の権限がありません">
            <div className={classes.accessOverlay}>
              <NotInterested fontSize="large" />
            </div>
          </Tooltip>
        )}
        <NodeIcon
          Icon={<DatasourceIcon type={data.datasource_type ?? ''} />}
          checked={data.checked ?? false}
          disableCheck={
            data.disableCheck || data.sample_datasource || !checkable
          }
          hovered={hover}
          onCheckChanged={onCheck}
        />
        <div className={classes.titleText}>
          <span className={classes.row}>
            <Tooltip title={data.label}>
              <strong
                className={clsx(
                  classes.ellipsis,
                  noPermission && classes.forbidden
                )}
              >
                {!noPermission ? data.label : '権限なし'}
              </strong>
            </Tooltip>
          </span>
          <span>{ds.name}</span>
        </div>
        {hover && (
          <MenuButton>
            {!data.sample_datasource && !noPermission && (
              <MenuItem
                onClick={() => {
                  window.open(
                    `${createLink(data.datasource_type, data.id)}`,
                    '_blank'
                  );
                }}
              >
                このデータソースを開く
              </MenuItem>
            )}
            {!data.disableTargetChange && (
              <MenuItem onClick={onTargetChange}>
                ここを起点に系譜を確認する
              </MenuItem>
            )}
            {!data.disableDelete &&
              !data.sample_datasource &&
              !noPermission && (
                <MenuItem
                  className={classes.deleteMenu}
                  onClick={async () => {
                    if (window.confirm('削除してもよろしいですか？')) {
                      await deleteDatasource(data.id);
                      data.reloadFunc();
                    }
                  }}
                >
                  削除
                </MenuItem>
              )}
          </MenuButton>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
    </div>
  );
});

export const ProjectNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const [hover, setHover] = React.useState(false);
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [data.onCheckChanged, data.id]
  );
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);
  const checkable =
    data.checkableAccessLevel != undefined
      ? data.access_level >= data.checkableAccessLevel
      : true;

  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.project,
          selected && classes.selected,
          data.start_node && classes.start
        )}
      >
        {!checkable && (
          <Tooltip title="編集者以上の権限がありません">
            <div className={classes.accessOverlay}>
              <NotInterested fontSize="large" />
            </div>
          </Tooltip>
        )}
        <div className={classes.icon}>
          <NodeIcon
            Icon={<ProjectIcon />}
            disableCheck={!checkable}
            checked={data.checked ?? false}
            hovered={hover}
            onCheckChanged={onCheck}
          />
        </div>
        <div className={clsx(classes.titleText, classes.project)}>
          <span className={classes.row}>
            <Tooltip title={data.label}>
              <strong
                className={clsx(
                  classes.ellipsis,
                  noPermission && classes.forbidden
                )}
              >
                {!noPermission ? data.label : '権限なし'}
              </strong>
            </Tooltip>
          </span>
        </div>
        {hover && (
          <MenuButton>
            {!noPermission && (
              <MenuItem
                onClick={() => {
                  window.open(`/projects/${data.id}`, '_blank');
                }}
              >
                このプロジェクトを開く
              </MenuItem>
            )}
            {!data.disableTargetChange && (
              <MenuItem onClick={onTargetChange}>
                ここを起点に系譜を確認する
              </MenuItem>
            )}
            {!data.disableDelete && !noPermission && (
              <MenuItem
                className={classes.deleteMenu}
                onClick={async () => {
                  if (window.confirm('削除してもよろしいですか？')) {
                    await deleteProject(data.id);
                    data.reloadFunc();
                  }
                }}
              >
                削除
              </MenuItem>
            )}
          </MenuButton>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
    </div>
  );
});

export const BuilderNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const [hover, setHover] = React.useState(false);
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [id, data.onCheckChanged]
  );
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);

  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.builder,
          selected && classes.selected,
          data.start_node && classes.start
        )}
      >
        <div className={classes.icon}>
          <NodeIcon
            Icon={<SvgIcSqlBuilder />}
            checked={data.checked ?? false}
            hovered={hover}
            disableCheck={data.disableCheck}
            onCheckChanged={onCheck}
          />
        </div>
        <div className={clsx(classes.titleText, classes.builder)}>
          <span className={classes.row}>
            <Tooltip title={data.label}>
              <strong
                className={clsx(
                  classes.ellipsis,
                  noPermission && classes.forbidden
                )}
              >
                {!noPermission ? data.label : '権限なし'}
              </strong>
            </Tooltip>
          </span>
        </div>
        {hover && (
          <MenuButton>
            {!noPermission && (
              <MenuItem
                onClick={() => {
                  window.open(`/builders/${data.id}`, '_blank');
                }}
              >
                このSQLビルダープロジェクトを開く
              </MenuItem>
            )}
            {!data.disableTargetChange && (
              <MenuItem onClick={onTargetChange}>
                ここを起点に系譜を確認する
              </MenuItem>
            )}
            {!data.disableDelete && !noPermission && (
              <MenuItem
                className={classes.deleteMenu}
                onClick={async () => {
                  if (window.confirm('削除してもよろしいですか？')) {
                    await deleteBuilder(data.id);
                    data.reloadFunc();
                  }
                }}
              >
                削除
              </MenuItem>
            )}
          </MenuButton>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
    </div>
  );
});

export const WorkflowNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const history = useHistory();
  const [hover, setHover] = React.useState(false);
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [data.onCheckChanged, data.id]
  );
  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.project,
          selected && classes.selected
        )}
      >
        <div className={classes.icon}>
          <NodeIcon
            Icon={<WorkflowIcon />}
            checked={data.checked ?? false}
            hovered={hover}
            onCheckChanged={onCheck}
          />
        </div>
        <div className={clsx(classes.titleText, classes.project)}>
          <Tooltip title={data.label}>
            <strong
              className={clsx(
                classes.ellipsis,
                noPermission && classes.forbidden
              )}
            >
              {!noPermission ? data.label : '権限なし'}
            </strong>
          </Tooltip>
        </div>
        {hover && !noPermission && (
          <MenuButton>
            <MenuItem
              onClick={() => {
                history.replace(
                  `/projects/${data.project_id}?select_workflow=${data.workflow_id}`
                );
              }}
            >
              このワークフローを開く
            </MenuItem>
            {!data.disableDelete && (
              <MenuItem
                className={classes.deleteMenu}
                onClick={async () => {
                  if (window.confirm('削除してもよろしいですか？')) {
                    await deleteWorkflow(data.project_id, data.workflow_id);
                    location.search += '&open_relationships=1';
                  }
                }}
              >
                削除
              </MenuItem>
            )}
          </MenuButton>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
    </div>
  );
});

export const ExportNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const history = useHistory();
  const [hover, setHover] = React.useState(false);
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [data.onCheckChanged, data.id]
  );
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);

  const ds = getDatasourceExpression(data.form_value?.type ?? '');
  if (ds.key === 'nehan_storage') {
    return <NehanStorageNode data={data} selected={selected} />;
  }

  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.project,
          selected && classes.selected,
          data.start_node && classes.start
        )}
      >
        <NodeIcon
          Icon={<DatasourceIcon type={data.form_value?.type ?? ''} />}
          checked={data.checked ?? false}
          disableCheck={true}
          hovered={hover}
          onCheckChanged={onCheck}
        />
        <div className={clsx(classes.titleText, classes.project)}>
          <Tooltip title={data.label}>
            <strong
              className={clsx(
                classes.ellipsis,
                noPermission && classes.forbidden
              )}
            >
              {!noPermission ? `${data.label}エクスポート` : '権限なし'}
            </strong>
          </Tooltip>
        </div>
        {hover && (
          <MenuButton>
            {!noPermission && (
              <MenuItem
                onClick={() => {
                  const newPath = `/projects/${data.project_id}/nodes/${data.id}?open_relationships=0`;
                  const match = matchPath(window.location.pathname, {
                    path: '/projects/:id',
                    exact: true
                  });
                  if (match) {
                    history.replace(newPath);
                  } else {
                    window.open(newPath, '_blank');
                  }
                }}
              >
                このノードを開く
              </MenuItem>
            )}
            {!data.disableTargetChange && (
              <MenuItem onClick={onTargetChange}>
                ここを起点に系譜を確認する
              </MenuItem>
            )}
          </MenuButton>
        )}
        {data.form_value?.type === 'nehan_storage' && (
          <Handle type="source" position={Position.Right} />
        )}
        <Handle type="target" position={Position.Left} />
      </div>
    </div>
  );
});

export const NehanStorageNode = React.memo(
  ({ data }: { data: NodeItem['data']; selected: boolean }) => {
    const classes = useStyle();
    return (
      <Tooltip title={data.form_value?.datasourceName}>
        <div className={clsx(classes.hex, classes.nehan_storage)}>
          <div className={classes.hexContent}>
            <SystemUpdateAlt style={{ fontSize: 32, position: 'relative' }} />
            <strong style={{ position: 'relative', fontSize: 10 }}>
              中間データ
            </strong>
          </div>
          <Handle type="source" position={Position.Right} />
          <Handle type="target" position={Position.Left} />
        </div>
      </Tooltip>
    );
  }
);

export const ReportNode = React.memo((item: NodeItem) => {
  const { id, data, selected } = item;
  const classes = useStyle();
  const [hover, setHover] = React.useState(false);
  const onCheck = React.useCallback(
    (checked: boolean) => {
      if (data.onCheckChanged) {
        data.onCheckChanged(id, checked, item);
      }
    },
    [data.onCheckChanged, data.id]
  );
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);

  const noPermission = data.access_level < AccessLevel.Viewer;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.report,
          selected && classes.selected,
          data.start_node && classes.start
        )}
      >
        <NodeIcon
          Icon={<ReportIcon />}
          checked={data.checked ?? false}
          hovered={hover}
          onCheckChanged={onCheck}
        />
        <div className={clsx(classes.titleText, classes.report)}>
          <Tooltip title={data.label}>
            <strong
              className={clsx(
                classes.ellipsis,
                noPermission && classes.forbidden
              )}
            >
              {!noPermission ? data.label : '権限なし'}
            </strong>
          </Tooltip>
        </div>
        {hover && (
          <MenuButton>
            {!noPermission && (
              <MenuItem
                onClick={() => {
                  window.open(`/reports/${data.id}`, '_blank');
                }}
              >
                このダッシュボードを開く
              </MenuItem>
            )}
            {!data.disableTargetChange && (
              <MenuItem onClick={onTargetChange}>
                ここを起点に系譜を確認する
              </MenuItem>
            )}
            {!data.disableDelete && !noPermission && (
              <MenuItem
                className={classes.deleteMenu}
                onClick={async () => {
                  if (window.confirm('削除してもよろしいですか？')) {
                    await deleteReport(data.id);
                    data.reloadFunc();
                  }
                }}
              >
                削除
              </MenuItem>
            )}
          </MenuButton>
        )}
        <Handle type="target" position={Position.Left} />
      </div>
    </div>
  );
});

export const ExpanderNode = React.memo((item: NodeItem) => {
  const { data, selected } = item;
  const classes = useStyle();
  const [hover, setHover] = React.useState(false);
  const onTargetChange = React.useCallback(() => {
    data.onTargetChange && data.onTargetChange(data);
  }, [data]);

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={clsx(
          classes.title,
          classes.expander,
          selected && classes.selected
        )}
      >
        <div className={clsx(classes.titleText)}>
          <strong className={classes.ellipsis}>
            その他{data.expand_count}個
          </strong>
        </div>
        {hover && (
          <MenuButton>
            <MenuItem onClick={onTargetChange}>起点を切り替えて開く</MenuItem>
          </MenuButton>
        )}
      </div>
      <div className={clsx(classes.overlap, classes.overlap1)} />
      <div className={clsx(classes.overlap, classes.overlap2)} />
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </div>
  );
});
