import * as React from 'react';
import { SvgIconProps } from '@mui/material';
import { AuthContext } from 'components/route';
import { SvgIcProjectHyper } from 'components/icons/IcProjectHyper';
import { SvgIcProject } from 'components/icons/IcProject';
import { AccountTree } from '@mui/icons-material';
import { getDatasourceExpression } from 'libs/datasources';
import SvgIcDashboard from 'components/icons/IcDashboard';
import { FaRegFaceSadTear } from 'react-icons/fa6';

export const ProjectIcon: React.VFC<SvgIconProps> = (props: SvgIconProps) => {
  const { user } = React.useContext(AuthContext);
  if (user.organization.feature_spark) {
    return <SvgIcProjectHyper {...props} />;
  }
  return <SvgIcProject {...props} />;
};

export const WorkflowIcon: React.VFC<SvgIconProps> = (props) => {
  return <AccountTree style={{ fontSize: 24 }} {...props} />;
};

export const DatasourceIcon: React.VFC<{
  type: string;
  className?: string;
  width?: number;
  height?: number;
}> = ({ type, className, width, height }) => {
  const ds = getDatasourceExpression(type);
  if (ds.key === 'default') {
    return (
      <FaRegFaceSadTear
        className={className}
        size={width ?? 24}
        color={'#f44336'}
      />
    );
  }
  return (
    <img
      width={width ?? 24}
      height={height ?? 24}
      alt={ds.name}
      src={ds.logoPath}
      className={className}
    />
  );
};

export const ReportIcon: React.VFC<SvgIconProps> = (props: SvgIconProps) => {
  return <SvgIcDashboard style={{ width: 32, height: 32 }} {...props} />;
};
