import * as React from 'react';
import MainLayout from 'components/layouts/main';
import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import ListView from 'components/ui/listView';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import {
  deleteBuilder,
  getBuilders,
  duplicateBuilder,
  updateBuilderName
} from 'libs/api';
import { useHistory, useParams } from 'react-router-dom';
import { projectTheme } from '../../theme';

const BuilderListPage = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'builder'
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    resourceId: string,
    shareEditable: boolean,
    name: string,
    isFolder: boolean
  ) => {
    setState({
      id: resourceId,
      editable: shareEditable,
      open: true,
      title: name,
      type: isFolder ? 'folder' : 'builder'
    });
  };

  const update = async (id: string, data: any) => {
    await updateBuilderName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="builders">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="builders"
            load={getBuilders}
            folderId={id}
            update={update}
            delete={deleteBuilder}
            duplicate={duplicateBuilder}
            move={async () =>
              history.push({
                pathname: '/builders/new',
                search: new URLSearchParams({
                  folderId: id || '',
                  first_display: '1'
                }).toString()
              })
            }
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
            showDetailPane={true}
            supportFolder={true}
          />{' '}
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default BuilderListPage;
