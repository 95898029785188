import React, { useEffect, useState } from 'react';
import dayjs, { ManipulateType } from 'dayjs';
import 'dayjs/locale/ja';

import {
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { MoreHoriz, Share } from '@mui/icons-material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { HelpButton } from 'components/diagram/ui/HelpDialog';
import { toDtypeLabel } from 'Utils/dataTypes';
import { Variable } from 'models/project';
import { VariablesImportDialog } from 'components/variablesImport';
import { useHistory } from 'react-router-dom';
import {
  createVariable,
  getVariables,
  importVariables as importVariablesApi,
  unimportVariables as unimportVariablesApi
} from 'libs/api';
import { VariableEditorDialog } from 'components/variableEditorDialog';
import { VariableItem } from 'models/dependency';
import { ResourceType } from 'models/variable';
import {
  AccessControlContext,
  ViewerDisabledTooltip
} from 'hooks/accessControl';
import { AccessLevel } from 'libs/accessLevel';

const dateFormatter = (
  value: string,
  dateFromat?: string,
  dateCustomFormat?: string
): string => {
  switch (dateFromat) {
    case 'ymd':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
    case 'ymd_slash':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY/MM/DD');
    case 'ymd_no_delimiter':
      return dayjs(value, 'YYYY-MM-DD').format('YYYYMMDD');
    case 'ymd_jp':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY年MM月DD日');
    case 'ym':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM');
    case 'year':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY');
    case 'month':
      return dayjs(value, 'YYYY-MM-DD').format('MM');
    case 'day':
      return dayjs(value, 'YYYY-MM-DD').format('DD');
    case 'custom_format':
      if (!dateCustomFormat) {
        return value;
      }
      return dayjs(value, 'YYYY-MM-DD').format(
        convertDateFormatFromPythonToJs(dateCustomFormat)
      );
    default:
      return value;
  }
};

const timestampFormatter = (
  value: string,
  timestampFromat?: string,
  timestampCustomFormat?: string
): string => {
  switch (timestampFromat) {
    case 'ymd_hms':
      return value;
    case 'ymd_slash_hms':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss');
    case 'ymdhms':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmmss');
    case 'ymdhms_jp':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY年MM月DD日 HH時mm分ss秒'
      );
    case 'ymd':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
    case 'ym':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM');
    case 'year':
      return dayjs(value, 'YYYY-MM-DD').format('YYYY');
    case 'month':
      return dayjs(value, 'YYYY-MM-DD').format('MM');
    case 'day':
      return dayjs(value, 'YYYY-MM-DD').format('DD');
    case 'hour':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('HH');
    case 'minute':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('mm');
    case 'second':
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('ss');
    case 'custom_format':
      if (!timestampCustomFormat) {
        return value;
      }
      return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format(
        convertDateFormatFromPythonToJs(timestampCustomFormat)
      );
    default:
      return value;
  }
};

const timeFormatter = (
  value: string,
  timeFromat?: string,
  timeCustomFormat?: string
): string => {
  switch (timeFromat) {
    case 'hms':
      return dayjs(value, 'HH:mm:ss').format('HH:mm:ss');
    case 'hms_jp':
      return dayjs(value, 'HH:mm:ss').format('HH時mm分ss秒');
    case 'hour':
      return dayjs(value, 'HH:mm:ss').format('HH');
    case 'minute':
      return dayjs(value, 'HH:mm:ss').format('mm');
    case 'second':
      return dayjs(value, 'HH:mm:ss').format('ss');
    case 'custom_format':
      if (!timeCustomFormat) {
        return value;
      }
      return dayjs(value, 'HH:mm:ss').format(
        convertDateFormatFromPythonToJs(timeCustomFormat)
      );
    default:
      return value;
  }
};

const convertDateFormatFromPythonToJs = (formatStr: string): string => {
  const replacements = {
    a: 'ddd',
    A: 'dddd',
    b: 'MMM',
    B: 'MMMM',
    c: 'lll',
    d: 'DD',
    '-d': 'D',
    e: 'D',
    F: 'YYYY-MM-DD',
    H: 'HH',
    '-H': 'H',
    I: 'hh',
    '-I': 'h',
    j: 'DDDD',
    '-j': 'DDD',
    k: 'H',
    l: 'h',
    m: 'MM',
    '-m': 'M',
    M: 'mm',
    '-M': 'm',
    p: 'A',
    P: 'a',
    S: 'ss',
    '-S': 's',
    u: 'E',
    w: 'd',
    W: 'WW',
    x: 'll',
    X: 'LTS',
    y: 'YY',
    Y: 'YYYY',
    z: 'ZZ',
    Z: 'z',
    f: 'SSS',
    '%': '%'
  };
  const tokens = formatStr.split(/(%\-?.)/);
  const dayjsFormat = tokens
    .map(function (token) {
      // Replace strftime tokens with dayjs formats
      if (token[0] === '%' && replacements.hasOwnProperty(token.substr(1))) {
        return replacements[token.substr(1)];
      }
      // Escape non-token strings to avoid accidental formatting
      return token.length > 0 ? '[' + token + ']' : token;
    })
    .join('');
  return dayjsFormat;
};

export const toValueLabel = (v: Variable): string => {
  switch (v.dtype) {
    case 'string':
      return v.value;
    case 'number':
      return v.value;
    case 'date':
      if (v.dateType === 'fixed') {
        return dateFormatter(v.value, v.dateFormat, v.dateCustomFormat);
      } else {
        return dateFormatter(
          toDateValueLabel(
            v.value,
            v.dateUnit as ManipulateType,
            v.dateOperator
          ),
          v.dateFormat,
          v.dateCustomFormat
        );
      }
    case 'timestamp':
      if (v.dateType === 'fixed') {
        return timestampFormatter(
          v.value,
          v.timestampFormat,
          v.timestampCustomFormat
        );
      } else {
        return timestampFormatter(
          toTimestampValueLabel(
            v.value,
            v.dateUnit as ManipulateType,
            v.dateOperator
          ),
          v.timestampFormat,
          v.timestampCustomFormat
        );
      }
    case 'time':
      if (v.dateType === 'fixed') {
        return timeFormatter(v.value, v.timeFormat, v.timeCustomFormat);
      } else {
        return timeFormatter(
          toTimeValueLabel(
            v.value,
            v.dateUnit as ManipulateType,
            v.dateOperator
          ),
          v.timeFormat,
          v.timeCustomFormat
        );
      }
    default:
      return v.value;
  }
};

const toDateValueLabel = (
  value: string,
  dateUnit?: ManipulateType,
  dateOperator?: string
): string => {
  switch (value) {
    case 'today':
      return dayjs().locale('ja').format('YYYY-MM-DD');
    case 'yesterday':
      return dayjs().locale('ja').add(-1, 'days').format('YYYY-MM-DD');
    case '7daysago':
      return dayjs().locale('ja').add(-7, 'days').format('YYYY-MM-DD');
    case '3monthsago':
      return dayjs().locale('ja').add(-3, 'months').format('YYYY-MM-DD');
    case '6monthsago':
      return dayjs().locale('ja').add(-6, 'months').format('YYYY-MM-DD');
    case '1yearago':
      return dayjs().locale('ja').add(-1, 'years').format('YYYY-MM-DD');
    default:
      if (dateOperator === 'add') {
        return dayjs()
          .locale('ja')
          .add(Number(value), dateUnit)
          .format('YYYY-MM-DD');
      } else if (dateOperator === 'subtract') {
        return dayjs()
          .locale('ja')
          .add(-1 * Number(value), dateUnit)
          .format('YYYY-MM-DD');
      } else {
        return value;
      }
  }
};

const toTimestampValueLabel = (
  value: string,
  dateUnit?: ManipulateType,
  dateOperator?: string
): string => {
  switch (value) {
    case 'now':
      return dayjs().locale('ja').format('YYYY-MM-DD HH:mm:ss');
    default:
      if (dateOperator === 'add') {
        return dayjs()
          .locale('ja')
          .add(Number(value), dateUnit)
          .format('YYYY-MM-DD HH:mm:ss');
      } else if (dateOperator === 'subtract') {
        return dayjs()
          .locale('ja')
          .add(-1 * Number(value), dateUnit)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        return value;
      }
  }
};

const toTimeValueLabel = (
  value: string,
  dateUnit?: ManipulateType,
  dateOperator?: string
): string => {
  switch (value) {
    case 'now':
      return dayjs().locale('ja').format('HH:mm:ss');
    default:
      if (dateOperator === 'add') {
        return dayjs()
          .locale('ja')
          .add(Number(value), dateUnit)
          .format('HH:mm:ss');
      } else if (dateOperator === 'subtract') {
        return dayjs()
          .locale('ja')
          .add(-1 * Number(value), dateUnit)
          .format('HH:mm:ss');
      } else {
        return value;
      }
  }
};

const toolbarStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      paddingRight: theme.spacing(1),
      position: 'sticky',
      top: '0px',
      zIndex: 10
    },
    spacer: {
      flex: '1 1 100%'
    },
    actions: {
      flex: '0 0 auto',
      color: theme.palette.text.secondary,
      margin: '5px'
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      flex: '0 0 auto'
    },
    textField: {
      marginLeft: 25,
      width: 1000
    }
  });

const tableToolbar: React.FunctionComponent<
  {
    onClickNew: () => void;
    onClickImport: () => void;
  } & WithStyles<typeof toolbarStyles>
> = ({ onClickNew, onClickImport, classes }) => {
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">変数一覧</Typography>
        <HelpButton manualUrlKey="variable" />
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <AccessControlContext.Consumer>
          {({ viewerDisabled }) => (
            <ViewerDisabledTooltip open={viewerDisabled}>
              <Button
                onClick={onClickImport}
                color="primary"
                disabled={viewerDisabled}
              >
                インポート
              </Button>
            </ViewerDisabledTooltip>
          )}
        </AccessControlContext.Consumer>
        <AccessControlContext.Consumer>
          {({ viewerDisabled }) => (
            <ViewerDisabledTooltip open={viewerDisabled}>
              <Button
                onClick={onClickNew}
                color="primary"
                disabled={viewerDisabled}
              >
                新規作成
              </Button>
            </ViewerDisabledTooltip>
          )}
        </AccessControlContext.Consumer>
      </div>
    </Toolbar>
  );
};

const TableToolbar = withStyles(toolbarStyles)(tableToolbar);

const useListViewStyles = makeStyles((theme: Theme) => {
  return {
    nameLink: {
      cursor: 'pointer'
    },
    shareIcon: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'middle'
    }
  };
});

interface VariableListViewProps {
  localVariables: Variable[];
  importedVariables: Variable[]; // インポートした共通変数
  resourceType: ResourceType;
  resourceId: string;
  addLocalVariable: (variable: Variable) => void;
  editLocalVariable: (variable: Variable, index: number) => void;
  deleteLocalVariable: (index: number) => void;
  saveLocalVariables: (resourceId: string) => any;
  loadImportedVariables: (
    resourceType: ResourceType,
    resourceId: string
  ) => void;
  importVariables?: (
    resourceType: ResourceType,
    resourceId: string,
    importVariableUuids: string[]
  ) => void;
  unimportVariables?: (
    resourceType: ResourceType,
    resourceId: string,
    importVariableUuids: string[]
  ) => void;
}

const VariableListView: React.FC<VariableListViewProps> = ({
  localVariables,
  importedVariables,
  resourceType,
  resourceId,
  addLocalVariable,
  editLocalVariable,
  deleteLocalVariable,
  saveLocalVariables,
  loadImportedVariables,
  importVariables,
  unimportVariables
}) => {
  const classes = useListViewStyles();
  const history = useHistory();
  const [openEditorDialog, setOpenEditorDialog] = useState(false);
  // 編集中の変数のインデックス、-1の場合は新規作成
  const [editIndex, setEditIndex] = useState(-1);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [menu, setMenu] = useState<
    | {
        el: HTMLElement;
        variable: Variable;
        variableIndex: number;
      }
    | undefined
  >(undefined);
  const [commonVariableItems, setCommonVariableItems] = useState<
    VariableItem[]
  >([]);

  const unimportVars = unimportVariables ?? unimportVariablesApi;
  const importVars = importVariables ?? importVariablesApi;

  useEffect(() => {
    loadImportedVariables(resourceType, resourceId);
  }, []);

  useEffect(() => {
    const f = async () => {
      const {
        data: { items }
      } = await getVariables();
      setCommonVariableItems(items);
    };
    f();
  }, []);

  const openAddDialog = () => {
    setOpenEditorDialog(true);
    setEditIndex(-1);
  };

  const closeEditDialog = () => {
    setOpenEditorDialog(false);
  };

  const openEditDialog = (index: number) => {
    const v = localVariables[index];
    if (v == undefined) {
      return;
    }
    setOpenEditorDialog(true);
    setEditIndex(index);
  };

  const onClickImport = () => {
    setOpenImportDialog(true);
  };

  const closeImportDialog = () => {
    setOpenImportDialog(false);
  };

  const onImportVariables = async () => {
    refresh();
  };

  const refresh = () => {
    loadImportedVariables(resourceType, resourceId);
  };

  const onClickMenuIcon = (
    event: React.MouseEvent<HTMLElement>,
    variable: Variable,
    variableIndex: number
  ) => {
    setMenu({
      el: event.currentTarget,
      variable,
      variableIndex
    });
  };

  const onCloseMenu = () => {
    setMenu(undefined);
  };

  const onClickDelete = async (
    menuVariable: Variable,
    menuVariableIndex: number
  ) => {
    if (menuVariable.uuid) {
      // 除外
      await unimportVars(resourceType, resourceId, [menuVariable.uuid]);
    } else {
      // 削除
      const v = localVariables[menuVariableIndex];
      if (v == undefined) {
        return;
      }
      if (window.confirm(v.name + 'を削除しますか？')) {
        deleteLocalVariable(menuVariableIndex);
        saveLocalVariables(resourceId);
      }
    }
    setMenu(undefined);
    refresh();
  };

  // 共通変数化
  const onClickToBeCommon = async (
    menuVariable: Variable,
    menuVariableIndex: number
  ) => {
    setMenu(undefined);
    try {
      // commonに追加 & import
      const {
        data: { uuid }
      } = await createVariable(menuVariable);
      // localから消す
      deleteLocalVariable(menuVariableIndex);
      saveLocalVariables(resourceId);
      await importVars(resourceType, resourceId, [uuid]);
    } catch (e) {
      if (e.isAxiosError) {
        alert(e.response.data);
      }
    }
    refresh();
  };

  const onClickNameLink = (variable: Variable, index: number) => {
    if (variable.uuid) {
      history.push(`/variables/${variable.uuid}`);
    } else {
      openEditDialog(index);
    }
  };

  return (
    <>
      <TableToolbar onClickNew={openAddDialog} onClickImport={onClickImport} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell>データ型</TableCell>
            <TableCell>値</TableCell>
            <TableCell>説明</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localVariables.concat(importedVariables).map((v, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {v.disableEdit || v.accessLevel === AccessLevel.Disabled ? (
                    v.name
                  ) : (
                    <Link
                      className={classes.nameLink}
                      onClick={() => onClickNameLink(v, index)}
                      underline="hover"
                    >
                      {v.name}
                    </Link>
                  )}
                  {v.uuid && (
                    <span className={classes.shareIcon}>
                      <Share fontSize="small" />
                    </span>
                  )}
                </TableCell>
                <TableCell>{toDtypeLabel(v.dtype)}</TableCell>
                <TableCell>{toValueLabel(v)}</TableCell>
                <TableCell>{v.desc}</TableCell>
                <TableCell padding="none">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      onClickMenuIcon(event, v, index);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          {localVariables.length + importedVariables.length === 0 && (
            <TableRow>
              <TableCell rowSpan={4}>変数が定義されていません</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {menu && (
        <Menu
          id="menu"
          anchorEl={menu.el}
          open={Boolean(menu.el)}
          onClose={onCloseMenu}
        >
          <MenuItem
            onClick={() => onClickDelete(menu.variable, menu.variableIndex)}
          >
            <Typography variant="inherit" color="secondary">
              {menu.variable.uuid ? '除外' : '削除'}
            </Typography>
          </MenuItem>
          {!menu.variable.uuid && (
            <Tooltip
              title={
                commonVariableItems.find((v) => v.name === menu.variable.name)
                  ? '共通変数に同じ名前があるので共通変数化できません'
                  : ''
              }
            >
              <div>
                <MenuItem
                  onClick={() =>
                    onClickToBeCommon(menu.variable, menu.variableIndex)
                  }
                  disabled={
                    !!commonVariableItems.find(
                      (v) => v.name === menu.variable.name
                    )
                  }
                >
                  <Typography>共通変数化</Typography>
                </MenuItem>
              </div>
            </Tooltip>
          )}
        </Menu>
      )}
      <VariablesImportDialog
        open={openImportDialog}
        onClose={closeImportDialog}
        localVariables={localVariables}
        resourceType={resourceType}
        resourceId={resourceId}
        onImportVariables={onImportVariables}
        importVariables={importVars}
        importedVariables={importedVariables}
      />
      <VariableEditorDialog
        open={openEditorDialog}
        onClose={closeEditDialog}
        editIndex={editIndex}
        addVariable={addLocalVariable}
        editVariable={editLocalVariable}
        saveVariables={saveLocalVariables}
        resourceId={resourceId}
        localVariables={localVariables}
        importedVariables={importedVariables}
      />
    </>
  );
};

export default VariableListView;
