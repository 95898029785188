import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  InputAdornment,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';
import MainLayout from 'components/layouts/main';
import { SelectedMenu } from 'components/mainDrawer';

const styles = () =>
  createStyles({
    content: {
      width: '100%'
    },
    title: {
      width: '288px',
      height: '27px',
      fontSize: '18px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#000000',
      marginTop: '16px',
      marginBottom: '52px'
    },
    grid: {
      width: '100%',
      paddingLeft: '30px'
    },
    search: {
      color: '#838383'
    },
    textField: {
      marginTop: '10px',
      marginBottom: '45px'
    },
    toolbar: {
      paddingLeft: 0,
      justifyContent: 'space-between'
    },
    notice: {
      height: '50px'
    }
  });

export interface TypeSelectPageProps {
  title: string;
  menu: SelectedMenu;
  onChange: (ev: any) => void;
}

const TypeSelectPage: React.FC<
  TypeSelectPageProps & WithStyles<typeof styles>
> = ({ classes, children, title, menu, onChange }) => {
  return (
    <MainLayout selectedMenu={menu}>
      <Grid container={true} className={classes.grid}>
        <div className={classes.content}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title}>{title}</Typography>
            <TextField
              className={classes.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes.search} />
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  width: '234px',
                  height: '40px',
                  borderRadius: '6px',
                  backgroundColor: '#f4f4f4',
                  color: '#838383',
                  paddingBottom: '15px',
                  fontSize: '13px'
                }
              }}
              onChange={onChange}
              variant={'filled'}
              placeholder="検索"
            />
          </Toolbar>
          {children}
          <div className={classes.notice}>
            この他にもサポートしている接続先があります。接続可能なデータタイプの一覧は
            <a target="_blank" href="https://www.cdata.com/jp/drivers/">
              こちら
            </a>
            。（CData社のサイトが開きます。）
          </div>
        </div>
      </Grid>
    </MainLayout>
  );
};

export default withStyles(styles)(TypeSelectPage);
