import * as React from 'react';
import {
  ButtonProps,
  IconButton,
  Popover,
  PopoverProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { Create } from '@mui/icons-material';
import { NavButton } from 'ui/navButton';

interface TitleFieldProps {
  color?: ButtonProps['color'];
  value: string;
  editable: boolean;
  onChange?: (value: string) => void;
  buttonLabel?: string;
  variant?: TypographyVariant;
  dataCy?: string;
  className?: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

export const TitleField: React.FC<TitleFieldProps> = ({
  color,
  value,
  editable,
  onChange,
  buttonLabel,
  variant,
  disabled,
  disabledTooltip,
  dataCy,
  className
}) => {
  const [name, setName] = React.useState<string>(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    []
  );

  React.useEffect(() => {
    if (name !== value) {
      setName(value);
    }
  }, [value]);

  const handleSave = React.useCallback(() => {
    onChange && onChange(name);
    handleClose();
  }, [name, onChange]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography data-cy={dataCy} variant={variant} className={className}>
        {value}
      </Typography>
      {editable && (
        <Tooltip title={disabledTooltip ? disabledTooltip : ''}>
          <span>
            <IconButton
              data-cy="title-field-change-name"
              size="small"
              disabled={disabled}
              onClick={handleOpen}
            >
              <Create fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <NameEditDialog
        color={color}
        anchorEl={anchorEl}
        name={name}
        onChange={handleOnChange}
        onClose={handleClose}
        onClickSave={handleSave}
        buttonLabel={buttonLabel}
      />
    </>
  );
};

export const NameEditDialog: React.FC<{
  color?: ButtonProps['color'];
  anchorEl?: PopoverProps['anchorEl'];
  name: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onClickSave: () => void;
  buttonLabel?: string;
}> = ({
  color,
  anchorEl,
  name,
  onChange,
  onClose,
  onClickSave,
  buttonLabel
}) => {
  if (!color) {
    color = 'nehan';
  }
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <div
        style={{ display: 'flex', margin: 8, alignItems: 'center' }}
        data-cy="name-edit-dialog"
      >
        <Typography style={{ marginRight: 8 }}>名前の変更:</Typography>
        <TextField
          variant="standard"
          value={name}
          onChange={onChange}
          autoFocus={true}
          InputProps={{
            disableUnderline: true,
            style: { padding: 4 }
          }}
          inputProps={{
            'data-cy': 'text-field-change-name'
          }}
          style={{ marginRight: 8, backgroundColor: 'rgba(0, 0, 0, 0.09)' }}
        />
        <NavButton
          variant="contained"
          color={color}
          onClick={onClickSave}
          data-cy="name-edit-dialog-change-button"
        >
          {buttonLabel ?? '変更'}
        </NavButton>
      </div>
    </Popover>
  );
};
