import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';

export const Switch = withStyles((theme) => ({
  root: {
    padding: 8
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#000000',
        opacity: 1
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    margin: 2,
    boxShadown: 'none'
  },
  track: {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    }
  },
  checked: {}
}))((props: SwitchProps) => <MuiSwitch disableRipple={true} {...props} />);
