import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ListItem, ListItemType } from '../../../models/dependency';
import { Path } from 'models/folder';
import ListView from 'components/ui/listView';
import { Dialog } from '../common/dialog';
import { ThemeProvider } from '@mui/material';
import mainTheme from '../../../theme';

const FolderSelectList: React.FC<{
  type: ListItemType;
  folderId: string;
  changeFolderId: (id: string) => void;
  load: () => Promise<{ data: { items: ListItem[]; paths: Path[] } }>;
}> = ({ type, load, folderId, changeFolderId }) => {
  const onClickItem = (item: ListItem) => {
    if (item.is_folder) {
      changeFolderId(item.uuid);
    }
  };
  return (
    <ListView
      type={type}
      load={load}
      folderId={folderId}
      onClickItem={onClickItem}
      onClickItemLink={onClickItem}
      onClickFolder={(fid: string) => {
        changeFolderId(fid);
      }}
      folderOnly={true}
      showDetailPane={false}
      supportFolder={true}
      disableCheck={true}
    />
  );
};

const dialogStyles = makeStyles(() => ({
  paper: {},
  content: {
    padding: 0,
    height: 'calc(830px - 58px)'
  }
}));

export const FolderSelectDialog: React.FC<{
  type: ListItemType;
  mode: 'move' | 'copy' | 'create' | 'export_datasource';
  defaultFolderId: string;
  load: () => Promise<{ data: { items: ListItem[]; paths: Path[] } }>;
  open: boolean;
  onClose: (folderId: string | null) => void;
  okButtonText?: string;
}> = ({ type, mode, defaultFolderId, load, open, onClose, okButtonText }) => {
  const classes = dialogStyles();
  const [folderId, setFolderId] = React.useState<string>(defaultFolderId);

  const dialogTitle: string = React.useMemo(() => {
    switch (mode) {
      case 'copy':
        return '複製する階層を選択してください';
      case 'move':
        return '移動先の階層を選択してください';
      case 'create':
        return '新規作成する階層を選択してください';
      case 'export_datasource':
        return '作成先の階層を選択してください';
    }
  }, [mode]);

  const buttonText: string = React.useMemo(() => {
    if (okButtonText) {
      return okButtonText;
    }
    switch (mode) {
      case 'copy':
        return 'この階層に複製';
      case 'move':
        return 'この階層に移動';
      case 'create':
        return 'この階層に作成';
      case 'export_datasource':
        return 'この階層に作成';
    }
  }, [mode, okButtonText]);

  if (!open) {
    return null;
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        classes={{ paper: classes.paper }}
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={() => onClose(null)}
        title={dialogTitle}
        contentProps={{ classes: { root: classes.content } }}
        OKButton={{
          label: buttonText,
          onClick: () => onClose(folderId)
        }}
      >
        <FolderSelectList
          type={type}
          load={load}
          folderId={folderId}
          changeFolderId={setFolderId}
        />
      </Dialog>
    </ThemeProvider>
  );
};
