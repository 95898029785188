import { blue, grey, red } from '@mui/material/colors';
import { NodeBackend } from 'models/websocket';
import { RedoItemType, UndoItemType } from 'reducers/undo';

export enum NodeStatus {
  Temporary = 'TEMPORARY',
  Warning = 'WARNING',
  Prospecting = 'PROSPECTING',
  Prospected = 'PROSPECTED',
  Executing = 'EXECUTING',
  Executed = 'EXECUTED',
  Copied = 'COPIED',
  Error = 'ERROR',
  EmptyOutput = 'EMPTY_OUTPUT'
}

export const NodeStatusText = (s: NodeStatus): string => {
  switch (s) {
    case NodeStatus.Temporary:
    case NodeStatus.Copied:
      return '未実行';
    case NodeStatus.EmptyOutput:
    case NodeStatus.Warning:
      return '警告あり';
    case NodeStatus.Prospecting:
    case NodeStatus.Executing:
      return '実行中';
    case NodeStatus.Prospected:
    case NodeStatus.Executed:
      return '成功';
    case NodeStatus.Error:
      return 'エラー';
  }
};

export const NodeStatusColor = (s: NodeStatus): string => {
  switch (s) {
    case NodeStatus.Prospecting:
    case NodeStatus.Executing:
      return blue['A700'];

    case NodeStatus.Prospected:
    case NodeStatus.Executed:
      return '#7fce5d';

    case NodeStatus.EmptyOutput:
    case NodeStatus.Warning:
    case NodeStatus.Error:
      return red['A700'];

    case NodeStatus.Temporary:
    case NodeStatus.Copied:
      return grey[600];

    default:
      return grey[900];
  }
};

export enum PortStatus {
  Created = 'CREATED',
  Temporary = 'TEMPORARY',
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Copied = 'COPIED',
  Error = 'ERROR'
}

export enum EdgeStatus {
  Temporary = 'TEMPORARY',
  Created = 'CREATED',
  Copied = 'COPIED',
  Error = 'ERROR'
}

export interface Node {
  [index: string]: unknown; // reactflowのNodeに渡すよう

  id: string;
  name: string;
  processType: string;
  inPorts: string[];
  outPorts: string[];
  formValues?: any;
  formErrors?: any;
  status: NodeStatus;
  backend: NodeBackend;
  x: number;
  y: number;
  errorMessage?: string;
  comment?: string;
  autoComment: string;
  execution_id?: string;
  started_at?: string;
  ended_at?: string;
  api_uuid?: string;
  rows: number;
}

export interface NodeMeta {
  height: number;
  width: number;
  id: string;
}

export interface Nodes {
  [id: string]: Node;
}

export interface Port {
  id: string;
  // OutputとかErrorとか(yamlに書いてるやつ)
  name: string;
  portLabel: string;
  objectType: string;
  display?: boolean;
  status: PortStatus;
  // S3にアップロードしたファイル名
  exportId?: string;
}

export interface Ports {
  [id: string]: Port;
}

export interface EdgePoint {
  nodeId: string;
  portId: string;
}

export interface Edge {
  [index: string]: unknown; // reactflowのEdgeに渡すよう

  id: string;
  from: EdgePoint;
  to: EdgePoint;
  status: EdgeStatus;
}

export interface Edges {
  [id: string]: Edge;
}

export interface Positions {
  offsetX: number;
  offsetY: number;
  zoomLevel: number;
}

export interface Graph {
  nodes: Nodes;
  edges: Edges;
  ports: Ports;
  positions: Positions;
  selectedNodeId?: string;
  previousNodeId?: string;
  undoList: UndoItemType[];
  redoList: RedoItemType[];
}

export interface PortInfo {
  portId: string;
  objectType: string;
  display?: boolean;
  status?: string;
  exportId?: string;
  portLabel?: string;
  showColumnInfo?: boolean;
}

export interface DataMap {
  [name: string]: PortInfo;
}

export interface UpdateCoordinate {
  id: string;
  x: number;
  y: number;
}
