import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
  setDescribeStatus,
  setHelpStatus,
  setNotificationStatus
} from 'actions/userConfig';
import { connect } from 'react-redux';
import MainAppBar from 'components/mainAppBar';
import { StoreType } from '../configureStore';
import { AppBarProps } from '@mui/material';

function mapStateToProps(
  state: StoreType,
  ownProps: {
    children?: React.ReactNode;
    appBarProps?: AppBarProps;
  }
) {
  return {
    help: state.userConfig.help,
    notification: state.userConfig.notification,
    describe: state.userConfig.describe,
    ...ownProps
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHelpStatus,
      setNotificationStatus,
      setDescribeStatus
    },
    dispatch
  );
}

export type MainAppBarContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MainAppBar);
