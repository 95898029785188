import {
  accessLevelList,
  datasourceStatusList,
  dataTypeList,
  exportStatusList,
  userRoleList
} from 'ui/listView/filter/form';
import { AccessLevel, UserRole } from 'libs/accessLevel';
import { customDateList } from 'ui/listView/filter/customDate';
import { DatasourceStatus } from 'models/datasource';
import { Dtypes } from 'Utils/dataTypes';
import { ListItemType } from 'models/dependency';
import { FilterDispatch } from 'ui/listView/filter/filterReducer';
import {
  FilterActionType,
  FilterState,
  FilterType
} from 'ui/listView/filter/type';
import { NodeStatus } from 'models/graph';
import { ChangeEvent, MutableRefObject } from 'react';
import { DateRangeValues } from 'models/report/parameter';
import { SelectChangeEvent } from '@mui/material';

export const handleChangeAccessLevels = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: SelectChangeEvent<string[]>) => {
    const keys = findKeysByValues(
      accessLevelList,
      event.target.value as string[],
      String(AccessLevel.Disabled)
    );
    dispatch(state, { type: FilterType.AccessLevels, payload: keys });
  };
};

export const handleChangeBuilderName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.BuilderName,
      payload: event.target.value
    });
};

export const handleChangeConnectionName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.ConnectionName,
      payload: event.target.value
    });
};

export const handleChangeCreators = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (_, value: string[]) =>
    dispatch(state, { type: FilterType.Creators, payload: value });
};

export const handleChangeCustomDateCondition = (
  typeCalendarPosition: FilterActionType,
  typeCondition: FilterActionType,
  ref: MutableRefObject<HTMLTableElement | null>,
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<{ value: string }>) => {
    if (event.target.value === 'カレンダーから選択') {
      dispatch(state, {
        type: typeCalendarPosition,
        payload: ref.current
      });
      return;
    }

    const customDate = findKeyByValue(
      customDateList,
      event.target.value,
      'empty'
    );
    dispatch(state, { type: typeCondition, payload: customDate });
  };
};

export const handleChangeCustomDateRange = (
  type: FilterActionType,
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (range: DateRangeValues) => dispatch(state, { type, payload: range });
};

export const handleChangeDatasourceName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.DatasourceName,
      payload: event.target.value
    });
};

const findKeyByValue = (
  target: { [key: string]: string },
  value: string,
  defaultValue: string
) => {
  return (
    Object.entries(target)
      .find(([_, label]) => value == label)
      ?.shift() ?? defaultValue
  );
};

const findKeysByValues = (
  target: { [key: string]: string },
  values: string[],
  defaultValue: string
) => {
  return values.map((v) => {
    return findKeyByValue(target, v, defaultValue);
  });
};

export const handleChangeDatasourceStatuses = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: SelectChangeEvent<string[]>) => {
    const keys = findKeysByValues(
      datasourceStatusList,
      event.target.value as string[],
      String(DatasourceStatus.Failed)
    );
    dispatch(state, {
      type: FilterType.DatasourceStatuses,
      payload: keys
    });
  };
};

export const handleChangeDatasourceTypes = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (_, value: string[]) =>
    dispatch(state, { type: FilterType.DatasourceTypes, payload: value });
};

export const handleChangeDataTypes = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: SelectChangeEvent<string[]>) => {
    const keys = findKeysByValues(
      dataTypeList,
      event.target.value as string[],
      String(Dtypes.STRING)
    );
    dispatch(state, { type: FilterType.DataTypes, payload: keys });
  };
};

export const handleChangeExcludeFolders = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.ExcludeFolders,
      payload: event.target.checked
    });
};

export const handleChangeIsScheduled = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.IsScheduled,
      payload: event.target.checked
    });
};

export const handleChangeExportStatuses = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: SelectChangeEvent<string[]>) => {
    const keys = findKeysByValues(
      exportStatusList,
      event.target.value as string[],
      String(NodeStatus.Error)
    );
    dispatch(state, {
      type: FilterType.ExportStatuses,
      payload: keys
    });
  };
};

export const handleChangeLimitedAccess = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.LimitedAccess,
      payload: event.target.checked
    });
};

export const handleChangeProjectName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.ProjectName,
      payload: event.target.value
    });
};

export const handleChangePublicUrlExists = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.PublicURLExists,
      payload: event.target.checked
    });
};

export const handleChangeReportName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.ReportName,
      payload: event.target.value
    });
};

export const handleChangeVariableName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.VariableName,
      payload: event.target.value
    });
};

export const handleChangeScheduleName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.ScheduleName,
      payload: event.target.value
    });
};

export const handleClickClearButton = (
  type: ListItemType,
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return () => dispatch(state, { type: FilterType.Clear, payload: type });
};

export const handleCloseCalendar = (
  type: FilterActionType,
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return () => dispatch(state, { type, payload: null });
};

export const handleChangeNotificationName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.NotificationName,
      payload: event.target.value
    });
};

export const handleChangeNotificationDstName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.NotificationDstName,
      payload: event.target.value
    });
};

export const handleChangeNotificationDstTypes = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (_, value: string[]) =>
    dispatch(state, { type: FilterType.NotificationDstTypes, payload: value });
};

export const handleChangeUserGroupName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.UserGroupName,
      payload: event.target.value
    });
};

export const handleChangeUserGroupHasIP = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.HasIPAddress,
      payload: event.target.checked
    });
};

export const handleChangeUserEmail = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.UserEmail,
      payload: event.target.value
    });
};

export const handleChangeUserTypes = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: SelectChangeEvent<string[]>) => {
    const keys = findKeysByValues(
      userRoleList,
      event.target.value as string[],
      String(UserRole.Disabled)
    );
    dispatch(state, {
      type: FilterType.UserTypes,
      payload: keys
    });
  };
};

export const handleChangeUserPasswordGenerated = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.UserPasswordGenerated,
      payload: event.target.checked
    });
};

export const handleChangeUserGroups = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (_, value: string[]) =>
    dispatch(state, { type: FilterType.UserGroups, payload: value });
};

export const handleChangeOrganizationName = (
  state: FilterState,
  dispatch: FilterDispatch
) => {
  return (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(state, {
      type: FilterType.OrganizationName,
      payload: event.target.value
    });
};
