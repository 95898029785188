import * as React from 'react';

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box
} from '@mui/material';
import { Dtypes, toDtypeLabel } from 'Utils/dataTypes';
import { Variable } from 'models/project';

interface VariableFormProps {
  variable: Variable;
  onChange: (variableParam: Partial<Variable>) => void;
}

export class VariableFrom extends React.Component<VariableFormProps> {
  constructor(props) {
    super(props);
  }

  handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const changedParams =
      ev.target.name === 'dtype'
        ? {
            dtype: ev.target.value,
            value: '',
            dateFormat: 'ymd',
            timestampFormat: 'ymd_hms'
          }
        : { [ev.target.name]: ev.target.value };
    this.props.onChange(changedParams);
  };

  renderValueField = () => {
    const {
      variable: { value, dtype }
    } = this.props;
    switch (dtype) {
      case 'string':
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
      case 'number':
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
      case 'date':
        return this.renderDateValueField();
      case 'timestamp':
        return this.renderTimestampValueField();
      case 'time':
        return this.renderTimeValueField();
      default:
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
    }
  };

  dateValueField = () => {
    const {
      variable: { value, dateType, dateUnit, dateOperator }
    } = this.props;
    switch (dateType) {
      case 'common':
        return (<>
          <InputLabel shrink={true}>変数の値</InputLabel>
          <Select
            variant="standard"
            name="value"
            fullWidth={true}
            value={value}
            onChange={this.handleChange}>
            <MenuItem value="today">今日</MenuItem>
            <MenuItem value="yesterday">昨日</MenuItem>
            <MenuItem value="7daysago">7日前</MenuItem>
            <MenuItem value="3monthsago">3ヶ月前</MenuItem>
            <MenuItem value="6monthsago">半年前</MenuItem>
            <MenuItem value="1yearago">1年前</MenuItem>
          </Select>
        </>);
      case 'relative':
        return (<>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" p={1} m={1}>
              <Box p={1} style={{ paddingTop: '25px' }}>
                今日から
              </Box>
              <Box p={1}>
                <TextField
                  variant="standard"
                  name="value"
                  label="値"
                  fullWidth={true}
                  value={value}
                  onChange={this.handleChange} />
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>単位</InputLabel>
                <Select
                  variant="standard"
                  name="dateUnit"
                  fullWidth={true}
                  value={dateUnit}
                  onChange={this.handleChange}>
                  <MenuItem value="years">年</MenuItem>
                  <MenuItem value="months">月</MenuItem>
                  <MenuItem value="days">日</MenuItem>
                </Select>
              </Box>
              <Box p={1} style={{ paddingTop: '25px' }}>
                を
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>加算/減算</InputLabel>
                <Select
                  variant="standard"
                  name="dateOperator"
                  fullWidth={true}
                  value={dateOperator}
                  onChange={this.handleChange}>
                  <MenuItem value="add">加算</MenuItem>
                  <MenuItem value="subtract">減算</MenuItem>
                </Select>
              </Box>
            </Box>
          </div>
        </>);
      case 'fixed':
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
    }
  };

  dateFormatField = () => {
    const {
      variable: { dateFormat }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>日付のフォーマット</InputLabel>
      <Select
        variant="standard"
        name="dateFormat"
        fullWidth={true}
        value={dateFormat}
        onChange={this.handleChange}>
        <MenuItem value="ymd">YYYY-mm-dd</MenuItem>
        <MenuItem value="ymd_slash">YYYY/mm/dd</MenuItem>
        <MenuItem value="ymd_no_delimiter">YYYYmmdd</MenuItem>
        <MenuItem value="ymd_jp">YYYY年mm月dd日</MenuItem>
        <MenuItem value="ym">YYYY-mm</MenuItem>
        <MenuItem value="year">YYYY ※年</MenuItem>
        <MenuItem value="month">mm ※月</MenuItem>
        <MenuItem value="day">dd ※日</MenuItem>
        <MenuItem value="custom_format">自分で設定する</MenuItem>
      </Select>
      {this.dateCustomFormatField()}
    </>);
  };

  dateCustomFormatField = () => {
    const {
      variable: { dateCustomFormat, dateFormat }
    } = this.props;
    switch (dateFormat) {
      case 'custom_format':
        return (<>
          <InputLabel shrink={true}>フォーマットを入力</InputLabel>
          <TextField
            variant="standard"
            name="dateCustomFormat"
            fullWidth={true}
            value={dateCustomFormat}
            onChange={this.handleChange} />
        </>);
    }
  };

  timestampFormatField = () => {
    const {
      variable: { timestampFormat }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>日付のフォーマット</InputLabel>
      <Select
        variant="standard"
        name="timestampFormat"
        fullWidth={true}
        value={timestampFormat}
        onChange={this.handleChange}>
        <MenuItem value="ymd_hms">YYYY-mm-dd HH:MM:SS</MenuItem>
        <MenuItem value="ymd_slash_hms">YYYY/mm/dd HH:MM:SS</MenuItem>
        <MenuItem value="ymdhms">YYYYmmddHHMMSS</MenuItem>
        <MenuItem value="ymdhms_jp">YYYY年mm月dd日 HH時MM分SS秒</MenuItem>
        <MenuItem value="ymd">YYYY-mm-dd</MenuItem>
        <MenuItem value="ym">YYYY-mm</MenuItem>
        <MenuItem value="year">YYYY ※年</MenuItem>
        <MenuItem value="month">mm ※月</MenuItem>
        <MenuItem value="day">dd ※日</MenuItem>
        <MenuItem value="hour">HH ※時</MenuItem>
        <MenuItem value="minute">MM ※分</MenuItem>
        <MenuItem value="second">SS ※秒</MenuItem>
        <MenuItem value="custom_format">自分で設定する</MenuItem>
      </Select>
      {this.timestampCustomFormatField()}
    </>);
  };

  timestampCustomFormatField = () => {
    const {
      variable: { timestampCustomFormat, timestampFormat }
    } = this.props;
    switch (timestampFormat) {
      case 'custom_format':
        return (<>
          <InputLabel shrink={true}>フォーマットを入力</InputLabel>
          <TextField
            variant="standard"
            name="timestampCustomFormat"
            fullWidth={true}
            value={timestampCustomFormat}
            onChange={this.handleChange} />
        </>);
    }
  };

  timeFormatField = () => {
    const {
      variable: { timeFormat }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>時間のフォーマット</InputLabel>
      <Select
        variant="standard"
        name="timeFormat"
        fullWidth={true}
        value={timeFormat}
        onChange={this.handleChange}>
        <MenuItem value="hms">HH:MM:SS</MenuItem>
        <MenuItem value="hms_jp">HH時MM分SS秒</MenuItem>
        <MenuItem value="hour">HH ※時</MenuItem>
        <MenuItem value="minute">MM ※分</MenuItem>
        <MenuItem value="second">SS ※秒</MenuItem>
        <MenuItem value="custom_format">自分で設定する</MenuItem>
      </Select>
      {this.timeCustomFormatField()}
    </>);
  };

  timeCustomFormatField = () => {
    const {
      variable: { timeCustomFormat, timeFormat }
    } = this.props;
    switch (timeFormat) {
      case 'custom_format':
        return (<>
          <InputLabel shrink={true}>フォーマットを入力</InputLabel>
          <TextField
            variant="standard"
            name="timeCustomFormat"
            fullWidth={true}
            value={timeCustomFormat}
            onChange={this.handleChange} />
        </>);
    }
  };

  renderDateValueField = () => {
    const {
      variable: { dateType }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>日付の指定方法</InputLabel>
      <Select
        variant="standard"
        name="dateType"
        fullWidth={true}
        value={dateType}
        onChange={this.handleChange}>
        <MenuItem key="common" value="common">
          よく使う日付を指定
        </MenuItem>
        <MenuItem key="relative" value="relative">
          相対日付を指定
        </MenuItem>
        <MenuItem key="fixed" value="fixed">
          固定日付を入力
        </MenuItem>
      </Select>
      {this.dateValueField()}
      {this.dateFormatField()}
    </>);
  };

  timestampValueField = () => {
    const {
      variable: { value, dateType, dateUnit, dateOperator }
    } = this.props;
    switch (dateType) {
      case 'common':
        return (<>
          <InputLabel shrink={true}>変数の値</InputLabel>
          <Select
            variant="standard"
            name="value"
            fullWidth={true}
            value={value}
            onChange={this.handleChange}>
            <MenuItem value="now">現在時刻</MenuItem>
          </Select>
        </>);
      case 'relative':
        return (<>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" p={1} m={1}>
              <Box p={1} style={{ paddingTop: '25px' }}>
                現在時刻から
              </Box>
              <Box p={1}>
                <TextField
                  variant="standard"
                  name="value"
                  label="値"
                  fullWidth={true}
                  value={value}
                  onChange={this.handleChange} />
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>単位</InputLabel>
                <Select
                  variant="standard"
                  name="dateUnit"
                  fullWidth={true}
                  value={dateUnit}
                  onChange={this.handleChange}>
                  <MenuItem value="years">年</MenuItem>
                  <MenuItem value="months">月</MenuItem>
                  <MenuItem value="days">日</MenuItem>
                  <MenuItem value="hours">時間</MenuItem>
                  <MenuItem value="minutes">分</MenuItem>
                  <MenuItem value="seconds">秒</MenuItem>
                </Select>
              </Box>
              <Box p={1} style={{ paddingTop: '25px' }}>
                を
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>加算/減算</InputLabel>
                <Select
                  variant="standard"
                  name="dateOperator"
                  fullWidth={true}
                  value={dateOperator}
                  onChange={this.handleChange}>
                  <MenuItem value="add">加算</MenuItem>
                  <MenuItem value="subtract">減算</MenuItem>
                </Select>
              </Box>
            </Box>
          </div>
        </>);
      case 'fixed':
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
    }
  };

  renderTimestampValueField = () => {
    const {
      variable: { dateType }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>日付時間の指定方法</InputLabel>
      <Select
        variant="standard"
        name="dateType"
        fullWidth={true}
        value={dateType}
        onChange={this.handleChange}>
        <MenuItem key="common" value="common">
          よく使う日付時間を指定
        </MenuItem>
        <MenuItem key="relative" value="relative">
          相対日付時間を指定
        </MenuItem>
        <MenuItem key="fixed" value="fixed">
          固定日付時間を入力
        </MenuItem>
      </Select>
      {this.timestampValueField()}
      {this.timestampFormatField()}
    </>);
  };

  timeValueField = () => {
    const {
      variable: { value, dateType, dateUnit, dateOperator }
    } = this.props;
    switch (dateType) {
      case 'common':
        return (<>
          <InputLabel shrink={true}>変数の値</InputLabel>
          <Select
            variant="standard"
            name="value"
            fullWidth={true}
            value={value}
            onChange={this.handleChange}>
            <MenuItem value="now">現在時刻</MenuItem>
          </Select>
        </>);
      case 'relative':
        return (<>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" p={1} m={1}>
              <Box p={1} style={{ paddingTop: '25px' }}>
                現在時刻から
              </Box>
              <Box p={1}>
                <TextField
                  variant="standard"
                  name="value"
                  label="値"
                  fullWidth={true}
                  value={value}
                  onChange={this.handleChange} />
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>単位</InputLabel>
                <Select
                  variant="standard"
                  name="dateUnit"
                  fullWidth={true}
                  value={dateUnit}
                  onChange={this.handleChange}>
                  <MenuItem value="hours">時間</MenuItem>
                  <MenuItem value="minutes">分</MenuItem>
                  <MenuItem value="seconds">秒</MenuItem>
                </Select>
              </Box>
              <Box p={1} style={{ paddingTop: '25px' }}>
                を
              </Box>
              <Box p={1}>
                <InputLabel shrink={true}>加算/減算</InputLabel>
                <Select
                  variant="standard"
                  name="dateOperator"
                  fullWidth={true}
                  value={dateOperator}
                  onChange={this.handleChange}>
                  <MenuItem value="add">加算</MenuItem>
                  <MenuItem value="subtract">減算</MenuItem>
                </Select>
              </Box>
            </Box>
          </div>
        </>);
      case 'fixed':
        return (
          (<TextField
            variant="standard"
            name="value"
            label="変数の値"
            fullWidth={true}
            value={value}
            onChange={this.handleChange} />)
        );
    }
  };

  renderTimeValueField = () => {
    const {
      variable: { dateType }
    } = this.props;
    return (<>
      <InputLabel shrink={true}>時間の指定方法</InputLabel>
      <Select
        variant="standard"
        name="dateType"
        fullWidth={true}
        value={dateType}
        onChange={this.handleChange}>
        <MenuItem key="common" value="common">
          よく使う時間を指定
        </MenuItem>
        <MenuItem key="relative" value="relative">
          相対時間を指定
        </MenuItem>
        <MenuItem key="fixed" value="fixed">
          固定時間を入力
        </MenuItem>
      </Select>
      {this.timeValueField()}
      {this.timeFormatField()}
    </>);
  };

  render() {
    const {
      variable: { name, dtype, desc }
    } = this.props;

    return (<>
      <TextField
        variant="standard"
        autoFocus={true}
        name="name"
        label="変数名(半角英数字と_(アンダースコア)のみ。先頭は数値以外)"
        fullWidth={true}
        value={name}
        inputProps={{
          required: true,
          pattern: '[_A-Za-z][_A-Za-z0-9]*'
        }}
        onChange={this.handleChange} />
      <InputLabel shrink={true}>データ型</InputLabel>
      <Select
        variant="standard"
        name="dtype"
        fullWidth={true}
        value={dtype}
        onChange={this.handleChange}>
        {[
          Dtypes.STRING,
          Dtypes.NUMBER,
          Dtypes.DATE,
          Dtypes.TIMESTAMP,
          Dtypes.TIME
        ].map((dtype: Dtypes) => {
          return (
            <MenuItem key={dtype} value={dtype}>
              {toDtypeLabel(dtype)}
            </MenuItem>
          );
        })}
      </Select>
      {this.renderValueField()}
      <TextField
        variant="standard"
        name="desc"
        label="変数の説明"
        fullWidth={true}
        value={desc}
        onChange={this.handleChange} />
    </>);
  }
}
