import * as React from 'react';
import { TableCell } from '@mui/material';
import { ListItem, UserGroupItem } from 'models/dependency';
import { LinkCell, OpenMenuType, ShareDialogCell } from 'ui/listViewTableCell';
import { AccessLevel } from 'libs/accessLevel';

export const UserGroupRow: React.FC<{
  item: UserGroupItem;
  onOpenMenu: OpenMenuType;
  onClickItemLink?: (item: ListItem) => void;
}> = ({ item, onOpenMenu, onClickItemLink }) => {
  const onClick = React.useCallback(() => {
    onClickItemLink && onClickItemLink(item);
  }, [onClickItemLink, item]);

  return (
    <>
      <LinkCell
        href={`/user_groups/${item.uuid}`}
        name={item.name}
        onClick={onClick}
      />
      <TableCell>{item.member_count}</TableCell>
      <TableCell>{item.ip_address}</TableCell>
      <ShareDialogCell
        uuid={item.uuid}
        name={item.name}
        accessLevel={AccessLevel.Disabled}
        onOpenMenu={onOpenMenu}
        disableShare={true}
      />
    </>
  );
};
