import * as React from 'react';
import {
  AggregateFunc,
  Option,
  StringCellColorOption,
  TableColumn,
  timeFormatTypes
} from 'models/chart';
import {
  CategoricalColorType,
  getColors,
  Palette,
  SequentialColorType
} from 'components/visualize/chart/color';
import {
  SelectField,
  SelectFieldProps
} from 'components/visualize/form/select';
import {
  CheckboxField,
  InputField,
  NumberField,
  StringField
} from 'components/visualize/form/input';
import { Label } from 'components/visualize/form/label';
import {
  PaletteColorSelector,
  PaletteSelector
} from 'components/visualize/chart/palette';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { bottomAggregateMethod } from '../editor/util';

export const defaultMinBarColor = '#F19483';

export const defaultPalette = {
  type: SequentialColorType.Orange,
  size: 5
};

export const settingLabels = {
  align: { label: 'テキストの位置' },
  dir: { label: '並べ替え' },
  toLocale: { label: '千桁区切りにする' },
  toFixed: { label: '数値の表示桁数' },
  toPercentage: { label: 'パーセント表示にする' },
  showProgress: { label: 'ミニバーを表示する' },
  progressColorPalette: { label: 'カラーパレット' },
  color: { label: 'ミニバーの色' },
  showCellColor: { label: '条件付き書式を有効にする' },
  palette: { label: 'カラーパレット' },
  setTextColor: { label: '文字色を変更する' },
  textColorPalette: { label: 'カラーパレット' },
  textColor: { label: 'テキストの色' },
  toFormat: { label: '表示形式を変更する' },
  timeFormatType: { label: '表示形式' },
  timeFormat: { label: '自分で指定' },
  aggregate: { label: '集計' },
  bottomAggregate: { label: '列の集約値を表示する' }
};

export const RowAlign: React.FC<{
  align: TableColumn['align'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ align, setColumn }) => {
  return (
    <Align
      showLabel
      align={align}
      setOption={(option) =>
        setColumn((prev) => {
          if (prev == undefined) {
            return undefined;
          }
          return { ...prev, align: option.align };
        })
      }
    />
  );
};

export const Align: React.FC<{
  showLabel?: boolean;
  align: TableColumn['align'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ align, setOption, showLabel = false }) => {
  return (
    <SelectField
      label={showLabel ? settingLabels.align.label : undefined}
      placeholder="位置"
      value={align}
      onChange={(align: 'center' | 'left' | 'right') => setOption({ align })}
      items={[
        { label: '左', value: 'left' },
        { label: '中央', value: 'center' },
        { label: '右', value: 'right' }
      ]}
      isClearable={true}
    />
  );
};

export const RowDir: React.FC<{
  dir: TableColumn['dir'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ dir, setColumn }) => {
  return (
    <Dir
      showLabel
      dir={dir}
      setOption={(option) =>
        setColumn((prev) => {
          if (prev == undefined) {
            return undefined;
          }
          return { ...prev, dir: option.dir };
        })
      }
    />
  );
};

export const Dir: React.FC<{
  showLabel?: boolean;
  dir: TableColumn['dir'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ dir, setOption, showLabel = false }) => {
  return (
    <SelectField
      label={showLabel ? settingLabels.dir.label : undefined}
      placeholder="順番"
      value={dir}
      onChange={(dir: string) =>
        setOption({ dir: dir === '' ? undefined : (dir as 'asc' | 'desc') })
      }
      items={[
        { label: '降順', value: 'desc' },
        { label: '昇順', value: 'asc' }
      ]}
      isClearable={true}
    />
  );
};

export const ToLocale: React.FC<{
  toLocale: TableColumn['toLocale'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ toLocale, setOption }) => {
  return (
    <CheckboxField
      value={toLocale || false}
      onChange={(toLocale) => setOption({ toLocale })}
    />
  );
};

export const RowToLocale: React.FC<{
  toLocale: TableColumn['toLocale'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ toLocale, setColumn }) => {
  return (
    <CheckboxField
      label={settingLabels.toLocale.label}
      value={toLocale || false}
      onChange={(toLocale) =>
        setColumn((prev) => {
          if (prev == undefined) {
            return undefined;
          }
          return { ...prev, toLocale };
        })
      }
    />
  );
};

export const ToFixed: React.FC<{
  toFixed: TableColumn['toFixed'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ toFixed, setOption }) => {
  return (
    <NumberField
      placeholder="小数点第一位まで=1"
      value={toFixed}
      onChange={(toFixed) =>
        setOption({
          toFixed:
            toFixed != undefined && toFixed != '' ? Number(toFixed) : undefined
        })
      }
    />
  );
};

export const RowToFixed: React.FC<{
  toFixed: TableColumn['toFixed'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ toFixed, setColumn }) => {
  return (
    <NumberField
      label={settingLabels.toFixed.label}
      placeholder="小数点第一位まで=1"
      value={toFixed}
      onChange={(toFixed) =>
        setColumn((prev) => {
          if (prev == undefined) {
            return undefined;
          }
          return {
            ...prev,
            toFixed:
              toFixed != undefined && toFixed != ''
                ? Number(toFixed)
                : undefined
          };
        })
      }
    />
  );
};

export const BottomAggregateFuncSelectField: React.FC<
  {
    bottomAggregateFunc: TableColumn['bottomAggregateFunc'];
    setOption: (option: Omit<TableColumn, keyof Option>) => void;
  } & Partial<SelectFieldProps>
> = ({ bottomAggregateFunc, setOption, ...rest }) => {
  return (
    <SelectField
      {...rest}
      placeholder="関数を選択"
      value={bottomAggregateFunc}
      onChange={(bottomAggregateFunc: AggregateFunc) =>
        setOption({
          bottomAggregateFunc,
          showBottomAggregate: Boolean(bottomAggregateFunc)
        })
      }
      items={bottomAggregateMethod}
      isClearable={true}
    />
  );
};

export const BottomAggregateFunc: React.FC<{
  bottomAggregateFunc: TableColumn['bottomAggregateFunc'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ bottomAggregateFunc, setOption }) => {
  return (
    <BottomAggregateFuncSelectField
      bottomAggregateFunc={bottomAggregateFunc}
      setOption={setOption}
      menuPortalTarget={document.body}
    />
  );
};

export const RowBottomAggregateFunc: React.FC<{
  showBottomAggregate: TableColumn['showBottomAggregate'];
  bottomAggregateFunc: TableColumn['bottomAggregateFunc'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ showBottomAggregate, bottomAggregateFunc, setColumn }) => {
  return (
    <>
      <CheckboxField
        label={settingLabels.bottomAggregate.label}
        value={showBottomAggregate || false}
        onChange={(show) => {
          setColumn((prev) => {
            if (prev == undefined) {
              return;
            }
            return {
              ...prev,
              showBottomAggregate: show
            };
          });
        }}
      />
      {showBottomAggregate && (
        <WithLabel
          label={' '}
          component={
            <BottomAggregateFuncSelectField
              bottomAggregateFunc={bottomAggregateFunc}
              setOption={(option) => {
                setColumn((prev) => {
                  if (prev == undefined) {
                    return;
                  }
                  return { ...prev, ...option };
                });
              }}
            />
          }
        />
      )}
    </>
  );
};

export const ToPercentage: React.FC<{
  toPercentage: TableColumn['toPercentage'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ toPercentage, setOption }) => {
  return (
    <CheckboxField
      value={toPercentage || false}
      onChange={(toPercentage) => setOption({ toPercentage })}
    />
  );
};

export const RowToPercentage: React.FC<{
  toPercentage: TableColumn['toPercentage'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ toPercentage, setColumn }) => {
  return (
    <CheckboxField
      label={settingLabels.toPercentage.label}
      value={toPercentage || false}
      onChange={(toPercentage) =>
        setColumn((prev) => {
          if (prev == undefined) {
            return undefined;
          }
          return { ...prev, toPercentage };
        })
      }
    />
  );
};

export const RowShowProgress: React.FC<{
  colorLabel?: string;
  showProgress: TableColumn['showProgress'];
  progressColorPalette: TableColumn['progressColorPalette'];
  color: TableColumn['color'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ showProgress, progressColorPalette, color, setColumn }) => {
  return (
    <>
      <CheckboxField
        label={settingLabels.showProgress.label}
        value={showProgress || false}
        onChange={(showProgress) =>
          setColumn((prev) => {
            if (prev == undefined) {
              return undefined;
            }
            const newPalette = progressColorPalette ?? {
              type: CategoricalColorType.Set2
            };
            const newColor = color ?? getColors(newPalette)[0];
            return {
              ...prev,
              showProgress,
              showCellColor: showProgress ? false : prev.showCellColor,
              progressColorPalette: newPalette,
              color: newColor
            };
          })
        }
      />
      {showProgress && (
        <>
          <WithLabel
            label={settingLabels.progressColorPalette.label}
            component={
              <PaletteSelector
                palette={
                  progressColorPalette || {
                    type: CategoricalColorType.Set2
                  }
                }
                onChange={(progressColorPalette) =>
                  setColumn((prev) => {
                    if (prev == undefined) {
                      return undefined;
                    }
                    return {
                      ...prev,
                      progressColorPalette
                    };
                  })
                }
              />
            }
          />
          <WithLabel
            label={settingLabels.color.label}
            component={
              <PaletteColorSelector
                color={
                  color ||
                  getColors({
                    type: CategoricalColorType.Set2
                  })[0]
                }
                palette={
                  progressColorPalette || {
                    type: CategoricalColorType.Set2
                  }
                }
                onChange={(color) =>
                  setColumn((prev) => {
                    if (prev == undefined) {
                      return undefined;
                    }
                    return {
                      ...prev,
                      color
                    };
                  })
                }
              />
            }
          />
        </>
      )}
    </>
  );
};

export const ShowCellColor: React.FC<{
  palette: TableColumn['palette'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ palette, setOption }) => {
  return (
    <PaletteSelector
      palette={
        palette || {
          type: SequentialColorType.Orange,
          size: 5
        }
      }
      onChange={(plt) => setOption({ palette: plt })}
    />
  );
};

export const RowShowCellColor: React.FC<{
  showCellColor: TableColumn['showCellColor'];
  palette: TableColumn['palette'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ showCellColor, palette, setColumn }) => {
  return (
    <>
      <CheckboxField
        // label={'条件付き書式'}
        label={settingLabels.showCellColor.label}
        value={showCellColor || false}
        onChange={(showCellColor) => {
          setColumn((prev) => {
            if (prev == undefined) {
              return;
            }
            return {
              ...prev,
              showCellColor,
              showProgress: showCellColor ? false : prev.showProgress,
              palette: palette ?? defaultPalette
            };
          });
        }}
      />
      {showCellColor && (
        <WithLabel
          label={settingLabels.palette.label}
          component={
            <PaletteSelector
              palette={
                palette || {
                  type: SequentialColorType.Orange,
                  size: 5
                }
              }
              onChange={(plt) =>
                setColumn((prev) => {
                  if (prev == undefined) {
                    return;
                  }
                  return { ...prev, palette: plt };
                })
              }
            />
          }
        />
      )}
    </>
  );
};

export const RowSetTextColor: React.FC<{
  setTextColor: TableColumn['setTextColor'];
  textColorPalette: TableColumn['textColorPalette'];
  textColor: TableColumn['textColor'];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ setTextColor, textColorPalette, textColor, setColumn }) => {
  return (
    <>
      <CheckboxField
        label={settingLabels.setTextColor.label}
        // label={'文字色を変更する'}
        value={setTextColor || false}
        onChange={(setTextColor) =>
          setColumn((prev) => {
            if (prev == undefined) {
              return undefined;
            }
            const newPalette = textColorPalette ?? defaultPalette;
            const newColor = textColor ?? '#000000';
            return {
              ...prev,
              setTextColor,
              textColor: newColor,
              textColorPalette: newPalette
            };
          })
        }
      />
      {setTextColor && (
        <>
          <WithLabel
            label={settingLabels.textColorPalette.label}
            component={
              <PaletteSelector
                palette={
                  textColorPalette || {
                    type: SequentialColorType.Orange,
                    size: 5
                  }
                }
                onChange={(textColorPalette) =>
                  setColumn((prev) => {
                    if (prev == undefined) {
                      return undefined;
                    }
                    return {
                      ...prev,
                      textColorPalette
                    };
                  })
                }
              />
            }
          />
          <WithLabel
            label={settingLabels.textColor.label}
            component={
              <PaletteColorSelector
                color={textColor || '#000000'}
                palette={
                  textColorPalette || {
                    type: SequentialColorType.Orange,
                    size: 5
                  }
                }
                onChange={(textColor) =>
                  setColumn((prev) => {
                    if (prev == undefined) {
                      return undefined;
                    }
                    return {
                      ...prev,
                      textColor
                    };
                  })
                }
              />
            }
          />
        </>
      )}
    </>
  );
};

export const SetTextColor: React.FC<{
  textColorPalette: TableColumn['textColorPalette'];
  textColor: TableColumn['textColor'];
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ textColorPalette, textColor, setOption }) => {
  return (
    <div style={{ textAlign: 'right' }}>
      <PaletteSelector
        palette={
          textColorPalette || {
            type: SequentialColorType.Orange,
            size: 5
          }
        }
        onChange={(palette) =>
          setOption({
            textColorPalette: palette
          })
        }
      />
      <PaletteColorSelector
        color={textColor || '#000000'}
        palette={
          textColorPalette || {
            type: SequentialColorType.Orange,
            size: 5
          }
        }
        onChange={(color) => setOption({ textColor: color })}
      />
    </div>
  );
};

export const RowToFormat: React.FC<{
  toFormat: TableColumn['toFormat'];
  timeFormatType: TableColumn['timeFormatType'];
  timeFormat: TableColumn['timeFormat'];
  dtype: string;
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ toFormat, timeFormatType, timeFormat, dtype, setColumn }) => {
  return (
    <>
      <CheckboxField
        label={settingLabels.toFormat.label}
        value={toFormat || false}
        onChange={(toFormat) =>
          setColumn((prev) => {
            if (prev == undefined) {
              return undefined;
            }
            return {
              ...prev,
              toFormat
            };
          })
        }
      />
      {toFormat && (
        <>
          <SelectField
            label={settingLabels.timeFormatType.label}
            placeholder="表示形式"
            onChange={(timeFormatType) =>
              setColumn((prev) => {
                if (prev == undefined || dtype == undefined) {
                  return undefined;
                }
                return {
                  ...prev,
                  timeFormatType,
                  // timeFormatTypesのformatをtimeFormatに設定する
                  timeFormat: timeFormatTypes[dtype].find(
                    (row) => row.value === timeFormatType
                  )?.format
                };
              })
            }
            value={timeFormatType}
            items={timeFormatTypes[dtype]}
            isClearable={true}
          />
          {timeFormatType === 'custom' && (
            <StringField
              label={settingLabels.timeFormat.label}
              placeholder="フォーマットを入力"
              onChange={(timeFormat) =>
                setColumn((prev) => {
                  if (prev == undefined) {
                    return undefined;
                  }
                  return { ...prev, timeFormat };
                })
              }
              value={timeFormat}
            />
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(
  createStyles({
    input: {},
    text: {}
  })
);

export const ToFormat: React.FC<{
  timeFormatType: TableColumn['timeFormatType'];
  timeFormat: TableColumn['timeFormat'];
  dtype: string;
  setOption: (option: Omit<TableColumn, keyof Option>) => void;
}> = ({ timeFormatType, timeFormat, dtype, setOption }) => {
  const classes = useStyles();
  return (
    <>
      <SelectField
        placeholder="表示形式"
        onChange={(timeFormatType) => {
          if (dtype == undefined) {
            return undefined;
          }
          setOption({
            timeFormatType,
            // timeFormatTypesのformatをtimeFormatに設定する
            timeFormat: timeFormatTypes[dtype].find(
              (row) => row.value === timeFormatType
            )?.format
          });
        }}
        value={timeFormatType}
        items={timeFormatTypes[dtype]}
        isClearable={true}
      />
      {timeFormatType === 'custom' && (
        <InputField
          placeholder="フォーマットを入力"
          onChange={(timeFormat) => {
            if (dtype == undefined) {
              return undefined;
            }
            setOption({ timeFormat });
          }}
          value={timeFormat}
          className={classes.text}
        />
      )}
    </>
  );
};

const WithLabel: React.FC<{
  label?: string;
  labelStyle?: React.CSSProperties;
  componentStyle?: React.CSSProperties;
  component: React.ReactNode;
}> = ({ label, labelStyle, componentStyle, component }) => {
  return (
    <>
      {label ? (
        <Label
          text={label}
          labelStyle={labelStyle}
          componentStyle={componentStyle}
        >
          {component}
        </Label>
      ) : (
        component
      )}
    </>
  );
};

export const BulkShowProgress: React.FC<{
  palette: Palette;
  colors: { [columnName: string]: string };
  setOption: (option: {
    progressColorPalette: Palette;
    colors: { [columnName: string]: string };
  }) => void;
}> = ({ palette, colors, setOption }) => {
  return (
    <>
      <WithLabel
        label="カラーパレット指定"
        labelStyle={{ width: '70%' }}
        componentStyle={{ width: '30%' }}
        component={
          <PaletteSelector
            palette={palette}
            onChange={(palette) => {
              const defaultColors = getColors(palette);
              const length = defaultColors.length;
              const newColors = {};
              Object.keys(colors).map((colname, i) => {
                newColors[colname] = defaultColors[i % length];
              });
              setOption({
                colors: newColors,
                progressColorPalette: palette
              });
            }}
          />
        }
      />
      <div style={{ overflow: 'auto', maxHeight: '300px' }}>
        {Object.keys(colors).map((colname, i) => {
          return (
            <div key={i}>
              <WithLabel
                label={colname}
                labelStyle={{ width: '70%' }}
                componentStyle={{ width: '30%' }}
                component={
                  <PaletteColorSelector
                    color={colors[colname]}
                    palette={palette}
                    onChange={(color) =>
                      setOption({
                        progressColorPalette: palette,
                        colors: { ...colors, [colname]: color }
                      })
                    }
                  />
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const RowStringCellColor: React.FC<{
  option?: StringCellColorOption;
  columnValues: string[];
  setColumn: (value: React.SetStateAction<TableColumn | undefined>) => void;
}> = ({ option: _option, columnValues, setColumn }) => {
  const option = React.useMemo(() => {
    if (_option != undefined) {
      return _option;
    }

    // デフォルト設定
    const defaultPalette = {
      type: CategoricalColorType.Set2
    };
    const defaultColors = getColors(defaultPalette);
    const length = defaultColors.length;
    const colors = {};
    columnValues.forEach((value, i) => {
      colors[value] = defaultColors[i % length];
    });
    return {
      show: false,
      palette: defaultPalette,
      colors
    };
  }, [_option, columnValues]);

  const colorsList = React.useMemo(() => {
    return Object.keys(option.colors).map((value) => {
      return {
        value,
        color: option.colors[value]
      };
    });
  }, [option]);

  const row = ({ index, style }) => {
    const row = colorsList[index];
    return (
      <div style={style}>
        <WithLabel
          label={row.value}
          labelStyle={{ width: '70%' }}
          componentStyle={{ width: '30%' }}
          component={
            <PaletteColorSelector
              color={row.color}
              palette={option.palette}
              onChange={(color) =>
                setColumn((prev) => {
                  if (prev == undefined) return;
                  return {
                    ...prev,
                    stringCellColor: {
                      ...option,
                      colors: {
                        ...option.colors,
                        [row.value]: color
                      }
                    }
                  };
                })
              }
            />
          }
        />
      </div>
    );
  };

  return (
    <>
      <CheckboxField
        label={settingLabels.showCellColor.label}
        value={option.show}
        onChange={(showCellColor) =>
          setColumn((prev) => {
            if (prev == undefined) return;
            return {
              ...prev,
              stringCellColor: { ...option, show: showCellColor }
            };
          })
        }
      />
      {option.show && (
        <>
          <WithLabel
            label="カラーパレット指定"
            labelStyle={{ width: '70%' }}
            componentStyle={{ width: '30%' }}
            component={
              <PaletteSelector
                palette={option.palette}
                onChange={(palette) => {
                  const defaultColors = getColors(palette);
                  const length = defaultColors.length;
                  const newColors = {};
                  Object.keys(option.colors).map((colname, i) => {
                    newColors[colname] = defaultColors[i % length];
                  });
                  setColumn((prev) => {
                    if (prev == undefined) return;
                    return {
                      ...prev,
                      stringCellColor: { ...option, colors: newColors, palette }
                    };
                  });
                }}
              />
            }
          />
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                width={width}
                height={300}
                itemCount={colorsList.length}
                itemSize={60}
              >
                {row}
              </List>
            )}
          </AutoSizer>
        </>
      )}
    </>
  );
};

export const BulkStringCellColor: React.FC<{
  title: string;
  palette: StringCellColorOption['palette'];
  colors: StringCellColorOption['colors'];
  setOption: (option: StringCellColorOption) => void;
}> = ({ title, palette, colors, setOption }) => {
  const colorsList = Object.keys(colors).map((value) => {
    return {
      value,
      color: colors[value]
    };
  });
  const row = ({ index, style }) => {
    const row = colorsList[index];
    return (
      <div style={style}>
        <WithLabel
          label={row.value}
          labelStyle={{ width: '70%' }}
          componentStyle={{ width: '30%' }}
          component={
            <PaletteColorSelector
              color={row.color}
              palette={palette}
              onChange={(color) =>
                setOption({
                  show: true,
                  palette,
                  colors: { ...colors, [row.value]: color }
                })
              }
            />
          }
        />
      </div>
    );
  };

  return (
    <>
      <Typography
        variant="h6"
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        {`列: ${title}`}
      </Typography>
      <WithLabel
        label="カラーパレット指定"
        labelStyle={{ width: '70%' }}
        componentStyle={{ width: '30%' }}
        component={
          <PaletteSelector
            palette={palette}
            onChange={(palette) => {
              const defaultColors = getColors(palette);
              const length = defaultColors.length;
              const newColors = {};
              Object.keys(colors).map((colname, i) => {
                newColors[colname] = defaultColors[i % length];
              });
              setOption({
                show: true,
                colors: newColors,
                palette
              });
            }}
          />
        }
      />
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            height={300}
            itemCount={colorsList.length}
            itemSize={60}
          >
            {row}
          </List>
        )}
      </AutoSizer>
    </>
  );
};
