import * as React from 'react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Button, ButtonProps, SvgIconProps } from '@mui/material';

export const NavButton: React.FC<
  ButtonProps & {
    arrowDirection?: 'right' | 'left' | 'none';
  }
> = ({ arrowDirection = 'none', children, ...props }) => {
  return (
    <Button
      data-cy={
        typeof children === 'string' && props['data-cy'] == undefined
          ? children
          : props['data-cy']
      }
      {...props}
      sx={{ height: 36, borderRadius: '3px', fontWeight: 'bold' }}
    >
      {arrowDirection === 'right' && (
        <>
          {children}
          <NavigateNext style={{ marginRight: -5 }} color="inherit" />
        </>
      )}
      {arrowDirection === 'left' && (
        <>
          <NavigateBefore style={{ marginLeft: -5 }} color="inherit" />
          {children}
        </>
      )}
      {arrowDirection === 'none' && children}
    </Button>
  );
};

export const NavArrow: React.FC<
  SvgIconProps & {
    arrowDirection?: 'right' | 'left';
  }
> = ({ arrowDirection = 'right', ...props }) => {
  const Icon = arrowDirection === 'right' ? NavigateNext : NavigateBefore;
  return <Icon {...props} />;
};
