import * as React from 'react';
import clsx from 'clsx';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ParameterType,
  RangeNumberParameter as RangeNumberParameterType,
  SingleNumberParameter as SingleNumberParameterType
} from 'models/report';
import { getDispatch, ReportAction } from '../../../hooks/reportReducer';
import { Option } from 'models/chart';
import { ListSelect } from 'components/report/parameter/listSelect';
import { color } from '../../../theme';
import { useDialog, ParameterDialog } from './parameterDialog';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: 300,
    color
  },
  title: {
    margin: 10,
    fontWeight: 700
  },
  color: {
    color
  },
  condition: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
});

const numberOperators: Option[] = [
  { label: '一致', value: '==' },
  { label: '以上', value: '>=' },
  { label: 'より大きい', value: '>' },
  { label: '以下', value: '<=' },
  { label: '未満', value: '<' }
];

const NumberOperator: React.FC<{
  operator: string;
  onChange: (operator: string) => void;
}> = ({ operator, onChange }) => {
  return (
    <ListSelect
      value={operator}
      label="演算子"
      onChange={onChange}
      options={numberOperators}
    />
  );
};

export const NumberInput: React.FC<
  {
    width?: number;
    label: string;
    value?: string | number;
    isInteger?: boolean;
    onChange: (value?: string) => void;
  } & Omit<TextFieldProps, 'onChange' | 'value'>
> = ({ width, label, value, onChange, isInteger, ...props }) => {
  const setNumber = React.useCallback(
    (e) => {
      if (e.target.value === '') {
        onChange(undefined);
      }
      const re = /^([0-9]+)\.?([0-9]+)?$/;
      if (re.test(e.target.value)) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );
  return (
    <TextField
      style={{ width: width || 120 }}
      size={'small'}
      margin={'dense'}
      label={label}
      value={value ?? ''}
      onChange={setNumber}
      inputProps={{
        pattern: isInteger ? 'd*' : undefined,
        style: { color }
      }}
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
      {...props}
    />
  );
};

const SingleNumberParameter: React.FC<{
  param: SingleNumberParameterType;
  onClose: () => void;
  execute: () => void;
}> = ({ param, execute }) => {
  const classes = useStyles();
  const dispatch = getDispatch();
  const dialogProps = useDialog();
  const [state, setState] = React.useState(param);
  return (
    <ParameterDialog
      {...dialogProps}
      title={param.name}
      onClickOK={() => {
        const { value, operator } = state;
        dispatch({
          type: ReportAction.changeParameterValue,
          payload: { uuid: param.uuid, values: { value, operator } }
        });
        execute();
        dialogProps.onClose();
      }}
      onClose={() => {
        setState(param);
        dialogProps.onClose();
      }}
      content={
        <div className={classes.paper}>
          <Typography
            className={clsx(classes.title, classes.color)}
            variant="body1"
          >
            数値条件を設定
          </Typography>
          <div className={classes.condition}>
            <NumberInput
              value={state.value}
              label={'値'}
              onChange={(value) => setState((prev) => ({ ...prev, value }))}
            />
            <NumberOperator
              operator={state.operator}
              onChange={(operator) =>
                setState((prev) => ({ ...prev, operator }))
              }
            />
          </div>
        </div>
      }
    />
  );
};

const RangeNumberParameter: React.FC<{
  param: RangeNumberParameterType;
  onClose: () => void;
  execute: () => void;
}> = ({ param, execute }) => {
  const classes = useStyles();
  const dispatch = getDispatch();
  const dialogProps = useDialog();
  const [state, setState] = React.useState(param);
  return (
    <ParameterDialog
      {...dialogProps}
      title={param.name}
      onClickOK={() => {
        const { min, max } = state;
        dispatch({
          type: ReportAction.changeParameterValue,
          payload: { uuid: param.uuid, values: { min, max } }
        });
        execute();
        dialogProps.onClose();
      }}
      onClose={() => {
        setState(param);
        dialogProps.onClose();
      }}
      content={
        <div className={classes.paper}>
          <Typography
            className={clsx(classes.title, classes.color)}
            variant="body1"
          >
            数値の範囲を設定
          </Typography>
          <div className={classes.condition}>
            <NumberInput
              value={state.min}
              onChange={(value) =>
                setState((prev) => ({ ...prev, min: value }))
              }
              label={'最小値'}
            />
            <Typography className={classes.color} variant={'h3'}>
              ~
            </Typography>
            <NumberInput
              value={state.max}
              onChange={(value) =>
                setState((prev) => ({ ...prev, max: value }))
              }
              label={'最大値'}
            />
          </div>
        </div>
      }
    />
  );
};

export const NumberParameter: React.FC<{
  param: SingleNumberParameterType | RangeNumberParameterType;
  onClose: () => void;
  execute: () => void;
}> = ({ param, ...rest }) => {
  switch (param.type) {
    case ParameterType.Single:
      return <SingleNumberParameter param={param} {...rest} />;
    case ParameterType.Range:
      return <RangeNumberParameter param={param} {...rest} />;
  }
};
