import * as React from 'react';
import { Grid } from '@mui/material';

import MainLayout from 'components/layouts/main';
import { AuthContext } from 'components/route';

const InfoPage: React.FC = () => {
  const { user } = React.useContext(AuthContext);

  const url = user!.isTrial
    ? 'https://octopus.nehan.io/support_contents/trial/'
    : 'https://nehan.io/latest-release';

  return (
    <MainLayout selectedMenu="info">
      <Grid container={true} justifyContent="center">
        <iframe
          style={{
            border: 'none',
            width: '100%',
            height: 'calc(100vh - 64px)'
          }}
          src={url}
        />
      </Grid>
    </MainLayout>
  );
};

export default InfoPage;
