import { AccessLevel } from 'libs/accessLevel';
import { Membership } from 'models/user';
import { UserGroup } from 'models/user_group';

type UserAccessLevel = {
  userId: string;
  accessLevel: AccessLevel;
};

export class MemberShips {
  maxAccessLevels: UserAccessLevel[];

  constructor(memberships: Membership[], userGroups: UserGroup[]) {
    // ユーザーのアクセスレベルを取得
    const userAccessLevels = memberships.flatMap(
      (membership): UserAccessLevel[] => {
        if (membership.entity_type === 'u') {
          return [
            { userId: membership.user.id, accessLevel: membership.access_level }
          ];
        }
        if (membership.entity_type === 'g') {
          const userGroup = userGroups.find(
            (userGroup) => userGroup.id === membership.user_group.id
          );
          if (userGroup && userGroup.users) {
            return userGroup.users.map((user) => {
              return {
                userId: user.id,
                accessLevel: membership.access_level
              };
            });
          }
          return [];
        }
        return [];
      }
    );

    // ユーザーごとの最大のアクセスレベルを取得
    const groupedByUserId = Object.groupBy(
      userAccessLevels,
      (userAccessLevel) => userAccessLevel.userId
    );
    const maxAccessLevels: UserAccessLevel[] = Object.entries(
      groupedByUserId
    ).map(([userId, accesses]) => ({
      userId,
      accessLevel: Math.max(
        ...(accesses || [AccessLevel.Disabled]).map(
          (access) => access.accessLevel
        )
      )
    }));
    this.maxAccessLevels = maxAccessLevels;
  }

  // ユーザーごとの最大のアクセスレベルでカウント
  memberCount(accessLevel: AccessLevel): number {
    return this.maxAccessLevels.filter((userAccessLevel) => {
      if (accessLevel === AccessLevel.Admin) {
        return userAccessLevel.accessLevel >= accessLevel;
      }
      return userAccessLevel.accessLevel === accessLevel;
    }).length;
  }
}
