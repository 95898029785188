import * as React from 'react';
import {
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  DialogTitle as MuiDialogTitle,
  Tooltip,
  TooltipProps,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBackIosOutlined, Close } from '@mui/icons-material';
import clsx from 'clsx';
import { Spacer } from '../spacer';
import { HelpButton, HelpButtonProps } from 'components/diagram/ui/HelpDialog';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: '#525252',
    backgroundColor: '#f0f0f0',
    fontSize: 14,
    fontWeight: 'bold',
    height: 58,
    padding: '0 0 0 24px',
    gap: 12
  },
  showBack: {
    padding: 0
  },
  closeButton: {
    color: theme.palette.grey[500]
  }
}));

export type DialogTitleProps = MuiDialogTitleProps & {
  title?: string;
  onClickBack?: () => void;
  onClickClose: () => void;
  helpButtonProps?: HelpButtonProps;
  backTooltipProps?: Partial<TooltipProps>;
};

export const DialogTitle: React.FC<DialogTitleProps> = ({
  title,
  onClickBack,
  onClickClose,
  backTooltipProps,
  helpButtonProps,
  ...props
}) => {
  const classes = styles();

  return (
    <MuiDialogTitle
      className={clsx(classes.root, {
        [classes.showBack]: onClickBack
      })}
      {...props}
    >
      {props.children || (
        <>
          {onClickBack && (
            <Tooltip title="戻る" {...backTooltipProps}>
              <IconButton onClick={onClickBack} size="large">
                <ArrowBackIosOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Typography color="inherit" variant="inherit">
            {title}
          </Typography>
          {helpButtonProps && <HelpButton {...helpButtonProps} />}
          <Spacer />
          <IconButton
            className={classes.closeButton}
            onClick={onClickClose}
            size="large"
          >
            <Close />
          </IconButton>
        </>
      )}
    </MuiDialogTitle>
  );
};
