import * as React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  IconButton,
  Link as MuiLink,
  TableCell,
  Tooltip
} from '@mui/material';
import { getDatasourceExpression } from 'libs/datasources';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowForward,
  CheckCircle,
  Error,
  Group,
  GroupAdd,
  MoreHoriz,
  RadioButtonUnchecked,
  RemoveCircle
} from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  createLink,
  DatasourceStatusColor,
  DatasourceStatusText
} from 'reducers/datasource';
import { NodeStatus, NodeStatusColor, NodeStatusText } from 'models/graph';
import { AccessLevel } from 'libs/accessLevel';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from 'components/route';
import {
  BaseItem,
  DatasourceItem,
  ListItem,
  ListItemType,
  OrganizationItem,
  ProjectItem,
  ScheduleItem
} from 'models/dependency';
import { SparkIcon } from 'components/icons/nodes/SparkIcon';
import {
  ProjectStatus,
  ProjectStatusColor,
  ProjectStatusText
} from 'models/project';
import { DatasourceStatus } from 'models/datasource';
import { ConfigStatus, ScheduleHistoryStatus } from 'models/schedule';
import { color, nehanColor } from '../../theme';
import { getSchedulingConfigLabel } from 'components/schedule/schedulingConfigButton';
import clsx from 'clsx';
import { Switch } from 'ui/common/switch';
import { MoveErrorIcon } from 'components/schedule/history';
import { destinations } from 'models/notificationDst/base';

export const ConnectionCell: React.FC<{ type?: string; spark?: boolean }> = ({
  type,
  spark
}) => {
  if (type == undefined) {
    return <TableCell>未設定</TableCell>;
  }
  const expr = getDatasourceExpression(type);
  return (
    <TableCell>
      {expr.logoPath && (
        <img
          alt={type}
          src={expr.logoPath}
          width="20"
          style={{ verticalAlign: 'bottom', marginRight: 5 }}
        />
      )}
      {expr.name}
      {spark && (
        <>
          &nbsp;
          <SparkIcon color="white" style="datasource" />
        </>
      )}
    </TableCell>
  );
};

export const NotificationDstCell: React.FC<{ type: string }> = ({ type }) => {
  const dst = destinations.find((dst) => dst.type === type);
  if (!dst) {
    return <TableCell>未設定</TableCell>;
  }
  return (
    <TableCell>
      {typeof dst.logo === 'string' && (
        <img
          alt={type}
          src={dst.logo}
          width="20"
          style={{ verticalAlign: 'bottom', marginRight: 5 }}
        />
      )}
      {React.isValidElement(dst.logo) && (
        <div
          style={{
            width: '20px',
            verticalAlign: 'bottom',
            marginRight: 5,
            display: 'inline-flex'
          }}
        >
          {dst.logo}
        </div>
      )}
      {dst.name}
    </TableCell>
  );
};

export function formatTimestamp(text?: string): string {
  return text != undefined ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-';
}

export const TimestampCell: React.FC<{ time?: string }> = ({ time }) => {
  const text = formatTimestamp(time);
  return <TableCell>{text}</TableCell>;
};
const linkCellStyle = makeStyles({
  iconSpan: {
    verticalAlign: 'middle'
  }
});
export type OpenShareDialogType = (
  id: string | number,
  shareEditable: boolean,
  title: string
) => void;
export const LinkCell: React.FC<{
  href: string;
  name: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  shareProps?: {
    enableShare?: boolean;
    isTrial?: boolean;
    onOpenShareDialog?: OpenShareDialogType;
    id: string;
    accessLevel: AccessLevel;
  };
}> = ({ icon, href, name, onClick, shareProps }) => {
  const classes = linkCellStyle();
  const showTooltip =
    shareProps != undefined && shareProps.enableShare && !shareProps.isTrial;
  const onClickHandler = React.useCallback(
    (ev) => {
      ev.preventDefault();
      onClick && onClick();
    },
    [onClick]
  );

  return (
    (<TableCell
        padding={showTooltip ? 'none' : undefined}
        data-cy={`table-link-cell-${name}`}
      >
      {icon && <span className={classes.iconSpan}>{icon}&nbsp;</span>}
      {!onClick && <Link to={href}>{name}</Link>}
      {onClick && (
        <MuiLink href="#" onClick={onClickHandler} underline="hover">
          {name}
        </MuiLink>
      )}
      {shareProps && showTooltip && (
        <Tooltip title="共有設定">
          <IconButton
            onClick={() =>
              shareProps.onOpenShareDialog &&
              shareProps.onOpenShareDialog(
                shareProps.id,
                shareProps.accessLevel >= AccessLevel.Admin,
                name
              )
            }
            size="large">
            <GroupAdd />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>)
  );
};
export const LinkButtonCell: React.FC<{
  name: string;
  uuid: string;
  onClick: (name: string, uuid: string) => void;
}> = ({ name, uuid, onClick }) => {
  return (
    (<TableCell>
      <MuiLink onClick={() => onClick(name, uuid)} underline="hover">{name}</MuiLink>
    </TableCell>)
  );
};
export const LinkResource: React.FC<{
  type: ListItemType;
  item: BaseItem;
  onClickFolder: (fid: string) => void;
}> = ({ type, item, onClickFolder }) => {
  if (item.is_folder) {
    return (
      (<MuiLink
        style={{
          cursor: 'pointer',
          fontWeight: 'bold'
        }}
        key={item.uuid}
        onClick={() => onClickFolder(item.uuid)}
        underline="hover">
        {item.name}
      </MuiLink>)
    );
  }
  switch (type) {
    case 'builders':
      return (
        <Link to={`/builders/${item.uuid}`} style={{ fontWeight: 'bold' }}>
          {item.name}
        </Link>
      );
    case 'datasources':
      const dsItem = item as DatasourceItem;
      return (
        <Link
          to={createLink(dsItem.type, dsItem.id)}
          style={{ fontWeight: 'bold' }}
        >
          {item.name}
        </Link>
      );
    case 'projects':
      return (
        <Link to={`/projects/${item.uuid}`} style={{ fontWeight: 'bold' }}>
          {item.name}
        </Link>
      );
    case 'reports':
      return (
        <Link to={`/reports/${item.uuid}`} style={{ fontWeight: 'bold' }}>
          {item.name}
        </Link>
      );
    case 'variables':
      const isAdmin = location.pathname.startsWith('/admin');
      return (
        <Link
          to={`${isAdmin ? '/admin' : ''}/variables/${item.uuid}`}
          style={{ fontWeight: 'bold' }}
        >
          {item.name}
        </Link>
      );
    case 'notifications':
      return (
        <Link to={`/notifications/${item.uuid}`} style={{ fontWeight: 'bold' }}>
          {item.name}
        </Link>
      );
    default:
      return <></>;
  }
};
export type OpenMenuType = (
  event: React.MouseEvent<HTMLElement>,
  id: string | number,
  name: string
) => void;

export const ShareDialogCell: React.FC<{
  uuid: string | number;
  name: string;
  accessLevel: number;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  disableMenu?: boolean;
  disableShare?: boolean;
}> = ({
  uuid,
  name,
  accessLevel,
  onOpenShareDialog,
  onOpenMenu,
  disableMenu,
  disableShare
}) => {
  const { user } = React.useContext(AuthContext);
  if (!disableShare && onOpenShareDialog == undefined) {
    return null;
  }
  const shareEditable = accessLevel >= AccessLevel.Admin;
  return (
    <TableCell padding="none">
      {!disableShare && (
        <Tooltip title="共有">
          <span>
            <IconButton
              size="small"
              disabled={user.isTrial}
              onClick={() =>
                onOpenShareDialog &&
                onOpenShareDialog(uuid, shareEditable, name)
              }
            >
              {accessLevel ? (
                <GroupAdd fontSize="small" />
              ) : (
                <Group fontSize="small" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      )}
      <IconButton
        disabled={disableMenu}
        data-cy="listview-row-menu"
        size="small"
        onClick={(event) => {
          onOpenMenu(event, uuid, name);
        }}
      >
        <MoreHoriz />
      </IconButton>
    </TableCell>
  );
};
export const ProjectStatusCell: React.FC<{ item: ProjectItem }> = ({
  item
}) => {
  const style = React.useMemo(() => {
    return {
      color: ProjectStatusColor(item.status),
      display: 'inline-flex',
      alignItems: 'center'
    };
  }, [item.status]);
  return (
    <TableCell>
      <span style={style}>
        {ProjectStatusText(item.status)}
        {(item.status === ProjectStatus.ERROR ||
          item.status === ProjectStatus.WARNING) && (
          <ProjectMoveErrorIcon
            projectUUID={item.uuid}
            error={`${
              item.status === ProjectStatus.ERROR ? 'エラー' : '警告'
            }ノードが${item.status_nodes}個あります`}
            errorNodeId={item.status_node_id}
          />
        )}
      </span>
    </TableCell>
  );
};

export const DatasourceStatusCell: React.FC<{ item: DatasourceItem }> = ({
  item
}) => {
  const style = React.useMemo(() => {
    return {
      color: DatasourceStatusColor(item.status),
      display: 'inline-flex',
      alignItems: 'center'
    };
  }, [item.status]);
  return (
    <TableCell>
      <span style={style}>
        {DatasourceStatusText(item.status)}
        &nbsp;
        {item.status === DatasourceStatus.Failed && (
          <Tooltip title={item.error}>
            <Error color="error" />
          </Tooltip>
        )}
      </span>
    </TableCell>
  );
};

export const NodeStatusCell: React.FC<{
  project_id: string;
  node_id: string;
  error?: string;
  status: NodeStatus;
}> = ({ project_id, error, node_id, status }) => {
  return (
    <TableCell>
      <span
        style={{
          color: NodeStatusColor(status),
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {NodeStatusText(status)}
        {[NodeStatus.Error, NodeStatus.Warning].includes(status) && (
          <ProjectMoveErrorIcon
            projectUUID={project_id}
            error={error || ''}
            errorNodeId={node_id}
          />
        )}
      </span>
    </TableCell>
  );
};

function getScheduleStatusStyle(status?: ScheduleHistoryStatus): {
  color: string;
  text: string;
} {
  switch (status) {
    case ScheduleHistoryStatus.Pending:
      return {
        color: '#8bc34a',
        text: '待機中'
      };
    case ScheduleHistoryStatus.Running:
      return {
        color: '#ff8a65',
        text: '実行中'
      };
    case ScheduleHistoryStatus.Success:
      return {
        color: '#2196f3',
        text: '成功'
      };
    case ScheduleHistoryStatus.Failed:
      return {
        color: '#f44336',
        text: '失敗'
      };
    case ScheduleHistoryStatus.Warning:
      return {
        color: '#ff9800',
        text: '警告'
      };
    default:
      return {
        color: color,
        text: '-'
      };
  }
}

function getScheduleConfigStatusStyle(status?: ConfigStatus): {
  color: string;
  text: string;
} {
  switch (status) {
    case ConfigStatus.Warning:
      return {
        color: '#ff8a65',
        text: '警告'
      };
    case ConfigStatus.OK:
      return {
        color: '#2196f3',
        text: 'OK'
      };
    case ConfigStatus.Error:
      return {
        color: '#f44336',
        text: 'エラー'
      };
    default:
      return {
        color: color,
        text: '-'
      };
  }
}

export const ScheduleStatusCell: React.FC<{ item: ScheduleItem }> = ({
  item
}) => {
  const style = getScheduleStatusStyle(item.status);
  return (
    <TableCell
      style={{
        color: style.color
      }}
    >
      {style.text}
      {item.status === ScheduleHistoryStatus.Failed && (
        <MoveErrorIcon history={item} />
      )}
    </TableCell>
  );
};

export const ScheduleConfigStatusCell: React.FC<{ item: ScheduleItem }> = ({
  item
}) => {
  const style = getScheduleConfigStatusStyle(item.config_status);
  return (
    <TableCell
      style={{
        color: style.color
      }}
    >
      {style.text}
    </TableCell>
  );
};

const configStyles = makeStyles({
  root: {
    color: `${nehanColor} !important`,
    fontWeight: '600 !important' as any,
    padding: 0
  },
  disabled: {
    color: 'gray !important'
  },
  button: {
    color: nehanColor
  }
});

export const ScheduleConfigCell: React.FC<{
  item: ScheduleItem;
  onChange: (id: string, enabled: boolean) => void;
  disabled?: boolean;
}> = ({ item, onChange, disabled }) => {
  const classes = configStyles();
  const config = item.scheduling_config;
  return (
    <TableCell
      className={clsx(classes.root, !config.enabled && classes.disabled)}
    >
      <Tooltip title={disabled ? '編集権限がありません' : ''}>
        <span>
          <Switch
            disabled={config.timing === 'none' || disabled}
            checked={config.enabled && config.timing !== 'none'}
            onChange={(_, checked) => onChange(item.uuid, checked)}
          />
        </span>
      </Tooltip>
      {getSchedulingConfigLabel(config)}
    </TableCell>
  );
};

const checkboxStyle = makeStyles({
  checkbox: {
    color: '#344955',
    padding: 4
  },
  unchecked: {
    background: `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%23f0f0f0" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>') no-repeat center center`,
    backgroundSize: 14
  }
});
export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const classes = checkboxStyle();
  return (
    <MuiCheckbox
      {...props}
      color="default"
      className={classes.checkbox}
      size="small"
      icon={<RadioButtonUnchecked className={classes.unchecked} />}
      indeterminateIcon={<RemoveCircle />}
      checkedIcon={<CheckCircle />}
    />
  );
};

export function getItemID(type: ListItemType, item: ListItem): string {
  if (item.is_folder) {
    return item.uuid;
  }

  switch (type) {
    case 'datasources': {
      const ds = item as DatasourceItem;
      return `${ds.id}`;
    }
    case 'organizations': {
      const itm = item as OrganizationItem;
      return `${itm.id}`;
    }
    default:
      return item.uuid;
  }
}

const ProjectMoveErrorIcon: React.FC<{
  projectUUID: string;
  error: string;
  errorNodeId: string;
  onClick?: () => void;
}> = ({ projectUUID, error, errorNodeId, onClick }) => {
  const history = useHistory();
  const focusNode = React.useCallback(
    (projectId: string, nodeId: string, callback?: () => void) => {
      history.push(`/projects/${projectId}/nodes/${nodeId}`);
      if (callback) {
        callback();
      }
    },
    []
  );
  return (
    (<Tooltip title={error}>
      {errorNodeId === '' ? (
        <Error color="error" />
      ) : (
        <IconButton
          onClick={() => focusNode(projectUUID, errorNodeId, onClick)}
          style={{ padding: 0 }}
          size="large">
          <ArrowForward />
        </IconButton>
      )}
    </Tooltip>)
  );
};

export const typeToText: { [type: string]: string } = {
  projects: '分析プロジェクト',
  connections: '接続情報',
  datasources: 'データソース',
  dashboards: '旧ダッシュボード',
  reports: 'ダッシュボード',
  builders: 'SQL ビルダー',
  exports: 'データエクスポート',
  visualize_selector: '分析プロジェクト',
  report_selector: 'ダッシュボード',
  creator: '作成者',
  folder_items: 'フォルダ内のオブジェクト',
  variables: '共通変数',
  schedules: 'スケジュール',
  notification_dsts: '通知先',
  notifications: '通知',
  user_groups: 'グループ',
  organizations: '組織'
};
