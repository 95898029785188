import * as React from 'react';
import { SvgIcon } from '@mui/material';

export function SvgIcProjectHyper(props) {
  return (
    <SvgIcon viewBox="11 11 58 58" {...props}>
      <g fill="currentColor">
        <path d="M54.169 48.915c-.652.626-1.178.623-1.602.252.854-2.044 1.684-5.003.815-7.563-.636-1.872-2.04-3.225-4.174-4.021l-.592-.221s4.487-.474 5.824 2.972c.816 2.1 2.393-.551 3.644.75.458.478-1.063 5.09-3.915 7.83zm-3.663 5.35c-.325 2.463-1.691 8.685-4.348 11.136a4.644 4.644 0 01-.176-1.147c-.001-.413.018-.976.04-1.615.053-1.612.12-3.62-.045-5.193l-.016-.15-.054-.142c-.162-.425-1.394-3.623-2.56-5.706h.05c2.423 0 6.699.728 6.742.735l.766.13-.4 1.953zm-17.72-7.85s3.876-3.698 4.529-3.437a2.767 2.767 0 011.033.732l-5.562 2.705zm-6.584.34c.059-3.13 5.95-8.987 7.207-7.937 1.256 1.052-.181 2.94-1.246 4.59-1.064 1.65-5.993 5.089-5.961 3.347zm21.34-23.836l1.918-8.75-.02-.13a1.226 1.226 0 00-1.78-.897l-.401.21L36.94 24.357s-5.852 1.967-9.076 4.005c-3.222 2.039-5.91 4.813-5.91 4.813-8.563 10.194-5.457 19.212-5.457 19.212 1.678 3.269 9.142.108 14.707-3.694l6.75-3.265 1.61-.934c2.559.489 10.835 1.95 11.272.506.396-1.314-3.165-3.358-4.843-4.238l2.809-2.092c7.518 2.805 1.53 12.369 1.53 12.369s-9.865-1.69-8.61 0c1.256 1.689 3.1 6.528 3.1 6.528.223 2.129-.006 5.266 0 6.692.006 1.425.762 2.924.762 2.924 4.683-2.175 6.07-12.766 6.07-12.766 5.868-3.952 10.079-13.05 10.079-13.05L62.179 40c3.047-9.376-4.905-18.655-14.637-17.081zM21.956 19.638l4.989.821c.832.138.832 1.334 0 1.472l-4.989.821a.745.745 0 00-.614.615l-.822 4.99c-.137.831-1.334.831-1.471 0l-.822-4.99a.743.743 0 00-.614-.615l-4.989-.82c-.832-.139-.832-1.335 0-1.473l4.989-.82a.743.743 0 00.614-.615l.822-4.99c.137-.832 1.334-.832 1.471 0l.822 4.99a.745.745 0 00.614.614" />
        <path d="M35.531 57.173l4.01.66c.668.111.668 1.072 0 1.182l-4.01.661a.598.598 0 00-.494.493l-.66 4.01c-.11.668-1.073.668-1.183 0l-.66-4.01a.598.598 0 00-.493-.493l-4.01-.66c-.668-.11-.668-1.072 0-1.183l4.01-.66a.599.599 0 00.493-.494l.66-4.009c.11-.669 1.072-.669 1.183 0l.66 4.01c.042.253.24.451.494.493M64.136 20.092l3.38.557c.563.092.563.903 0 .996l-3.38.556a.508.508 0 00-.416.416l-.557 3.38c-.092.564-.903.564-.996 0l-.556-3.38a.51.51 0 00-.417-.416l-3.38-.556c-.563-.093-.563-.904 0-.996l3.38-.557a.508.508 0 00.417-.416l.556-3.38c.093-.563.904-.563.996 0l.557 3.38a.506.506 0 00.416.416M23.947 60.408l2.34.386c.39.063.39.625 0 .689l-2.34.385a.35.35 0 00-.288.289l-.386 2.339c-.064.39-.625.39-.689 0l-.386-2.34a.35.35 0 00-.288-.288l-2.339-.385c-.39-.064-.39-.626 0-.69l2.34-.385a.35.35 0 00.287-.288l.386-2.34c.064-.39.625-.39.69 0l.385 2.34a.35.35 0 00.288.288M32.918 16.258l2.61.431c.436.071.436.698 0 .77l-2.61.43a.39.39 0 00-.321.32l-.43 2.611c-.072.435-.698.435-.77 0l-.43-2.61a.39.39 0 00-.321-.322l-2.611-.43c-.435-.07-.435-.698 0-.769l2.61-.43a.389.389 0 00.322-.322l.43-2.61c.072-.436.698-.436.77 0l.43 2.61a.387.387 0 00.32.321" />
      </g>
    </SvgIcon>
  );
}
