import { _delete, _get, _post, _put } from 'libs/api/base';
import { ListItemType } from 'models/dependency';
import { Path, getFolderType } from 'models/folder';

export function addFolder(type: string, name: string, parent_id: string) {
  return _post<{ uuid: string }>('/folders', { type, name, parent_id });
}

export function updateFolder(id: string, name: string) {
  return _put(`/folders/${id}/name`, { name });
}

export function deleteFolder(id: string, type: string) {
  return _delete<string>(`/folders/${id}`, { type });
}

export function moveFolderItems(
  targets: { id: string; type: string }[],
  folder_id: string
) {
  return _put(`/folders/_move`, { targets, folder_id });
}

export function moveFolderItemsOnExport(id: string, folder_id: string) {
  return _put(`/folders/_move_on_export`, { id, folder_id });
}

export function getFolderMembers(id: string) {
  return _get(`/folders/${id}/members`);
}

export async function deleteFolderMember(
  fid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/folders/${fid}/members`, { id, is_group });
}

export function getFolderPath(resourceId: string, listItemType: ListItemType) {
  return _post<Path[]>(`/folder_path`, {
    resourceId,
    resourceType: getFolderType(listItemType)
  });
}

export function getFolderPathByFolderId(folderId: string) {
  return _post<Path[]>(`/folder_path_by_folder_id`, {
    folderId
  });
}
