import * as React from 'react';
import styled from '@emotion/styled';

import {
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
  Toolbar
} from '@mui/material';

import { UserRole } from 'libs/accessLevel';
import {
  Camera,
  SettingsInputComponent,
  Storage,
  TextFormat
} from '@mui/icons-material';
import SvgIcSqlBuilder from 'components/icons/IcSqlBuilder';
import SvgIcDashboard from 'components/icons/IcDashboard';
import { AuthContext } from 'components/route';
import { SvgIcProjectHyper } from 'components/icons/IcProjectHyper';
import { SvgIcProject } from 'components/icons/IcProject';
import { LinkForceRemount } from 'ui/linkForceRemount';
import { SvgIcDatasource } from 'components/icons/IcDatasource';
import { LinkProps } from 'react-router-dom';
import { IconSchedule } from 'components/icons/IcSchedule';
import { SvgIcNotify } from 'components/icons/IcNotify';
import {
  MdGroups,
  MdManageAccounts,
  MdOutlineCreditCard,
  MdSettings
} from 'react-icons/md';
import { IconExport } from './icons/IcExport';
import { IconConnection } from './icons/IcConnection';
import { FaShoePrints } from 'react-icons/fa';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  selected: boolean;
  primary: string;
  to: string;
}

// 新しい型定義を追加
interface NavigationItem {
  to: string;
  icon?: React.ReactElement;
  primary: string;
  dataCy?: string;
  menuKey: SelectedMenu;
  requiredFeature?: 'dashboard' | 'batch' | 'spark';
  requiredRole?: UserRole;
}

interface NavigationGroup {
  title: string;
  items: NavigationItem[];
}

const ListItemStyled = styled(ListItemButton)`
  &.Mui-selected {
    background-color: #4d4d4d19;
  }
` as typeof ListItemButton;

const drawerWidth = 240;

const DrawerStyled = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: ${drawerWidth}px;
    background: #eee;

    .Mui-selected {
      border-left: 8px solid #e2762a;
      padding-left: 3px !important;
      span {
        font-weight: bold;
      }
    }

    .MuiList-subheader {
      margin-top: 25px;
    }

    .MuiListSubheader-root {
      height: 20px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #838383;
      background: #eee;
    }

    .MuiListSubheader-gutters {
      padding-left: 19px;
    }

    .MuiListItem-root {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 8px;
      width: auto;
    }

    .MuiListItemIcon-root {
      min-width: 30px;
      min-height: 30px;
      justify-content: center;
      align-items: center;
    }

    .MuiListItem-gutters {
      padding-left: 11px;
    }

    span {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }

    svg {
      color: #000;
    }
  }
`;

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((linkProps, ref) => (
        <LinkForceRemount ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <li data-cy={props['data-cy']}>
      <ListItemStyled
        LinkComponent={CustomLink}
        href={to}
        selected={props.selected}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemStyled>
    </li>
  );
}

export type SelectedMenu =
  | 'info'
  | 'manual'
  | 'user'
  | 'projects'
  | 'datasources'
  | 'exports'
  | 'schedules'
  | 'connections'
  | 'notifications'
  | 'notification_dsts'
  | 'builders'
  | 'reports'
  | 'variables'
  | 'admin/users'
  | 'admin/user_groups'
  | 'admin/storage_usage'
  | 'admin/audit_logs'
  | 'admin/payment'
  | 'admin/projects'
  | 'admin/datasources'
  | 'admin/builders'
  | 'admin/schedules'
  | 'admin/connections'
  | 'admin/variables'
  | 'admin/reports'
  | 'admin/notifications'
  | 'admin/notification_dsts'
  | 'admin/organizations'
  | 'admin/backends'
  | 'admin/project_export'
  | 'admin/builder_export'
  | 'admin/project_import'
  | 'admin/builder_import';

export interface MainDrawerProps {
  selectedMenu: SelectedMenu;
}

const navigationConfig: NavigationGroup[] = [
  {
    title: '',
    items: [
      {
        to: '/projects',
        menuKey: 'projects',
        primary: 'データを分析する',
        dataCy: 'drawer-project-list-link',
        icon: <SvgIcProject />
      },
      {
        to: '/user',
        menuKey: 'user',
        primary: 'アカウント設定',
        dataCy: 'drawer-account-setting-link',
        icon: <MdSettings size="1.5em" />
      },
      {
        to: '/manual',
        menuKey: 'manual',
        primary: 'マニュアル',
        dataCy: 'drawer-manual-link',
        icon: <Camera />
      }
    ]
  },
  {
    title: 'データを作成・管理する',
    items: [
      {
        to: '/datasources',
        menuKey: 'datasources',
        primary: 'データソース',
        dataCy: 'drawer-datasource-list-link',
        icon: <SvgIcDatasource />
      },
      {
        to: '/builders',
        menuKey: 'builders',
        primary: 'SQLビルダー',
        dataCy: 'drawer-sqlbuilder-list-link',
        icon: <SvgIcSqlBuilder />
      }
    ]
  },
  {
    title: '組織内で共用する',
    items: [
      {
        to: '/connections',
        menuKey: 'connections',
        primary: '接続情報',
        dataCy: 'drawer-connection-list-link',
        icon: <IconConnection />
      },
      {
        to: '/notification_dsts',
        menuKey: 'notification_dsts',
        primary: '通知先',
        dataCy: 'drawer-connection-list-link',
        icon: <SettingsInputComponent />
      },
      {
        to: '/variables',
        menuKey: 'variables',
        primary: '共通変数',
        dataCy: 'drawer-variable-list-link',
        icon: <TextFormat />
      }
    ]
  },
  {
    title: '成果物を管理する',
    items: [
      {
        to: '/reports',
        menuKey: 'reports',
        primary: 'ダッシュボード',
        dataCy: 'drawer-report-list-link',
        icon: <SvgIcDashboard />,
        requiredFeature: 'dashboard'
      },
      {
        to: '/exports',
        menuKey: 'exports',
        primary: 'データエクスポート',
        dataCy: 'drawer-dataexport-list-link',
        icon: <IconExport />
      }
    ]
  },
  {
    title: '更新を管理する',
    items: [
      {
        to: '/schedules',
        menuKey: 'schedules',
        primary: 'スケジューリング',
        dataCy: 'drawer-schedule-list-link',
        icon: <IconSchedule />,
        requiredFeature: 'batch'
      },
      {
        to: '/notifications',
        menuKey: 'notifications',
        primary: '通知',
        dataCy: 'drawer-notification-list-link',
        icon: <SvgIcNotify />,
        requiredFeature: 'batch'
      }
    ]
  },
  {
    title: 'nehanマネージャー用',
    items: [
      {
        to: '/admin/users',
        menuKey: 'admin/users',
        primary: 'ユーザー管理',
        dataCy: 'drawer-admin-user-list-link',
        icon: <MdManageAccounts size="1.5em" />,
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/user_groups',
        menuKey: 'admin/user_groups',
        primary: 'ユーザーグループ管理',
        dataCy: 'drawer-admin-user-group-list-link',
        icon: <MdGroups size="1.5em" />,
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/storage_usage',
        menuKey: 'admin/storage_usage',
        primary: 'リソース状態',
        dataCy: 'drawer-admin-storage-status-list-link',
        icon: <Storage />,
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/audit_logs',
        icon: <FaShoePrints />,
        menuKey: 'admin/audit_logs',
        primary: '監査ログ',
        dataCy: 'drawer-admin-audit-log-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/payment',
        menuKey: 'admin/payment',
        primary: '支払い設定',
        dataCy: 'drawer-admin-payment-list-link',
        icon: <MdOutlineCreditCard size="1.5em" />,
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/projects',
        menuKey: 'admin/projects',
        primary: '組織内全分析プロジェクト',
        dataCy: 'drawer-admin-project-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/datasources',
        menuKey: 'admin/datasources',
        primary: '組織内全データソース',
        dataCy: 'drawer-admin-datasource-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/builders',
        menuKey: 'admin/builders',
        primary: '組織内全SQLビルダー',
        dataCy: 'drawer-admin-sqlbuilder-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/connections',
        menuKey: 'admin/connections',
        primary: '組織内全接続情報',
        dataCy: 'drawer-admin-connection-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/variables',
        menuKey: 'admin/variables',
        primary: '組織内全共通変数',
        dataCy: 'drawer-admin-variable-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/reports',
        menuKey: 'admin/reports',
        primary: '組織内全ダッシュボード',
        dataCy: 'drawer-admin-report-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/schedules',
        menuKey: 'admin/schedules',
        primary: '組織内全スケジューリング',
        dataCy: 'drawer-schedule-list-link',
        requiredRole: UserRole.Admin,
        requiredFeature: 'batch'
      },
      {
        to: '/admin/notifications',
        menuKey: 'admin/notifications',
        primary: '組織内全通知',
        dataCy: 'drawer-admin-report-list-link',
        requiredRole: UserRole.Admin
      },
      {
        to: '/admin/notification_dsts',
        menuKey: 'admin/notification_dsts',
        primary: '組織内全通知先',
        dataCy: 'drawer-admin-report-list-link',
        requiredRole: UserRole.Admin
      }
    ]
  },
  {
    title: 'nehan管理者用',
    items: [
      {
        to: '/admin/organizations',
        menuKey: 'admin/organizations',
        primary: '全ての組織',
        dataCy: 'drawer-admin-organization-list-link',
        requiredRole: UserRole.NehanAdmin
      },
      {
        to: '/admin/backends',
        menuKey: 'admin/backends',
        primary: '全てのバックエンド',
        dataCy: 'drawer-admin-backend-list-link',
        requiredRole: UserRole.NehanAdmin
      },
      {
        to: '/admin/project_import',
        menuKey: 'admin/project_import',
        primary: 'プロジェクトのインポート',
        requiredRole: UserRole.NehanAdmin
      },
      {
        to: '/admin/builder_import',
        menuKey: 'admin/builder_import',
        primary: 'ビルダーのインポート',
        requiredRole: UserRole.NehanAdmin
      },
      {
        to: '/admin/project_export',
        menuKey: 'admin/project_export',
        primary: 'プロジェクトのエクスポート',
        requiredRole: UserRole.NehanAdmin
      },
      {
        to: '/admin/builder_export',
        menuKey: 'admin/builder_export',
        primary: 'ビルダーのエクスポート',
        requiredRole: UserRole.NehanAdmin
      }
    ]
  }
];

const mainDrawer: React.FC<MainDrawerProps> = ({ selectedMenu }) => {
  const { user } = React.useContext(AuthContext);

  // フィルタリング用のヘルパー関数
  const isItemVisible = (item: NavigationItem) => {
    if (item.requiredRole && user.organization.role < item.requiredRole) {
      return false;
    }
    if (
      item.requiredFeature === 'dashboard' &&
      !user.organization.feature_dashboard
    ) {
      return false;
    }
    if (item.requiredFeature === 'batch' && !user.organization.feature_batch) {
      return false;
    }
    if (item.menuKey === 'manual' && import.meta.env.VITE_HIDE_SAMPLES) {
      return false;
    }
    if (
      item.menuKey === 'admin/storage_usage' &&
      user.organization.private_aws
    ) {
      return false;
    }
    return true;
  };

  // グループ内の表示可能なアイテムをフィルタリング
  const filterGroup = (group: NavigationGroup): NavigationGroup | null => {
    const filteredItems = group.items.filter(isItemVisible);
    return filteredItems.length > 0 ? { ...group, items: filteredItems } : null;
  };

  // メニューグループをレンダリング
  const renderGroup = (group: NavigationGroup) => (
    <React.Fragment key={group.title}>
      <List
        subheader={
          group.title ? <ListSubheader>{group.title}</ListSubheader> : null
        }
      >
        {group.title && (
          <Divider
            variant="middle"
            sx={{
              marginBottom: '3px',
              marginLeft: '19px',
              marginRight: '16px'
            }}
          />
        )}
        {group.items.map((item) => (
          <ListItemLink
            key={item.to}
            to={item.to}
            selected={selectedMenu === item.menuKey}
            icon={
              item.menuKey === 'projects' && user.organization.feature_spark ? (
                <SvgIcProjectHyper />
              ) : (
                item.icon
              )
            }
            primary={item.primary}
            data-cy={item.dataCy}
          />
        ))}
      </List>
    </React.Fragment>
  );

  // 表示可能なグループをフィルタリング
  const visibleGroups = navigationConfig
    .map(filterGroup)
    .filter((group): group is NavigationGroup => group !== null);

  return (
    <DrawerStyled variant="permanent" anchor="left">
      <Toolbar />
      <Divider />
      {visibleGroups.map(renderGroup)}
    </DrawerStyled>
  );
};

export default mainDrawer;
