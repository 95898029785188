import { Dtypes } from 'Utils/dataTypes';

export const DateUnitArray = ['day', 'month', 'year'] as const;
export type DateUnit = (typeof DateUnitArray)[number];
export const TimeUnitArray = ['second', 'minute', 'hour'] as const;
export type TimeUnit = (typeof TimeUnitArray)[number];
export const DatetimeUnitArray = [...DateUnitArray, ...TimeUnitArray] as const;
export type DatetimeUnit = (typeof DatetimeUnitArray)[number];
export type UnionDateUnit = DateUnit | DatetimeUnit | TimeUnit;

interface TimeValuesV2 {
  version: 2;
}

// N{日,月,年}{前,間}
export type InputPastValue = {
  value: string; // 数値だが空白も許容するのでstring
  unit: UnionDateUnit;
};

type DateSingleValuesSelect =
  | 'manual'
  | 'today'
  | 'yesterday'
  | '1_week_ago'
  | '1_month_ago'
  | '1_year_ago'
  | 'input_past';

export interface DateSingleValues extends TimeValuesV2 {
  select: DateSingleValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    date: string;
  };
}

export type DateRangeValuesSelect =
  | 'manual'
  | 'this_week'
  | 'last_week'
  | 'this_month'
  | 'last_month'
  | 'this_year'
  | 'last_year'
  | 'input_past';

export interface DateRangeValues extends TimeValuesV2 {
  select: DateRangeValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    startDate: string;
    endDate: string;
  };
  includeToday: boolean;
}

type DatetimeSingleValuesSelect =
  | 'manual'
  | 'today'
  | 'yesterday'
  | '1_week_ago'
  | '1_month_ago'
  | '1_year_ago'
  | '1_hour_ago'
  | '6_hour_ago'
  | '12_hour_ago'
  | 'input_past';

export interface DatetimeSingleValues extends TimeValuesV2 {
  select: DatetimeSingleValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    date: string;
    time: string;
  };
}

type DatetimeRangeValuesSelect =
  | 'manual'
  | 'this_week'
  | 'last_week'
  | 'this_month'
  | 'last_month'
  | 'this_year'
  | 'last_year'
  | 'past_1_hour'
  | 'past_3_hour'
  | 'past_6_hour'
  | 'past_12_hour'
  | 'input_past';

export interface DatetimeRangeValues extends TimeValuesV2 {
  select: DatetimeRangeValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
  };
  includeToday: boolean;
}

type TimeSingleValuesSelect =
  | 'manual'
  | '1_hour_ago'
  | '6_hour_ago'
  | '12_hour_ago'
  | 'input_past';

export interface TimeSingleValues extends TimeValuesV2 {
  select: TimeSingleValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    time: string;
  };
}

type TimeRangeValuesSelect =
  | 'manual'
  | 'past_1_hour'
  | 'past_6_hour'
  | 'past_12_hour'
  | 'input_past';

export interface TimeRangeValues extends TimeValuesV2 {
  select: TimeRangeValuesSelect;
  inputPastValue: InputPastValue;
  inputValue: {
    startTime: string; // HH:mm:ss
    endTime: string; // HH:mm:ss
  };
}

export type TimeValues =
  | DateSingleValues
  | DateRangeValues
  | DatetimeSingleValues
  | DatetimeRangeValues
  | TimeSingleValues
  | TimeRangeValues;

export const dateUnits: {
  label: string;
  value: DateUnit;
}[] = [
  { label: '日', value: 'day' },
  { label: '月', value: 'month' },
  { label: '年', value: 'year' }
];

export const timeSingleUnits: {
  label: string;
  value: TimeUnit;
}[] = [
  { label: '時間', value: 'hour' },
  { label: '分', value: 'minute' },
  { label: '秒', value: 'second' }
];

export const timeRangeUnits: {
  label: string;
  value: TimeUnit;
}[] = [
  { label: '時', value: 'hour' },
  { label: '分', value: 'minute' },
  { label: '秒', value: 'second' }
];

export const datetimeSingleUnits = [...dateUnits, ...timeSingleUnits];
export const datetimeRangeUnits = [...dateUnits, ...timeRangeUnits];

export type DateType = Dtypes.DATE | Dtypes.TIMESTAMP | Dtypes.TIME;
