import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { nehanColor } from '../../../theme';

export const Switch = withStyles((theme) => ({
  root: {
    padding: 8
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: nehanColor,
        opacity: 1
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    margin: 2,
    boxShadown: 'none'
  },
  track: {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  checked: {}
}))((props: SwitchProps) => <MuiSwitch disableRipple={true} {...props} />);
