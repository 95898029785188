import {
  _delete,
  _get,
  _getWithSharedToken,
  _post,
  _postRaw,
  _put
} from 'libs/api/base';
import { ProjectState } from 'reducers/project';
import { Graph } from 'models/graph';
import { ExportItem, ProjectItem } from 'models/dependency';
import { Path } from 'models/folder';
import { Project, Variable, Workflow } from 'models/project';
import { QueryParams } from 'ui/listViewBase';

// projects
export function addProject(body: { name: string; folder_id: string }) {
  return _post('/projects', body);
}

export function getProjects(folderId?: string, queryParams?: QueryParams) {
  return _get<{ items: ProjectItem[]; paths: Path[]; emails: string[] }>(
    '/projects',
    {
      params: { folderId, ...queryParams }
    }
  );
}

export function getProject(projectId: string) {
  return _get<Project>(`/projects/${projectId}`);
}

export function getProjectWithSharedToken(token: string, projectId: string) {
  return _getWithSharedToken(token, `/embed/projects/${projectId}`);
}

export function duplicateProject(projectId: string, folderId?: string) {
  return _post(
    `/projects/${projectId}/duplicate`,
    {
      projectId
    },
    {
      params: { folderId }
    }
  );
}

export function updateProject(
  projectId: string,
  body: Partial<Pick<ProjectState, 'currentWorkflowIndex'>>
) {
  return _put(`/projects/${projectId}`, body);
}

export function updateWorkflow(
  projectId: string,
  workflowId: number,
  body: { name: string; graph: Graph }
) {
  return _put(`/projects/${projectId}/workflows/${workflowId}`, body);
}

export function updateProjectName(id: string, name: string) {
  return _put(`/projects/${id}/name`, { name });
}

export function addWorkflow(projectId: string) {
  return _post(`/projects/${projectId}/workflows`, null);
}

export function deleteWorkflow(projectId: string, workflowId: number) {
  return _delete(`/projects/${projectId}/workflows/${workflowId}`);
}

export function duplicateWorkflow(
  projectId: string,
  workflowId: number,
  target_project: string
) {
  return _post<{
    workflow: Workflow;
    hasVisualizeNodes: { [k: string]: boolean };
    hasReportVisualizeNodes: Project['hasReportVisualizeNodes'];
  }>(`/projects/${projectId}/workflows/${workflowId}/duplicate`, {
    target_project
  });
}

export function moveWorkflow(
  projectId: string,
  workflowId: number,
  targetOrderNumber: number
) {
  return _put(`/projects/${projectId}/workflows/${workflowId}/move`, {
    targetOrderNumber
  });
}

export function deleteNode(
  projectId: string,
  workflowId: number,
  nodeIds: string[]
) {
  return _post(`/projects/${projectId}/workflows/${workflowId}/delete_nodes`, {
    node_ids: nodeIds
  });
}

export function deleteProject(projectId: string) {
  return _delete(`/projects/${projectId}`);
}

export function interruptProject(projectId: string, workflowId: number) {
  return _post<void>(
    `/projects/${projectId}/workflows/${workflowId}/interrupt`,
    undefined
  );
}

export function shutdownProject(projectId: string) {
  return _post<void>(`/projects/${projectId}/shutdown`, undefined);
}

export type ExportNodes = Array<
  {
    project_uuid: string;
    project_name: string;
    workflow_name: string;
  } & Node
>;

export async function getExportNodes(_: string, queryParams?: QueryParams) {
  const { data } = await _get<ExportItem[]>('/projects/exports', {
    params: { ...queryParams }
  });
  return { data: { items: data, paths: [] } };
}

export async function updateVariables(
  projectId: string,
  variables: Variable[]
) {
  return _put(`/projects/${projectId}/variables`, { variables });
}

export async function getProjectMembers(projectId: string) {
  return _get(`/projects/${projectId}/members`);
}

export async function deleteProjectMember(
  projectId: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/projects/${projectId}/members`, { id, is_group });
}

export async function getAdminProjects(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get('/admin/projects', {
    params: { folderId, ...queryParams }
  });
}

export async function getExportProjectJSON(uuid: string) {
  return _post('/projects/export_json', { uuid });
}

export async function getExportBuilderJSON(uuid: string) {
  return _post('/builders/export_json', { uuid });
}

export async function postImportProjectJSON(body: string) {
  return _postRaw('/projects/import_json', body);
}

export async function postImportBuilderJSON(body: string) {
  return _postRaw('/builders/import_json', body);
}

export async function getProjectRelationships(uuid: string) {
  return _get(`/projects/${uuid}/relationships`);
}

export async function getWorkflowRelationships(uuid: string) {
  return _get(`/projects/${uuid}/workflow_relationships`);
}

export async function getExportRelationships(node_id: string, uuid: string) {
  return _get(`/projects/${uuid}/export_relationships/${node_id}`);
}

export async function postWorkflowExecution(
  project_id: string,
  workflow_id: number,
  body: {
    node_id?: string;
    follow: boolean;
  }
) {
  return _post(
    `/projects/${project_id}/workflows/${workflow_id}/execute`,
    body
  );
}
