import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import NewDatasourceList from 'components/datasource/newDatasourceList';
import TypeSelectPage from 'components/pages/typeSelectPage';

const styles = () =>
  createStyles({
    content: {
      paddingTop: 10,
      maxWidth: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    }
  });

const NewDatasourceListPage = () => {
  const [searchText, setSearchText] = React.useState<string>('');
  const onChange = (ev: any) => {
    setSearchText(ev.target.value);
  };
  return (
    <TypeSelectPage
      title="接続するデータタイプを選択"
      menu="datasources"
      onChange={onChange}
    >
      <NewDatasourceList searchText={searchText} />
    </TypeSelectPage>
  );
};

export default withStyles(styles)(NewDatasourceListPage);
