import * as React from 'react';
import { ListItem, ReportItem } from '../../../models/dependency';
import { TableCell } from '@mui/material';
import { AccessLevelString } from 'libs/accessLevel';
import { Folder } from '@mui/icons-material';
import { LinkCell, TimestampCell } from 'ui/listViewTableCell';

export const ReportSelectorRow: React.FC<{
  item: ReportItem;
  onClickItem?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({ item, onClickItem, clearForm }) => {
  const onClick = onClickItem
    ? React.useCallback(() => {
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;
  const onClickFolder = onClickItem
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickItem(item);
      }, [onClickItem, item])
    : undefined;

  if (item.is_folder) {
    return (
      <>
        <LinkCell
          href="#"
          name={item.name}
          icon={<Folder />}
          onClick={onClickFolder}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
      </>
    );
  } else {
    return (
      <>
        <LinkCell href="#" name={item.name} onClick={onClick} />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TimestampCell time={item.created_at} />
        <TimestampCell time={item.updated_at} />
      </>
    );
  }
};
