import * as React from 'react';
import { Box, Button, Popover } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ProjectFilter } from 'ui/listView/filter/projectFilter';
import { DatasourceFilter } from 'ui/listView/filter/datasourceFilter';
import { BuilderFilter } from 'ui/listView/filter/builderFilter';
import { ConnectionFilter } from 'ui/listView/filter/connectionFilter';
import { VariableFilter } from 'ui/listView/filter/variableFilter';
import { ReportFilter } from 'ui/listView/filter/reportFilter';
import { ExportFilter } from 'ui/listView/filter/exportFilter';
import { FilterContext } from 'ui/listView/filter/context';
import { handleClickClearButton } from 'ui/listView/filter/handler';
import { ScheduleFilter } from 'ui/listView/filter/scheduleFilter';
import { NotificationFilter } from './notificationFilter';
import { NotificationDstFilter } from './notificationDstFilter';
import { UserGroupFilter } from 'ui/listView/filter/userGroupFilter';
import { UserFilter } from 'ui/listView/filter/userFilter';
import { OrganizationFilter } from 'ui/listView/filter/organizationFilter';

export const ListViewFilter: React.FC<{
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
}> = ({ anchorEl, onClose }) => {
  const {
    type,
    state: filterState,
    dispatch: filterDispatch,
    load
  } = React.useContext(FilterContext);

  const handleClickSearchButton = React.useCallback(() => {
    onClose();
    load(true);
  }, [load, onClose]);

  const filterOpen = Boolean(anchorEl);
  const filterPopoverId = filterOpen ? 'filter-popover' : undefined;

  return (
    <Popover
      slotProps={{
        paper: {
          sx: { width: 600 }
        }
      }}
      id={filterPopoverId}
      open={filterOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Box
        sx={{
          p: 2,
          '& table': {
            width: '100%'
          },
          '& tr': {
            height: 36,
            '& td:nth-child(2)': {
              width: 360
            }
          }
        }}
      >
        <ResourceFilter />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            paddingTop: 2,
            paddingBottom: 1
          }}
        >
          <Button
            variant="contained"
            color="cancel"
            onClick={handleClickClearButton(type, filterState, filterDispatch)}
          >
            クリア
          </Button>
          <Button
            variant="contained"
            color="common_color"
            onClick={handleClickSearchButton}
          >
            検索
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export const ResourceFilter: React.FC = () => {
  const { type } = React.useContext(FilterContext);
  switch (type) {
    case 'schedules':
      return <ScheduleFilter />;
    case 'builders': {
      return <BuilderFilter />;
    }
    case 'connections': {
      return <ConnectionFilter />;
    }
    case 'datasources':
    case 'selector_datasource': {
      return <DatasourceFilter />;
    }
    case 'exports':
    case 'selector_export': {
      return <ExportFilter />;
    }
    case 'projects':
    case 'selector_project':
    case 'visualize_selector': {
      return <ProjectFilter />;
    }
    case 'reports':
    case 'report_selector':
    case 'selector_report': {
      return <ReportFilter type={type} />;
    }
    case 'variables': {
      return <VariableFilter />;
    }
    case 'notifications': {
      return <NotificationFilter />;
    }
    case 'notification_dsts': {
      return <NotificationDstFilter />;
    }
    case 'users':
      return <UserFilter />;
    case 'user_groups':
      return <UserGroupFilter />;
    case 'organizations': {
      return <OrganizationFilter />;
    }
    default:
      return null;
  }
};
