import * as React from 'react';
import clsx from 'clsx';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  CircularProgress,
  DialogTitleProps,
  FormControlLabel as MuiFormControlLabel,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  NodeItem,
  Relationships
} from 'components/relationships/relationships';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  CheckCircle,
  CheckCircleOutline,
  DeleteForever,
  ErrorOutline,
  PauseCircleOutline
} from '@mui/icons-material';
import { produce } from 'immer';
import {
  deleteDatasource,
  deleteProject,
  deleteReport,
  deleteWorkflow
} from 'libs/api';
import { DetailPane } from './pane';
import { Dialog as CommonDialog } from 'components/ui/common/dialog';

const dialogStyles = makeStyles(() => ({
  deleteList: {
    display: 'flex',
    paddingLeft: 16,
    flexDirection: 'column'
  },
  deleteItem: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

type DeleteStatus = 'none' | 'running' | 'success' | 'failure';

function getDeleteIcon(status: DeleteStatus) {
  switch (status) {
    case 'none':
      return <PauseCircleOutline style={{ color: 'silver' }} />;
    case 'running':
      return <CircularProgress size={24} />;
    case 'success':
      return <CheckCircleOutline style={{ color: 'green' }} />;
    case 'failure':
      return <ErrorOutline style={{ color: 'red' }} />;
  }
}

function deleteItem(item: NodeItem) {
  switch (item.type) {
    case 'project':
      return deleteProject(item.id);
    case 'workflow':
      return deleteWorkflow(item.data.project_id, item.data.workflow_id);
    case 'report':
      return deleteReport(item.data.id);
    case 'datasource':
      return deleteDatasource(item.data.id);
    default:
      throw new Error('Unknown type');
  }
}

function getTypeOrder(a: string, b: string): number {
  const typeOrder: { [key: string]: number } = {
    report: 0,
    workflow: 1,
    project: 2,
    datasource: 3
  };
  return typeOrder[a] - typeOrder[b];
}

const DeleteDialog: React.VFC<{
  open: boolean;
  checkedItems: NodeItem[];
  onCloseDialog: (deleted: boolean) => void;
}> = ({ open, checkedItems, onCloseDialog }) => {
  const classes = dialogStyles();
  const [status, setStatus] = React.useState<'none' | 'running' | 'done'>(
    'none'
  );
  const [itemsStatus, setItemsStatus] = React.useState<DeleteStatus[]>([]);
  const [items, setItems] = React.useState<NodeItem[]>([]);
  React.useEffect(() => {
    // ダイアログが開いたときに、アイテムを初期化
    if (!open) {
      setItems([]);
    }

    // Report, Workflow, Project, Datasourceの順にソート
    const newItems = checkedItems.sort((a, b) => {
      return getTypeOrder(a.type, b.type);
    });
    setItems(newItems);
    setItemsStatus(newItems.map(() => 'none'));
    setStatus('none');
  }, [open, checkedItems]);

  const startDelete = async () => {
    for (let i = 0; i < items.length; i++) {
      setItemsStatus((prev) =>
        produce(prev, (draft) => {
          draft[i] = 'running';
        })
      );
      const item = items[i];
      try {
        await deleteItem(item);
        setItemsStatus((prev) =>
          produce(prev, (draft) => {
            draft[i] = 'success';
          })
        );
      } catch (e) {
        setItemsStatus((prev) =>
          produce(prev, (draft) => {
            draft[i] = 'failure';
          })
        );
      }
    }

    setStatus('done');
  };

  const onDelete = React.useCallback(() => {
    setStatus('running');
    startDelete();
  }, [startDelete]);

  return (
    <CommonDialog
      open={open}
      title="オブジェクトの削除"
      onClose={() => onCloseDialog(false)}
      cancelButton={{
        disabled: status !== 'none',
        onClick: () => onCloseDialog(false)
      }}
      OKButton={
        status !== 'done'
          ? {
              label: `削除`,
              onClick: onDelete
            }
          : {
              label: `閉じる`,
              onClick: () => onCloseDialog(true)
            }
      }
    >
      <p>
        <strong>注意：</strong>
        削除したオブジェクトは復元できません。
        <br />
        データソースを削除する場合、他のプロジェクトに影響が出る場合があります。
      </p>
      <p>下記のオブジェクトが削除されます</p>
      <div className={classes.deleteList}>
        {items.map((item, i) => (
          <div key={item.id} className={classes.deleteItem}>
            {getDeleteIcon(itemsStatus[i])}
            &nbsp;
            {item.data.label}
          </div>
        ))}
      </div>
    </CommonDialog>
  );
};

const FormControlLabel = withStyles({
  label: {
    fontWeight: 'bold'
  }
})(MuiFormControlLabel);
const Checkbox = withStyles({
  root: {
    color: '#b8b8b8',
    '&$checked': {
      color: '#344955'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);

const dialogTitleStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#525252',
    backgroundColor: '#f0f0f0',
    fontSize: 14,
    fontWeight: 'bold',
    height: 58,
    padding: '0 0 0 24px'
  },
  deleteMode: {
    padding: '0 0 0 24px',
    backgroundColor: '#fffbed'
  },
  spacer: {
    flex: '1 1 auto'
  },
  closeButton: {
    color: theme.palette.grey[500]
  }
}));

const dialogTitleProps = (args: {
  title: string;
  deleteMode: boolean;
  onUncheck: () => void;
  onDelete: () => void;
  onClose: () => void;
}): DialogTitleProps | undefined => {
  const classes = dialogTitleStyles();
  return {
    className: clsx(classes.title, {
      [classes.deleteMode]: args.deleteMode
    }),
    children: args.deleteMode ? (
      <>
        <FormControlLabel
          control={<Checkbox checkedIcon={<CheckCircle />} checked={true} />}
          label="選択解除"
          onChange={args.onUncheck}
        />
        <div className={classes.spacer} />
        <Tooltip title="選択したオブジェクトを削除">
          <IconButton onClick={args.onDelete} size="large">
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </>
    ) : undefined
  };
};

const mainDialogStyle = makeStyles(() => ({
  paper: {
    maxHeight: 830
  },
  content: {
    display: 'flex',
    padding: 0,
    height: 'calc(830px - 58px)'
  }
}));

export const RelationshipsDialog: React.VFC<{
  id: string;
  project_id?: string;
  workflow_id?: number;
  type: string;
  open: boolean;
  onClose: () => void;
  onClickDetailStatusIcon?: () => void;
}> = ({
  id,
  project_id,
  workflow_id,
  type,
  open,
  onClose,
  onClickDetailStatusIcon
}) => {
  const classes = mainDialogStyle();
  const [targetItem, setTargetItem] = React.useState<{
    type: string;
    id: string;
    project_id?: string;
  }>({ type, id, project_id });
  const [checkedItems, setCheckedItems] = React.useState<NodeItem[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<NodeItem | null>(null);
  const [title, setTitle] = React.useState<string>('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onClear = React.useCallback(() => setCheckedItems([]), []);
  const onCloseDelete = React.useCallback((deleted: boolean) => {
    if (deleted) {
      location.search += '&open_relationships=1';
      return;
    }
    setDialogOpen(false);
  }, []);
  const onCloseDialog = React.useCallback(() => {
    setSelectedItem(null);
    onClose();
  }, [onClose]);
  const onDelete = React.useCallback(() => {
    setDialogOpen(true);
  }, []);
  const onTargetChange = React.useCallback((data: NodeItem['data']) => {
    setTargetItem({
      type: data.type,
      id: data.id,
      project_id: data.project_id
    });
    setCheckedItems([]);
  }, []);

  const onCheckChanged = React.useCallback(
    (id: string, checked: boolean, item: NodeItem) => {
      setCheckedItems((prev) => {
        if (checked) {
          return [...prev, item];
        }
        return prev.filter((i) => i.id !== id);
      });
    },
    []
  );

  React.useEffect(() => {
    setTargetItem({ type, id, project_id });
    setCheckedItems([]);
  }, [open, type, id, project_id, workflow_id]);

  return (
    <CommonDialog
      classes={{ paper: classes.paper }}
      maxWidth="xl"
      fullWidth={true}
      open={open}
      onClose={onCloseDialog}
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      title={title}
      titleProps={dialogTitleProps({
        title: title,
        deleteMode: checkedItems.length > 0,
        onUncheck: onClear,
        onDelete: onDelete,
        onClose: onCloseDialog
      })}
      contentProps={{ classes: { root: classes.content } }}
      hideFooter
    >
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Relationships
          key={targetItem.id}
          id={targetItem.id}
          project_id={targetItem.project_id}
          workflow_id={workflow_id}
          type={targetItem.type}
          checkedItems={checkedItems.map((i) => i.id)}
          onChangeChecked={onCheckChanged}
          onClickItem={setSelectedItem}
          onChangeTitle={setTitle}
          onTargetChange={onTargetChange}
        />
      </div>
      <DetailPane
        type={selectedItem?.type}
        item={selectedItem}
        showWorkflowSelector={false}
        onClose={() => setSelectedItem(null)}
        onClickStatusIcon={onClickDetailStatusIcon}
      />
      <DeleteDialog
        open={dialogOpen}
        onCloseDialog={onCloseDelete}
        checkedItems={checkedItems}
      />
    </CommonDialog>
  );
};
