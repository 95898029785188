import * as React from 'react';
import {
  getBuilderRelationships,
  getDatasourcesRelationships,
  getExportRelationships,
  getProjectRelationships,
  getReportRelationships,
  getWorkflowRelationships
} from 'libs/api';
import { useAsyncRetry } from 'react-use';
import { produce } from 'immer';
import {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  isEdge,
  MiniMap,
  Node as FlowNode,
  Position,
  ReactFlow,
  ReactFlowInstance,
  ReactFlowProvider,
  useStore
} from '@xyflow/react';
import dagre from '@dagrejs/dagre';
import {
  BuilderNode,
  DatasourceNode,
  ExpanderNode,
  ExportNode,
  ProjectNode,
  ReportNode,
  WorkflowNode
} from 'components/relationships/relationshipNode';
import { getDatasourceExpression } from 'libs/datasources';
import { LoadingContent } from 'components/LoadingSpinner';
import { WorkflowStatus } from 'models/project';
import { RelationshipLink } from 'models/relationship';

// default styling
import '@xyflow/react/dist/style.css';

type Node = ExportNodeType;
type onCheckChangeHandler = (
  id: string,
  checked: boolean,
  item: NodeItem
) => void;
type BaseNodeData = { checked: boolean; onCheckChanged?: onCheckChangeHandler };
type ExportNodeType = FlowNode<
  BaseNodeData & { form_value: { type: string } },
  'export'
>;

export const loadRelationshipFunc = (type: string) => {
  switch (type) {
    case 'project':
      return getProjectRelationships;
    case 'workflow':
      return getWorkflowRelationships;
    case 'report':
      return getReportRelationships;
    case 'datasource':
      return getDatasourcesRelationships;
    case 'builder':
      return getBuilderRelationships;
    case 'export':
      return getExportRelationships;
  }
  return getProjectRelationships;
};

const getLabel = (type: string, title: string): string => {
  switch (type) {
    case 'project':
      return `起点：${title} (プロジェクト)`;
    case 'workflow':
      return `「${title}」内のワークフロー系譜`;
    case 'report':
      return `起点：${title} (ダッシュボード)`;
    case 'datasource':
      return `起点：${title} (データソース)`;
    case 'builder':
      return `起点：${title} (SQLビルダー)`;
  }
  return '';
};

const nodeWidth = 140;
const nehanStorageNodeWidth = 100;
const nodeHeight = 80;

const getLayoutedElements = (elements, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep: 80 });

  elements.forEach((el) => {
    if (isNode(el)) {
      if (el.type === 'export') {
        const ds = getDatasourceExpression(el.data.form_value?.type ?? '');
        if (ds.key === 'nehan_storage') {
          dagreGraph.setNode(el.id, {
            width: nehanStorageNodeWidth,
            height: nodeHeight
          });
        } else {
          dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        }
      } else {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      }
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? Position.Left : Position.Top;
      el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2
      };
    }

    return el;
  });
};

function setHighlight(
  elements: Array<Node | Edge>,
  id: string
): Array<Node | Edge> {
  const newLayout: Array<Node | Edge> = elements.map((d) => {
    return { ...d, style: { opacity: 0.3 } };
  });

  // 親をすべてハイライト
  const queue: string[] = [];
  let traversed: string[] = [];
  queue.push(id);
  traversed.push(id);
  while (queue.length !== 0) {
    const id = queue.pop();
    // targetがidのリンクをすべてハイライト
    for (let i = 0; i < newLayout.length; i++) {
      const node = newLayout[i];
      if (isEdge(node) && node.target === id) {
        newLayout[i].style = { stroke: '#d64b8b', strokeWidth: '3px' } as any;
        if (!traversed.includes(node.source)) {
          queue.push(node.source);
          traversed.push(node.source);
        }
      }
      if (newLayout[i].id === id) {
        newLayout[i].style = { opacity: 1 };
      }
    }
  }

  //子をすべてハイライト
  traversed = [];
  queue.push(id);
  traversed.push(id);
  while (queue.length !== 0) {
    const id = queue.pop();
    // targetがidのリンクをすべてハイライト
    for (let i = 0; i < newLayout.length; i++) {
      const node = newLayout[i];
      if (isEdge(node) && node.source === id) {
        newLayout[i].style = { stroke: '#d64b8b', strokeWidth: '3px' } as any;
        if (!traversed.includes(node.target)) {
          queue.push(node.target);
          traversed.push(node.target);
        }
      }
      if (newLayout[i].id === id) {
        newLayout[i].style = { opacity: 1 };
      }
    }
  }

  return newLayout;
}

function getID(
  type: string,
  id: string,
  project_id?: string,
  workflow_id?: number,
  nodes?: any
): string {
  switch (type) {
    case 'project':
      return id;
    case 'datasource':
      return `datasource_${id}`;
    case 'report':
      return `report_${id}`;
    case 'builder':
      return `builder_${id}`;
    case 'export':
      return `${project_id}_${id}`;
    case 'workflow': {
      const w = nodes?.find((n) => n.data.id === String(workflow_id));
      return w?.id;
    }
  }
  return id;
}

const nodeTypes = {
  datasource: DatasourceNode,
  workflow: WorkflowNode,
  project: ProjectNode,
  export: ExportNode,
  report: ReportNode,
  builder: BuilderNode,
  expander: ExpanderNode
};

type OnCheckChangedHandler = (
  id: string,
  checked: boolean,
  item: NodeItem
) => void;

interface WorkflowItem {
  id: string;
  name: string;
  status: WorkflowStatus;
}

export interface NodeItem {
  id: string;
  type: string;
  name: string;
  parent_workflows: string[];
  data: {
    id: string;
    label: string;
    type: string;
    datasource_type: string;
    sample_datasource: boolean;
    comment: string;
    project_id: string;
    workflow_id: number;
    start_node: boolean;
    form_value?: any;
    expand_count: number;
    list_item: {
      type: string;
      id: number;
      uuid: string;
      is_folder: false;
    };
    workflows: WorkflowItem[];
    export_node_id?: string;
    access_level: number;

    // added by relationships
    checked?: boolean;
    disableCheck?: boolean;
    disableDelete?: boolean;
    checkableAccessLevel?: number;
    onCheckChanged?: OnCheckChangedHandler;
    disableTargetChange?: boolean;
    onTargetChange?: (target: NodeItem['data']) => void;
    reloadFunc: () => void;
  };
  selected: boolean;
}

export interface RelationshipUtils {
  reload: () => void;
}

const isNode = (element: Node | Edge): element is Node => {
  return 'id' in element && !('source' in element) && !('target' in element);
};

const _Relationships: React.VFC<{
  id: string;
  project_id?: string;
  workflow_id?: number;
  type: string;
  initial_highlight_id?: string;
  checkedItems: string[];
  disableDelete?: boolean;
  checkableAccessLevel?: number;
  onChangeChecked?: onCheckChangeHandler;
  onClickItem?: (item: NodeItem) => void;
  onChangeTitle?: (title: string) => void;
  onTargetChange?: (item: NodeItem['data']) => void;
  onLoad?: (
    params: RelationshipUtils,
    items: NodeItem[],
    links: RelationshipLink[]
  ) => void;
}> = ({
  id,
  project_id,
  workflow_id,
  initial_highlight_id,
  type,
  checkedItems,
  disableDelete,
  checkableAccessLevel,
  onChangeChecked,
  onClickItem,
  onChangeTitle,
  onTargetChange,
  onLoad
}) => {
  const isInitialized = React.useRef<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const [layout, setLayout] = React.useState<Array<Node | Edge>>([]);
  const [flow, setFlow] = React.useState<ReactFlowInstance | null>(null);
  const [fitView, setFitView] = React.useState(false);
  const addSelectedNodes = useStore((store) => store.addSelectedNodes);
  const [highlightId, setHighlightId] = React.useState(initial_highlight_id);

  const load = useAsyncRetry(async () => {
    const { data } = await loadRelationshipFunc(type)(id, project_id as any);
    setData(data);
    isInitialized.current = false;
  }, [id, type]);

  React.useEffect(() => {
    if (!data || load.loading) {
      return;
    }
    onChangeTitle && onChangeTitle(getLabel(type, data.start_label));
    const elements: Array<Node | Edge> = [
      ...(data.nodes || []),
      ...(data.links || [])
    ];
    let newLayout = getLayoutedElements(elements, 'LR') as any;
    let highlightTarget =
      highlightId || getID(type, id, project_id, workflow_id, data.nodes);
    if (type === 'export') {
      // チェインの場合は、エクスポートノードが存在しないので、データソースノードから探す
      const ds = data.nodes.find(
        (node) => node.data.export_node_id === highlightTarget
      );
      if (ds && ds.data.datasource_type === 'nehan_internal') {
        highlightTarget = ds.id;
      }
    }
    setHighlightId('');
    newLayout = setHighlight(newLayout, highlightTarget);
    newLayout = produce(newLayout, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (isNode(draft[i]) && draft[i].data) {
          draft[i].data.onCheckChanged = onChangeChecked;
          draft[i].data.onTargetChange = onTargetChange;
          draft[i].data.reloadFunc = load.retry;
          if (checkedItems.find((id) => id === draft[i].id)) {
            draft[i].data.checked = true;
          }

          if (draft[i].id === getID(type, id, project_id)) {
            if (type !== 'workflow') {
              draft[i].data.start_node = true;
            }
            // エクスポートの場合は、別途タイトル設定
            if (type === 'export') {
              const ds = getDatasourceExpression(
                draft[i].data.form_value?.type ?? ''
              );
              onChangeTitle && onChangeTitle(`起点：${ds.name} (エクスポート)`);
            }
          }
          // ワークフローの場合は、ワークフロー以外のチェックを無効化
          if (type === 'workflow' && draft[i].type !== 'workflow') {
            draft[i].data.disableCheck = true;
            draft[i].data.disableDelete = true;
            draft[i].data.disableTargetChange = true;
          }

          if (disableDelete) {
            draft[i].data.disableDelete = true;
          }

          if (checkableAccessLevel != undefined) {
            draft[i].data.checkableAccessLevel = checkableAccessLevel;
          }
        }
      }
    });
    newLayout.forEach((el) => {
      if (el.id === getID(type, id, project_id, workflow_id)) {
        addSelectedNodes([el]);
      }
    });
    setLayout(newLayout);
    setFitView(true);
  }, [data, load.loading]);

  React.useEffect(() => {
    if (load.loading) {
      return;
    }
    if (!isInitialized.current && !load.loading) {
      if (onLoad) {
        onLoad({ reload: load.retry }, data?.nodes || [], data?.links || []);
      }
    }
    isInitialized.current = true;
  }, [onLoad, load.loading]);

  React.useEffect(() => {
    if (!fitView) {
      return;
    }
    if (flow) {
      flow.fitView();
      setFitView(false);
    }
  }, [flow, fitView]);

  // チェック状態が変わったとき
  React.useEffect(() => {
    setLayout((layout) =>
      produce(layout, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          const el = draft[i];
          if (isNode(el) && el.data != undefined) {
            el.data.checked = checkedItems.includes(draft[i].id);
            el.data.onCheckChanged = onChangeChecked;
          }
        }
      })
    );
  }, [checkedItems, onChangeChecked]);

  const onNodeClick = React.useCallback(
    (ev, el) => {
      let parentEl = ev.target;
      while (parentEl) {
        if (parentEl.classList.contains('_node_menu_icon')) {
          return;
        }
        parentEl = parentEl.parentElement;
      }
      // エッジをハイライト
      const newLayout = setHighlight(layout, el.id);
      setLayout(newLayout);

      if (onClickItem) {
        onClickItem(el as never as NodeItem);
      }
    },
    [layout, onClickItem]
  );

  if (load.loading) {
    return <LoadingContent text="系譜のロード中" />;
  }

  const nodes = layout.filter(isNode);
  const edges = layout.filter(isEdge);

  console.log(layout, nodes);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      // @ts-ignore
      nodeTypes={nodeTypes}
      minZoom={0.1}
      onNodeClick={onNodeClick}
      onInit={(_flow) => setFlow(_flow)}
    >
      <MiniMap />
      <Controls showInteractive={false} />
      <Background variant={BackgroundVariant.Lines} />
    </ReactFlow>
  );
};

export const Relationships: React.VFC<
  React.ComponentProps<typeof _Relationships>
> = (props) => {
  return (
    <ReactFlowProvider>
      <_Relationships {...props} />
    </ReactFlowProvider>
  );
};
