import React, { createContext, useContext } from 'react';
import { useStyles } from './styles';
import { Dialog } from 'components/ui/common/dialog';

type DialogProps = {
  open: boolean;
  onClose: () => void;
};

const DialogContext = createContext<DialogProps | undefined>(undefined);

export const DialogProvider: React.FC<
  { children: React.ReactNode } & DialogProps
> = ({ children, open, onClose }) => {
  return (
    <DialogContext.Provider value={{ open, onClose }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogContext');
  }
  return context;
};

export const ParameterDialog: React.FC<
  {
    open: boolean;
    onClose: () => void;
    content: React.ReactNode;
    onClickOK: () => void;
    isValid?: boolean;
    title?: string;
    hideHeader?: boolean;
  } & DialogProps
> = ({
  open,
  onClose,
  content,
  onClickOK,
  isValid,
  title,
  hideHeader,
  ...dialogProps
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
      OKButton={{
        onClick: onClickOK,
        disabled: isValid != null && !isValid
      }}
      title={title}
      hideHeader={hideHeader}
      {...dialogProps}
    >
      {content}
    </Dialog>
  );
};
