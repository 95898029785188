import { Toolbar as MuiToolbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

export const Toolbar = withStyles({
  root: {
    width: '100%',
    height: 64,
    backgroundColor: '#f9f9f9'
  }
})(MuiToolbar);
