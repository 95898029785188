import * as React from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { MdLogout, MdPassword, MdSettings } from 'react-icons/md';
import { Avatar } from 'ui/common/avatar';
import history from '../../../appHistory';
import { PasswordChangeDialog } from 'components/pages/editUser';
import { AuthContext } from 'components/route';
import { firebaseApp } from '../../../firebase';
import { getAuth } from 'firebase/auth';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class ProfileIcon extends React.PureComponent<
  RouteComponentProps<{}>,
  {
    anchorEl?: HTMLElement;
    open: boolean;
  }
> {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: undefined,
      open: false
    };
  }

  handleProfileMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: undefined });
  };

  logout = () => {
    getAuth(firebaseApp).signOut();
    this.props.history.push('/login');
  };

  handleOpenPasswordChange = () => {
    this.setState({ open: true });
  };

  handleClosePasswordChange = () => {
    this.setState({ open: false });
  };

  render() {
    const { anchorEl } = this.state;
    const { user } = this.context;
    const isMenuOpen = anchorEl != undefined;

    return (<>
      <IconButton onClick={this.handleProfileMenuOpen} color="inherit" size="large">
        <Avatar user={user} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={() => history.push('/user')}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <MdSettings />
          </ListItemIcon>
          アカウント設定
        </MenuItem>
        <MenuItem onClick={this.handleOpenPasswordChange}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <MdPassword />
          </ListItemIcon>
          パスワードの変更
        </MenuItem>
        <MenuItem onClick={this.logout}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <MdLogout />
          </ListItemIcon>
          ログアウト
        </MenuItem>
      </Menu>
      <PasswordChangeDialog
        open={this.state.open}
        onClose={this.handleClosePasswordChange}
      />
    </>);
  }
}

export default withRouter(ProfileIcon);
